import React from 'react';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';
import Isvg from "react-inlinesvg";
import warrning from '../assets/svg/warrning.svg'
import info from '../assets/svg/info.svg'
import ErrorModal from './errorModal';
import FormBuilder from './forms/formBuilder';
import { required } from "./forms/validation";
// import BankIDModal_v2 from './bankIDModal_v2';
import BankIDModal_v5 from './bankIDModal_v5'
import bankID from '../assets/images/icon-bankid2.png';
import LoginImg from '../assets/images/login-img.png';
import BankId from '../assets/images/bankid.png';
import EId from '../assets/images/eid.png';
import Eidplus from '../assets/images/eidplus.png';
import ArrowBack from '../assets/svg/arrow-back.svg'
import NetId from '../assets/images/netid.png';
import MobImg from '../assets/images/mob-img.png';
import RightGreen from "../assets/images/grafic1.png";
import BottomYellow from "../assets/images/yellow-bottom.png";
import YellowGreen from "../assets/images/yell-grn.png";
import { getSearchParams } from '../helpers/linkHelper';

class LoginComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openBankId: false,
            formLogin: false
        }
    }


    componentDidMount() {
        let params = getSearchParams(this.props[0].location.search);
        if (params && params.qrCodeLink) {
            this.setState({
                openBankId: true
            })

        }

        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        let alias = this.props.alias;
        // localStorage.setItem('groupAlias', alias);
        let mainAlias = this.props.mainAlias;
        if (mainAlias && alias && alias !== 'noGroupAlias') {
            // console.log('alias i main alias');
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    this.setState({
                        data: result
                    }, () => {
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }
                    })

                }
            });



        } else if (alias && alias !== 'noGroupAlias') {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    }, () => {

                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }
                    })

                }
            });
        }

        window.addEventListener('message',
            (e) => {
                if (e.data == 'loggedin') {
                    this.props.registerClinicGroupLogs({
                        type: 'login',
                        loginType: 'bankid'
                    })
                    this.props.verifyUser(() => {
                        this.props[0].history.push('/');

                    }, () => {
                        // console.log(this.state.data)

                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (arr && arr.length) {
                                this.props.changeGroup(arr[0]?._id);
                                localStorage.setItem('groupAlias', arr[0].alias);
                            }
                        }


                    });

                } else if (e.data == 'loggedin_newuser') {


                    this.props.verifyUser(() => {
                        this.props[0].history.push('/');
                    }, () => {
                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                this.props.changeGroup(arr[0]?._id)
                                localStorage.setItem('groupAlias', arr[0].alias);
                            } else {
                                this.props.changeGroup(this.state.data?._id)
                            }
                        } else {
                            this.props.changeGroup(this.state.data?._id)
                        }




                    });
                } else if (e.data === 'loggedin_netid') {
                    this.props.verifyUser(() => {
                        this.props[0].history.push('/');

                    }, () => {
                        if (this.state.data) {
                            if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                                let arr = [];
                                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                    arr.push(this.props.uData.clinicGroups[i]);
                                }
                                if (arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                    this.props.changeGroup(arr[0]?._id)
                                    localStorage.setItem('groupAlias', arr[0].alias);
                                } else {
                                    this.props.changeGroup(this.state.data?._id)
                                }
                            } else {
                                this.props.changeGroup(this.state.data?._id)
                            }
                        }



                    });
                }
            }

            , false)
    }

    login = (data) => {

        if (API_ENDPOINT && (API_ENDPOINT.indexOf('localhost') == -1) && API_ENDPOINT.indexOf('qaapi-console') == -1) {
            if (!(API_ENDPOINT.indexOf('devapi.curoflow') != -1 && data.username == 'mista'))
                data.verificationCodeNeeded = true;
        }

        fetch(API_ENDPOINT + "/users/login", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    if (result.error.text) {
                        this.setState({
                            error: result.error.text
                        })
                    } else {
                        this.setState({
                            error: result.error
                        })
                    }
                } else {
                    if (result.sessionId) {
                        localStorage.setItem('sessionId', result.sessionId)
                    }
                    try {
                        if (result?.userData && typeof window !== 'undefined') {
                            localStorage?.setItem?.('userData', JSON.stringify(result?.userData));
                        }
                    } catch (err) {
                        console.error('login user data err: ', err);
                    }
                    localStorage.setItem('userLevel', 20);
                    localStorage.setItem('logoutLandingPage', true);
                    if (this.props.alias) {
                        localStorage.setItem('loginGroupAlias', this.props.alias);
                        // localStorage.setItem('groupAlias', this.props.alias);
                    }

                    localStorage.removeItem('groupAlias');
                    localStorage.setItem("authToken", result.token);
                    localStorage.setItem("profile", "doctor");
                    localStorage.setItem("time", Math.floor(Date.now() / 1000));
                    this.props.registerLog("Logged in");
                    this.props.verifyUser(() => {
                        this.props.registerClinicGroupLogs({
                            type: 'login',
                            loginType: 'username',
                        })
                        if (typeof window != 'undefined' && (localStorage.getItem('groupAlias') == null || localStorage.getItem('groupAlias') == 'undefined')) {
                            if (this.props.uData && this.props.uData.userLevel == 20 && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                                localStorage.setItem('groupAlias', this.props.uData.clinicGroups[0].alias)
                            }
                        }
                        this.props[0].history.push("/my-data/account");

                    }, () => {
                        if (/*localStorage.getItem('loginGroupAlias') == localStorage.getItem('groupAlias') && */this.state.data /*&& localStorage.getItem('groupAlias') !== 'noGroupAlias'*/) {
                            this.props.changeGroup(this.state.data?._id);
                        } else {
                            let arr = [];
                            for (let i = 0; i < this.props.uData?.clinicGroups.length; i++) {
                                arr.push(this.props.uData?.clinicGroups[i]);
                            }
                            this.props.changeGroup(arr[0]?._id);
                        }
                    });



                }
            });
    };
    componentDidUpdate(prevProps, prevState) {
        if (this.props.uData) {
            this.props[0].history.push("/my-data/account");
        }
        // if (prevState.faceId != this.state.faceId && !this.state.faceId && this.state.loadingFaceId) {
        //     this.setState({ loadingFaceId: false })
        // }
    }
    // checkPhoto = (photo) => {
    //     this.setState({ loadingFaceId: true }, () => {
    //         let obj = { photo: photo }
    //         if (typeof window != 'undefined' && localStorage.sessionId) {
    //             obj.sessionId = localStorage.sessionId;
    //         }
    //         fetch(API_ENDPOINT + "/users/login/check/face-id", {
    //             method: "POST",
    //             headers: {
    //                 "content-type": "application/json",
    //             },
    //             body: JSON.stringify(obj),
    //         }).then((res) => res.json()).then((result) => {
    //             if (result && result.user) {
    //                 this.setState({ faceId: null, faceIdUser: result.user, loadingFaceId: false })
    //             } else {
    //                 this.setState({ loadingFaceId: false })
    //             }
    //         });

    //     })

    // }

    render() {
        const { country } = this.props;
        const allowBankId = this.state.data?.bankIdAuthentication ? true : false;
        const allowNetId = this.state.data?.netIdAuthentication ? true : false;
        if (this.props.userVerificationInProgress) {
            return null
        }
        return (
            <div className='login-content' style={this.props.withoutPicture ? { padding: 20 } : { padding: 20 }}>
                {
                    this.props.withoutPicture ?
                        null
                        :
                        <img src={RightGreen} alt="" className="right-green" />

                }
                {
                    this.props.withoutPicture ?
                        null
                        :
                        <img src={BottomYellow} alt="" className="bottom-yellow" />

                }
                {
                    this.props.withoutPicture ?
                        null
                        :
                        <img src={YellowGreen} alt="" className="top-left" />

                }
                {
                    this.props.withoutPicture ?
                        null
                        :
                        <div className='text-img'>
                            <img src={LoginImg} alt="" className='desktop' />
                            <img src={MobImg} alt="" className='mobile' />
                            <p>{'Create your own digital reception with Curoflow.'.translate(this.props.lang)}</p>
                        </div>
                }


                {
                    this.state.openBankId ?
                        <div className='open-bankid'>

                            <button onClick={() => {
                                this.setState({
                                    openBankId: false
                                })
                            }} className="login-back"> <Isvg src={ArrowBack} /> {'go back'.translate(this.props.lang)}</button>
                            {/* <BankIDModal_v2 {...this.props} doctorLogin={this.props.doctorLogin} alias={this.props.alias} mainAlias={this.props.mainAlias} /> */}
                            <BankIDModal_v5 {...this.props} doctorLogin={this.props.doctorLogin} alias={this.props.alias} mainAlias={this.props.mainAlias} />
                        </div>
                        :
                        this.state.openSiths ?
                            <div className='open-bankid'>
                                <button onClick={() => {
                                    this.setState({
                                        openBankId: false,
                                        openSiths: false
                                    })
                                }} className="login-back"> <Isvg src={ArrowBack} /> {'go back'.translate(this.props.lang)}</button>
                                <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                    try {
                                        if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('ui/bankidse') !== -1) {
                                            this.setState({
                                                _hideIframe: true
                                            })
                                        }
                                    } catch (e) { }
                                }} src={this.state.openSiths} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: 770, border: 'none' }} />
                            </div>

                            :
                            <div className='right-content' style={this.props.withoutPicture ? { height: '100%' } : {}}>

                                <h2>{'Log in'.translate(this.props.lang)}</h2>
                                {(this.props.bankID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && (!this.props.alias || this.props.alias === 'noGroupAlias' || allowBankId)) || (this.props.netID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && this.props.alias && (this.props.alias === 'noGroupAlias' || allowNetId)) ? <p>{'Choose your login method'.translate(this.props.lang)}</p> : null}
                                <div className='login-btns'>
                                    {
                                        this.props.bankID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && (!this.props.alias || this.props.alias === 'noGroupAlias' || allowBankId) ?
                                            <Button className='bg-black' onClick={() => {
                                                this.setState({
                                                    openBankId: true,
                                                })
                                            }}> <img src={BankId} /> {'BankID'.translate(this.props.lang)}</Button>
                                            :
                                            null
                                    }
                                    {
                                        this.props.netID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && this.props.alias && (this.props.alias === 'noGroupAlias' || allowNetId) ?
                                            <Button onClick={() => {
                                                fetch(API_ENDPOINT + `/users/netid/start/${this.props.alias}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'content-type': 'application/json'
                                                    },
                                                }).then(res => res.json()).then((result) => {
                                                    if (result.redirectUrl) {
                                                        this.setState({
                                                            openSiths: result.redirectUrl
                                                        }, () => {
                                                            if (this.props.alias)
                                                                localStorage.setItem('groupAlias', this.props.alias)
                                                        })
                                                    }
                                                })


                                            }}><img src={NetId} /> {'Log in with SITHS'.translate(this.props.lang)}</Button>
                                            :
                                            null
                                    }

                                </div>
                                <div>
                                    {(this.props.bankID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && (!this.props.alias || this.props.alias === 'noGroupAlias' || allowBankId)) || (this.props.netID && (this.props._mainLogin ? country && country === 'sweden' ? true : false : true) && this.props.alias && (this.props.alias === 'noGroupAlias' || allowNetId)) ? <div className={this.props.lang === 'en' ? 'line' : 'line-se'}>
                                        <p>{'or'.translate(this.props.lang)}</p>
                                    </div> : null}

                                    {
                                        this.props.formLogin || this.state.formLogin ?
                                            <div className="login-form">
                                                <FormBuilder
                                                    country={this.props.country}
                                                    countryTwoLetter={this.props.countryTwoLetter}
                                                    dateFormat={this.props.dateFormat}
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    lang={this.props.lang}
                                                    onSubmit={(data) => this.login(data)}
                                                    buttonText={"Log in".translate(this.props.lang)}
                                                    fields={[
                                                        {
                                                            type: "row",
                                                            children: [
                                                                {
                                                                    type: "col",
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12,
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: "text",
                                                                            name: "username",
                                                                            label: "Username".translate(
                                                                                this.props.lang
                                                                            ),
                                                                            validate: [
                                                                                required(
                                                                                    "Username is required!".translate(
                                                                                        this.props.lang
                                                                                    )
                                                                                ),
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    type: "col",
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12,
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: "password",
                                                                            name: "password",
                                                                            label: "Password".translate(
                                                                                this.props.lang
                                                                            ),
                                                                            validate: [
                                                                                required(
                                                                                    "Password is required!".translate(
                                                                                        this.props.lang
                                                                                    )
                                                                                ),
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            type: "row",
                                                            children: [
                                                                {
                                                                    type: "col",
                                                                    width: {
                                                                        lg: 6,
                                                                        sm: 12,
                                                                        xs: 12,
                                                                    },
                                                                    children: [

                                                                    ],
                                                                },
                                                                {
                                                                    type: "col",
                                                                    width: {
                                                                        lg: 6,
                                                                        sm: 12,
                                                                        xs: 12,
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: "hidden",
                                                                            name: "forget",
                                                                            afterText: (
                                                                                <a
                                                                                    href="/forgotpassword"
                                                                                    className="forget"
                                                                                >
                                                                                    {"Forgot password?".translate(
                                                                                        this.props.lang
                                                                                    )}
                                                                                </a>
                                                                            ),
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ]}
                                                ></FormBuilder>
                                            </div>
                                            :
                                            null
                                    }


                                    {this.state.error ?
                                        <p style={{ color: 'red', paddingTop: 16, marginBottom: 0 }}>{this.state.error.translate(this.props.lang)}</p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                }



            </div>

        )
    }
}

export default LoginComponent;
