import React, { Component, useState } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import ListBuilder from '../../components/listBuilder';
import ErrorModal from '../../components/errorModal';
import InfoModal from '../../components/infoModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import viewIcon from '../../assets/svg/eye.svg';
import xIcon from '../../assets/svg/x.svg';
import Payson from '../../components/payson';
import Swish from '../swish';
import Card from '../../assets/svg/card.svg';
import warrning from '../../assets/svg/warrning.svg';
import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import info from '../../assets/svg/info.svg';
import changePayment from '../../assets/svg/change_payment.svg';
import download from '../../assets/svg/download.svg';
import moment from 'moment';

import PdfSave from '../../components/pdvSave';
import _assertThisInitialized from '@babel/runtime/helpers/assertThisInitialized';

import PayNowModal from '../../components/payNowModal';
import ListBuilderMob from '../../components/listBuilderMob';

import questionaryIcon from '../../assets/svg/questionary-mob.svg';
import caseIcon from '../../assets/svg/case-mob.svg';
import priceIcon from '../../assets/svg/payment-icon.svg';
import paymentMethodIcon from '../../assets/svg/payment-method-icon.svg';
import Stripe from '../../components/stripe';
import DeleteModal from '../../components/deleteModal';


/**
* Paid and unpaid referrals page
* @author   Stefan Pantic
*/
class MyPayments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            itemsInvoice: [],
            groups: [],
            total: 0,
            totalInvoice: 0,
            loading: true,
            changeButton: false,
            tabIndex: 1,
            payNowModal: false,
            choosenPayment: null,
            windowWidth: typeof window !== 'undefined' ? window?.innerWidth : null,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias'), patient: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias'), patient: this.props.uData._id })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }

    autoSelectPaymentMethod = () => {

        if (this.state.items && this.state.items.length > 0) {
            let items = this.state.items;
            for (let i = 0; i < items.length; i++) {
                let paymentMethods = [];
                if (items[i].priceList && !items[i].paymentMethod && !items[i].paymentCanceldStatus) {

                    if (items[i].priceList.paymentOnSite) {
                        paymentMethods.push('paymentOnSite')
                    }
                    if (items[i].priceList.invoice) {
                        paymentMethods.push('invoice')
                    }
                    if (items[i].priceList.freeCard) {
                        paymentMethods.push('freeCard')
                    }
                    if (items[i].priceList.onlinePayment) {
                        paymentMethods.push('onlinePayment')
                    }

                    if (paymentMethods.length == 1) {
                        if (paymentMethods[0] == 'paymentOnSite' || paymentMethods[0] == 'invoice') {
                            this.setState({
                                payNowModal: items[i]
                            })
                        }
                    }
                } else if (items[i].questionaryData && !items[i].paymentMethod && !items[i].paymentCanceldStatus) {
                    let paymentMethods = [];
                    if (items[i].questionaryData.paymentOnSite) {
                        paymentMethods.push('paymentOnSite')
                    }
                    if (items[i].questionaryData.invoice) {
                        paymentMethods.push('invoice')
                    }
                    if (items[i].questionaryData.haveFreeCard) {
                        paymentMethods.push('freeCard')
                    }
                    if (items[i].questionaryData.onlinePayment) {
                        paymentMethods.push('onlinePayment')
                    }

                    if (paymentMethods.length == 1) {
                        if (paymentMethods[0] == 'paymentOnSite' || paymentMethods[0] == 'invoice') {
                            if (items[i].questionaryData && items[i].questionaryData.group) {
                                fetch(API_ENDPOINT + '/data/clinic-groups/' + items[i].questionaryData.group, {
                                    method: 'GET',
                                    headers: {
                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                        'content-type': 'application/json'
                                    },
                                }).then(res => res.json()).then((result) => {
                                    this.setState({
                                        newReferralId: items[i]._id,
                                        payNowModalReferral: items[i].questionaryData,
                                        onlineDataPayment: items[i],
                                        group: result
                                    })
                                })

                            }
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
            window.addEventListener('resize', this.resizeWindow);
        }

        this.get();


        // this.interval = setInterval(() => {
        //     this.get();
        // }, 60 * 1000);


        // if (!localStorage.getItem('groupAlias')) {
        // this.props[0].history.push(`/home-patient-level`);
        // this.setState({
        //     groupError: true
        // })
        // }
        if (this.props.referralStatusBefore) {
            // this.setState({
            //     failureModal: true
            // })
            this.setState({
                failureModal2: true
            })
        }
        if (this.props[0].location.state && this.props[0].location.state.tab && this.props[0].location.state.tab == 2) {
            this.callbackAfterPaying()
        }
        if (this.props[0].location.state && this.props[0].location.state.successModal) {
            this.setState({ successModalError: this.props[0].location.state.successModal }, () => {
                this.props[0].history.push({
                    state: { successModal: null }
                })
            })
        }
        if (this.props[0].location.state && this.props[0].location.state.dropInErrorModal && this.props[0].location.state.questionaryData && this.props[0].location.state.refData && this.props[0].location.state.compliteReferral) {
            this.setState({
                dropInErrorModal: this.props[0].location.state.dropInErrorModal,
                dropInErrorModalData: this.props[0].location.state.questionaryData,
                dropInErrorModalReferralForDelete: this.props[0].location.state.refData,
                dropInErrorModalReferral: this.props[0].location.state.compliteReferral
            }, () => {
                this.props[0].history.push({
                    state: { dropInErrorModal: null, questionaryData: null, refData: null, compliteReferral: null }
                })
            })
        }

        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }

    }
    registerEvents = async () => {

        this.props.socket.on('newPayment', (data) => {
            this.get();
        })




    }
    componentWillUnmount() {
        // if (this.interval) {
        //     clearInterval(this.interval)
        // }
        window.removeEventListener('resize', this.resizeWindow);

        if (this.props.socket) {
            this.props.socket.removeListener('newPayment');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

        if (this.state.tabIndex == 1 && this.state.items && prevState.items && this.state.items != prevState.items) {
            this.autoSelectPaymentMethod();
        }

    }

    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false, getWhenDone = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj }, () => { if (getWhenDone) this.get() });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj }, () => { if (getWhenDone) this.get() })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }

    /**
    * Delete referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    deleteReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient-schedule/delete/' + data.toString(), {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {

                this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
            })
        })
    }

    /**
    * Return group for referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    findGroup = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referral-group/' + data._id.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {

                this.setState({
                    referral: data,
                    group: result.group,
                    onlinePayment: result.onlinePayment,
                    loading: null
                })
            })
        })
    }
    openPayson = (data) => {
        this.setState({
            paysonModalControl: data,

        })
    }
    openStripe = (data) => {
        this.setState({
            stripeModalControl: false
        }, () => {
            this.setState({
                stripeModalControl: data,

            })
        })

    }
    openSwish = (data) => {
        this.setState({
            swishModalControl: data,

        }, () => {
            // console.log(this.state.swishModalControl)
        })
    }

    /**
    * Change payment method for referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    updatePaymentMethod = (data, check) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referral-change-payment/' + data._id.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ check: check, paymentMethod: this.state.onlinePaymentMethodChange })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    paymnetOptions: result.obj
                }, () => {
                    // console.log(this.state.paymnetOptions)
                })
                this.get();
                if (this.state.changePaymentModal) {
                    this.setState({
                        changePaymentModal: null
                    })
                } else {
                    this.setState({
                        changePaymentModal: data
                    })
                }

            })
        })
    }

    updatePaymentMethodControl = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/working-hours/control-change-payment/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.setState({
                    paymnetOptionsControl: result.obj
                }, () => {
                    // console.log(this.state.paymnetOptions)
                })
                this.get();


            })
        })
    }

    updatePaymentControl = (data) => {
        // console.log(data)
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                // console.log(result)
                if (result.paymentMethod == 1) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Payment must be made "On site" before the visit.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else if (result.paymentMethod == 2) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                }
            })
        })
    }
    updatePaymentMethodFreeCard = (data) => {
        // console.log(this.state.payNowModal)
        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowModal && this.state.payNowModal.startEventReservedDate && (new Date(data.freeCardValidFrom) > new Date(this.state.payNowModal.startEventReservedDate) || new Date(data.freeCardValidTo) < new Date(this.state.payNowModal.startEventReservedDate))) {
            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            // alert(data)
            data.freeCard = true;
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        loading: false,
                        successModal: 'Remember to take your free card with you.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)
                        this.setState({ tabIndex: 2 }, () => { this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true) })

                    })
                    this.get();


                })
            })
        }


    }
    updatePaymentMethodFreeCardReferral = (data) => {

        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.onlineDataPayment && this.state.onlineDataPayment.patientReservedTimestamp && (new Date(data.freeCardValidFrom) > new Date(this.props.getStringDateTs(this.state.onlineDataPayment.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.onlineDataPayment.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/) || new Date(data.freeCardValidTo) < new Date(this.props.getStringDateTs(this.state.onlineDataPayment.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.onlineDataPayment.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/))) {
            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            // alert(JSON.stringify(data))
            data.freeCard = true;
            let onlinePaymentMethod = this.state.onlinePaymentMethod;
            onlinePaymentMethod = 1
            // let referral = this.state.referral;
            // referral.price = 0
            // alert(data)
            this.setState({
                onlinePaymentMethod,
                // referral,
                freeCardData: data
            }, () => {
                // alert(data)
                this.updateReferral()
            })
        }

    }

    // delete = (data) => {
    //     fetch(API_ENDPOINT + '//bills/data/delete/' + data._id, {
    //         method: 'DELETE',
    //         headers: {
    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //             'content-type': 'application/json'
    //         },
    //         body: JSON.stringify({})
    //     }).then(res => res.json()).then((result) => {
    //         this.setState({
    //             deleteBill: null
    //         })
    //         this.get();


    //     })
    // }

    callbackAfterPaying = () => {
        this.setState({ payNowModal: null, swishModalControl: null, swishModal: null, tabIndex: 2, successModal: 'Payment successful.'.translate(this.props.lang) }, () => {

            this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true);
            this.props[0].history.push({
                state: { tab: null }
            })
        })
    }

    updateReferral = () => {
        let data = {
            freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
            paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
        }
        if (this.state.freeCardData) {
            data.freeCardData = this.state.freeCardData
        }

        if (this.state.payson) {
            data.payson = true
        }
        if (this.state.swish) {
            data.swish = true
        }
        if (this.state.stripe) {
            data.stripe = true
        }
        if (data.paymentMethod == 2) {
            data.requsetedInvoice = true;
        }

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referrals/update/' + this.state.newReferralId.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.paymentMethod == 2 || result.paymentMethod == 1) {
                        this.setState({
                            payNowModalReferral: null
                        })
                        if (result.paymentMethod == 1) {
                            this.setState({
                                successModal: 'Payment must be made "On site" before the visit.'.translate(this.props.lang)
                            })
                        } else if (result.paymentMethod == 2) {
                            this.setState({
                                successModal: 'Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'.translate(this.props.lang)
                            })
                        }
                    } else if (result.payson) {
                        this.setState({
                            paysonModal: this.state.onlineDataPayment,
                            payNowModalReferral: null
                        })
                    } else if (result.swish) {
                        this.setState({
                            swishModal: this.state.onlineDataPayment,
                            payNowModalReferral: null
                        })
                    } else if (result.stripe) {
                        this.setState({
                            stripeModal: false
                        }, () => {
                            this.setState({
                                stripeModal: this.state.onlineDataPayment,
                                // payNowModalReferral: null
                            })
                        })

                    } else if (result.freePayment == 1) {
                        // alert('drugo')
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + this.state.newReferralId.toString(), {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                payNowModalReferral: null,
                                successModal: 'Remember to take your free card with you.'.translate(this.props.lang),

                            }, () => {
                                this.setState({ tabIndex: 2 }, () => {
                                    this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 20 }], true, true);
                                    // this.get();
                                })
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                            })
                        })

                    }

                    this.get();
                }
                this.setState({
                    loading: false,
                    freeCardData: null
                })


            })
        })

    }

    checkPaymentOptions = (data) => {
        let moreThenOneOption = true;
        if (data.priceList) {
            let numberOfPaymentOptions = 0;
            if (data.priceList.paymentOnSite) {
                numberOfPaymentOptions++
            }
            if (data.priceList.invoice) {
                numberOfPaymentOptions++
            }
            if (data.priceList.freeCard) {
                numberOfPaymentOptions++
            }
            if (data.priceList.onlinePayment) {
                numberOfPaymentOptions++
            }

            if (numberOfPaymentOptions == 1) {
                moreThenOneOption = false;
            }
        } else if (data.questionaryData) {
            let numberOfPaymentOptions = 0;
            if (data.questionaryData.paymentOnSite) {
                numberOfPaymentOptions++
            }
            if (data.questionaryData.invoice) {
                numberOfPaymentOptions++
            }
            if (data.questionaryData.haveFreeCard) {
                numberOfPaymentOptions++
            }
            if (data.questionaryData.onlinePayment) {
                numberOfPaymentOptions++
            }

            if (numberOfPaymentOptions == 1) {
                moreThenOneOption = false;
            }
        }

        return moreThenOneOption
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }

        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid >
                    <Row style={{ marginLeft: (this.state.windowWidth && this.state.windowWidth < 768) ? 3 : null, marginRight: (this.state.windowWidth && this.state.windowWidth < 768) ? 3 : null }}>
                        <div className='panel'>
                            <h5 className="component-header">{'My payments'.translate(this.props.lang)}</h5>
                            {(this.state.windowWidth === null || this.state.windowWidth > 768) ? <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 }, () => { this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 20 }, { name: 'filter', value: '' }], true, true); })}>{"Unpaid".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 }, () => { this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 20 }, { name: 'filter', value: '' }], true, true); })}>{"Paid cases".translate(this.props.lang)}</Button>
                                </div>
                            </Col> : null}

                            {
                                this.state.tabIndex == 1 ?
                                    (this.state.windowWidth !== null && this.state.windowWidth < 768) ?
                                        <div>
                                            {this.state.items?.length <= 0 ?
                                                <p className='no-conversations-p'>{"No payments".translate(this.props.lang)}</p> : null}
                                            <ListBuilderMob
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.total}
                                                showNumeration={false}
                                                rawItems={this.state.items}
                                                loadMoreItems={() => {
                                                    this.updateParams('entries', Number(params?.entries) + 10)
                                                }}
                                                disableLoadMore={this.state.items && this.state.total && Number(this.state.total) <= this.state.items?.length}
                                                fields={[
                                                    { type: 'title', name: 'recpientClinicNameMob' },
                                                    { type: 'subtitle', name: 'recpientClinicSubgroupNameMob' },
                                                    { type: 'subtitle', name: 'doctorNameMob' },
                                                    { type: 'item', name: 'paymentCanceldStatusMob', condition: item => item?.paymentCanceldStatusMob ? true : false },
                                                    { type: 'item', name: 'questionaryName', svg: questionaryIcon },
                                                    { type: 'item', name: 'version', svg: caseIcon },
                                                    { type: 'item', name: 'price', svg: priceIcon, condition: item => item?.price ? true : false },
                                                    { type: 'item', name: 'methodPaymentMob', svg: paymentMethodIcon, condition: item => item?.methodPaymentMob ? true : false }
                                                ]}
                                                filterFields={[
                                                    {
                                                        name: 'Unpaid'.translate(this.props.lang), onClick: () => {
                                                            if (this.state.tabIndex === 1) return;
                                                            this.setState({ tabIndex: 1 }, () => {
                                                                this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true)
                                                                // this.get()
                                                            })
                                                        },
                                                        active: this.state.tabIndex === 1
                                                    },
                                                    {
                                                        name: 'Paid cases'.translate(this.props.lang),
                                                        onClick: () => {
                                                            if (this.state.tabIndex === 2) return;
                                                            this.setState({ tabIndex: 2 }, () => {
                                                                this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true)
                                                                // this.get()
                                                            })
                                                        },
                                                        active: this.state.tabIndex === 2
                                                    }
                                                ]}
                                                items={this.state.items.map((item => {
                                                    // console.log(item)
                                                    return {
                                                        ...item,

                                                        control: item.type == 'revisit' ? item.type : '',
                                                        questionaryName: this.props.lang == 'en' ? item.questionaryName ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.visitReasonName ? item.visitReasonName : '' : item.questionaryName ? item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)') : item.visitReasonName ? item.visitReasonName : '',
                                                        version: item.patient ? item.patient.referralNumber + (item.version != 0 ? '.' + item.version : '') : item.controlNumber ? `${item.controlNumber}` : '',
                                                        recpientClinicName: <>{item.recpientClinicName ? item.recpientClinicName : ''}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName ? item.recpientClinicSubgroupName : ''} </span><br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span>  </>,
                                                        patient: <>{item.patient ? item.patient.name : item.patientName ? item.patientName : ''}<br /><span className="clinic-subgroup-name">{item.patient ? item.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : ''} </span> </>,
                                                        price: item.questionaryPrice ? item.questionaryPrice + " " + currency : '',
                                                        date: this.props.getStringDateTs(item.tsCreated, this.props.dateFormat)/*moment.unix(item.tsCreated).format(this.props.dateFormat)*/,
                                                        recpientClinicNameMob: item.recpientClinicName ? item.recpientClinicName : '',
                                                        recpientClinicSubgroupNameMob: item?.recpientClinicSubgroupName ? item.recpientClinicSubgroupName : '',
                                                        doctorNameMob: item?.doctorName ? item.doctorName : '',
                                                        paymentCanceldStatusMob: item.paymentCanceldStatus ? <p style={{ color: 'red' }}>{"Visit has been cancelled".translate(this.props.lang)}</p> : '',
                                                        methodPaymentMob: Number(item.questionaryPrice) === 0 ? 'Free of charge'.translate(this.props.lang) : item.paymentMethod ? item.paymentMethod === 1 ? <div><div>{'On site'.translate(this.props.lang)}</div><div>{'Pending payment'.translate(this.props.lang)}</div></div> : item.paymentMethod === 2 ? item.attachedInvoice ? <div><div>{'Invoice'.translate(this.props.lang)}</div><div>{'Pending payment'.translate(this.props.lang)}</div></div> : <div><div>{'Invoice'.translate(this.props.lang)}</div><div>{'Invoice requested'.translate(this.props.lang)}</div></div> : item.paymentMethod === 3 ? 'Online'.translate(this.props.lang) : '' : item.freePayment ? item.freePayment === 1 ? 'Free card'.translate(this.props.lang) : item.freePayment === 2 ? 'Under 23'.translate(this.props.lang) : item.freePayment === 3 ? 'Under 20'.translate(this.props.lang) : item.freePayment === 4 ? 'Under 18'.translate(this.props.lang) : item.freePayment === 5 ? 'Older than 85'.translate(this.props.lang) : item.freePayment === 6 ? 'Under 18 (child)'.translate(this.props.lang) : null : null,
                                                        methodPayment:
                                                            item.type == 'revisit' && item.paymentMethod ?
                                                                item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                        <div>{'On site'.translate(this.props.lang)}</div>
                                                                        {
                                                                            item.paymentCanceldStatus ?
                                                                                <Button color='primary' className='button-change-payment-method' onClick={() => this.setState({ payNowModal: item })}>
                                                                                    {'Change payment method'.translate(this.props.lang)}
                                                                                </Button>
                                                                                :
                                                                                null
                                                                        }

                                                                        {/* <div className="tabel-action-tooltip" onClick={() => this.setState({ payNowModal: item })}>
                                                                        <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                        <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                        </div>
                                                                    </div> */}
                                                                    </div>
                                                                    : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                            <div>
                                                                                {'Invoice'.translate(this.props.lang)}
                                                                            </div>
                                                                            {
                                                                                item.paymentCanceldStatus ?
                                                                                    <Button color='primary' className='button-change-payment-method' onClick={() => this.setState({ payNowModal: item })}>
                                                                                        {'Change payment method'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {/* <div className="tabel-action-tooltip" onClick={() => this.setState({ payNowModal: item })}>
                                                                            <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                            <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </div>
                                                                        </div> */}
                                                                        </div>
                                                                        : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                <div>
                                                                                    {'Online'.translate(this.props.lang)}
                                                                                </div>
                                                                                {
                                                                                    item.paymentCanceldStatus ?
                                                                                        <Button color='primary' className='button-change-payment-method' /*onClick={() => this.setState({ payNowModal: item })}*/>
                                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {/* <div className="tabel-action-tooltip" >
                                                                                <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                                <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                    {'Change payment method'.translate(this.props.lang)}
                                                                                </div>
                                                                            </div> */}
                                                                            </div>
                                                                            :
                                                                            ''
                                                                : item.questionaryPrice == 0 ?
                                                                    'Free of charge'.translate(this.props.lang)
                                                                    :
                                                                    item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                            <div>
                                                                                {'On site'.translate(this.props.lang)}
                                                                            </div>
                                                                            {
                                                                                item.paymentCanceldStatus ?
                                                                                    <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                        if (item.questionaryData && item.questionaryData.group) {
                                                                                            fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                                method: 'GET',
                                                                                                headers: {
                                                                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                    'content-type': 'application/json'
                                                                                                },
                                                                                            }).then(res => res.json()).then((result) => {
                                                                                                this.setState({
                                                                                                    newReferralId: item._id,
                                                                                                    payNowModalReferral: item.questionaryData,
                                                                                                    onlineDataPayment: item,
                                                                                                    group: result
                                                                                                })
                                                                                            })

                                                                                        }
                                                                                    }}>
                                                                                        {'Change payment method'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {/* <div className="tabel-action-tooltip" onClick={() => {
                                                                            if (item.questionaryData && item.questionaryData.group) {
                                                                                fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                    method: 'GET',
                                                                                    headers: {
                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                        'content-type': 'application/json'
                                                                                    },
                                                                                }).then(res => res.json()).then((result) => {
                                                                                    this.setState({
                                                                                        newReferralId: item._id,
                                                                                        payNowModalReferral: item.questionaryData,
                                                                                        onlineDataPayment: item,
                                                                                        group: result
                                                                                    })
                                                                                })

                                                                            }
                                                                        }}>
                                                                            <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                            <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </div>
                                                                        </div> */}
                                                                        </div>
                                                                        :
                                                                        item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                <div>
                                                                                    {'Invoice'.translate(this.props.lang)}
                                                                                </div>
                                                                                {
                                                                                    this.checkPaymentOptions(item) ?
                                                                                        <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                            if (item.questionaryData && item.questionaryData.group) {
                                                                                                fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                                    method: 'GET',
                                                                                                    headers: {
                                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                        'content-type': 'application/json'
                                                                                                    },
                                                                                                }).then(res => res.json()).then((result) => {
                                                                                                    this.setState({
                                                                                                        newReferralId: item._id,
                                                                                                        payNowModalReferral: item.questionaryData,
                                                                                                        onlineDataPayment: item,
                                                                                                        group: result
                                                                                                    })
                                                                                                })

                                                                                            }
                                                                                        }}>
                                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {/* <div className="tabel-action-tooltip" onClick={() => {
                                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                        method: 'GET',
                                                                                        headers: {
                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                            'content-type': 'application/json'
                                                                                        },
                                                                                    }).then(res => res.json()).then((result) => {
                                                                                        this.setState({
                                                                                            newReferralId: item._id,
                                                                                            payNowModalReferral: item.questionaryData,
                                                                                            onlineDataPayment: item,
                                                                                            group: result
                                                                                        })
                                                                                    })

                                                                                }
                                                                            }}>
                                                                                <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                                <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                    {'Change payment method'.translate(this.props.lang)}
                                                                                </div>
                                                                            </div> */}
                                                                            </div>
                                                                            :
                                                                            item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                    <div >
                                                                                        {'Online'.translate(this.props.lang)}
                                                                                    </div>
                                                                                    {/* <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                        method: 'GET',
                                                                                        headers: {
                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                            'content-type': 'application/json'
                                                                                        },
                                                                                    }).then(res => res.json()).then((result) => {
                                                                                        this.setState({
                                                                                            newReferralId: item._id,
                                                                                            payNowModalReferral: item.questionaryData,
                                                                                            onlineDataPayment: item,
                                                                                            group: result
                                                                                        })
                                                                                    })

                                                                                }
                                                                            }}>
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </Button> */}
                                                                                    {/*<div><Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} /><div className="chat-icons-tooltip">{'Change payment method'.translate(this.props.lang)}</div></div>*/}
                                                                                </div>
                                                                                : item.freePayment == 1 ? 'Free card'.translate(this.props.lang)
                                                                                    : item.freePayment == 2 ? 'Under 23'.translate(this.props.lang)
                                                                                        : item.freePayment == 3 ? 'Under 20'.translate(this.props.lang)
                                                                                            : item.freePayment == 4 ? 'Under 18'.translate(this.props.lang)
                                                                                                : item.freePayment == 5 ? 'Older than 85'.translate(this.props.lang)
                                                                                                    : item.freePayment == 6 ? 'Under 18 (child)'.translate(this.props.lang)
                                                                                                        :
                                                                                                        null,
                                                        status: item.paymentMethod ?
                                                            item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                'Pending payment'.translate(this.props.lang)
                                                                :
                                                                item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && !item.attachedInvoice ?
                                                                    'Invoice requested'.translate(this.props.lang)
                                                                    :
                                                                    item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && item.attachedInvoice ?
                                                                        'Pending payment'.translate(this.props.lang)

                                                                        :
                                                                        ''
                                                            :
                                                            null,
                                                    }
                                                }))}
                                                actions={[
                                                    {
                                                        component: <div className='list-builder-button-mob'>{'Pay now'.translate(this.props.lang)}</div>,
                                                        buttonClassName: 'blue-button-list-builder-mob',
                                                        onClick: item => {
                                                            if (item.type != 'revisit') {
                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                        method: 'GET',
                                                                        headers: {
                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                            'content-type': 'application/json'
                                                                        },
                                                                    }).then(res => res.json()).then((result) => {
                                                                        this.setState({
                                                                            newReferralId: item._id,
                                                                            payNowModalReferral: item.questionaryData,
                                                                            onlineDataPayment: item,
                                                                            group: result
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({ payNowModal: item })

                                                            }
                                                        },
                                                        condition: item => (item.paymentMethod !== 1 && item.paymentMethod !== 2 && item.questionaryPrice) ? true : false,
                                                    },
                                                    {
                                                        component: <div className='list-builder-button-mob'>{'Change payment method'.translate(this.props.lang)}</div>,
                                                        buttonClassName: 'blue-button-list-builder-mob',
                                                        onClick: item => {
                                                            if (item?.type === 'revisit') return this.setState({ payNowModal: item });
                                                            else {
                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                        method: 'GET',
                                                                        headers: {
                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                            'content-type': 'application/json'
                                                                        },
                                                                    }).then(res => res.json()).then((result) => {
                                                                        this.setState({
                                                                            newReferralId: item._id,
                                                                            payNowModalReferral: item.questionaryData,
                                                                            onlineDataPayment: item,
                                                                            group: result
                                                                        })
                                                                    })

                                                                }
                                                            }
                                                        },
                                                        condition: item => item && item.paymentMethod && item.questionaryPrice && +item.questionaryPrice > 0 && [1, 2].includes(item.paymentMethod)
                                                    },
                                                    {
                                                        component: <div className='list-builder-button-mob'>{'Download invoice'.translate(this.props.lang)}</div>,
                                                        buttonClassName: 'blue-button-list-builder-mob',
                                                        onClick: item => {
                                                            if (item?.attachedInvoice && typeof window !== 'undefined') {
                                                                window.open(API_ENDPOINT + item?.attachedInvoice + `?mt=${this.props.uData ? this.props.uData?.mediaToken : ''}&rid=${item?._id}`, '_blank')
                                                            }
                                                        },
                                                        condition: item => (item?.paymentMethod && item?.paymentMethod == 2 && item?.attachedInvoice) ? true : false
                                                    }
                                                ]}
                                                params={params}
                                                sortField={params?.sortField}
                                                sortType={params?.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            />
                                        </div>

                                        :
                                        <Col lg="12" className='col-no-padding'>
                                            <h5 className="component-header">{'Unpaid'.translate(this.props.lang)}</h5>
                                            {this.state.items?.length <= 0 ?
                                                <p className='no-conversations-p'>{"No payments".translate(this.props.lang)}</p> : null}


                                            <ListBuilder
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.total}
                                                showNumeration={false}
                                                fields={[
                                                    { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'questionaryName', label: 'Cause'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: false },
                                                    // { type: 'text', name: 'revisit', label: 'revisit'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang) },
                                                ]}
                                                rawItems={this.state.items}
                                                items={this.state.items.map((item => {
                                                    // console.log(item)
                                                    return {
                                                        ...item,
                                                        control: item.type == 'revisit' ? item.type : '',
                                                        questionaryName: this.props.lang == 'en' ? item.questionaryName ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.visitReasonName ? item.visitReasonName : '' : item.questionaryName ? item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)') : item.visitReasonName ? item.visitReasonName : '',
                                                        version: item.patient ? item.patient.referralNumber + (item.version != 0 ? '.' + item.version : '') : item.controlNumber ? `${item.controlNumber}` : '',
                                                        recpientClinicName: <>{item.recpientClinicName ? item.recpientClinicName : ''}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName ? item.recpientClinicSubgroupName : ''} </span><br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span>  </>,
                                                        patient: <>{item.patient ? item.patient.name : item.patientName ? item.patientName : ''}<br /><span className="clinic-subgroup-name">{item.patient ? item.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : ''} </span> </>,
                                                        price: item.questionaryPrice ? item.questionaryPrice + " " + currency : '',
                                                        date: this.props.getStringDateTs(item.tsCreated, this.props.dateFormat)/*moment.unix(item.tsCreated).format(this.props.dateFormat)*/,
                                                        methodPayment:
                                                            item.paymentCanceldStatus ? <p style={{ color: 'red' }}>{"Visit has been cancelled".translate(this.props.lang)}</p> :
                                                                item.type == 'revisit' && item.paymentMethod ?
                                                                    item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                            <div>{'On site'.translate(this.props.lang)}</div>
                                                                            {
                                                                                this.checkPaymentOptions(item) ?
                                                                                    <Button color='primary' className='button-change-payment-method' onClick={() => this.setState({ payNowModal: item })}>
                                                                                        {'Change payment method'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {/* <div className="tabel-action-tooltip" onClick={() => this.setState({ payNowModal: item })}>
                                                                        <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                        <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                        </div>
                                                                    </div> */}
                                                                        </div>
                                                                        : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                <div>
                                                                                    {'Invoice'.translate(this.props.lang)}
                                                                                </div>
                                                                                {
                                                                                    this.checkPaymentOptions(item) ?
                                                                                        <Button color='primary' className='button-change-payment-method' onClick={() => this.setState({ payNowModal: item })}>
                                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {/* <div className="tabel-action-tooltip" onClick={() => this.setState({ payNowModal: item })}>
                                                                            <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                            <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </div>
                                                                        </div> */}
                                                                            </div>
                                                                            : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                    <div>
                                                                                        {'Online'.translate(this.props.lang)}
                                                                                    </div>
                                                                                    {
                                                                                        this.checkPaymentOptions(item) ?
                                                                                            <Button color='primary' className='button-change-payment-method' /*onClick={() => this.setState({ payNowModal: item })}*/>
                                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                    {/* <div className="tabel-action-tooltip" >
                                                                                <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                                <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                    {'Change payment method'.translate(this.props.lang)}
                                                                                </div>
                                                                            </div> */}
                                                                                </div>
                                                                                :
                                                                                ''
                                                                    : item.questionaryPrice == 0 ?
                                                                        'Free of charge'.translate(this.props.lang)
                                                                        :
                                                                        item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                <div>
                                                                                    {'On site'.translate(this.props.lang)}
                                                                                </div>
                                                                                {
                                                                                    this.checkPaymentOptions(item) ?
                                                                                        <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                            if (item.questionaryData && item.questionaryData.group) {
                                                                                                fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                                    method: 'GET',
                                                                                                    headers: {
                                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                        'content-type': 'application/json'
                                                                                                    },
                                                                                                }).then(res => res.json()).then((result) => {
                                                                                                    this.setState({
                                                                                                        newReferralId: item._id,
                                                                                                        payNowModalReferral: item.questionaryData,
                                                                                                        onlineDataPayment: item,
                                                                                                        group: result
                                                                                                    })
                                                                                                })

                                                                                            }
                                                                                        }}>
                                                                                            {'Change payment method'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {/* <div className="tabel-action-tooltip" onClick={() => {
                                                                            if (item.questionaryData && item.questionaryData.group) {
                                                                                fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                    method: 'GET',
                                                                                    headers: {
                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                        'content-type': 'application/json'
                                                                                    },
                                                                                }).then(res => res.json()).then((result) => {
                                                                                    this.setState({
                                                                                        newReferralId: item._id,
                                                                                        payNowModalReferral: item.questionaryData,
                                                                                        onlineDataPayment: item,
                                                                                        group: result
                                                                                    })
                                                                                })

                                                                            }
                                                                        }}>
                                                                            <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                            <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </div>
                                                                        </div> */}
                                                                            </div>
                                                                            :
                                                                            item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                    <div>
                                                                                        {'Invoice'.translate(this.props.lang)}
                                                                                    </div>
                                                                                    {
                                                                                        this.checkPaymentOptions(item) ?
                                                                                            <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                                        method: 'GET',
                                                                                                        headers: {
                                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                            'content-type': 'application/json'
                                                                                                        },
                                                                                                    }).then(res => res.json()).then((result) => {
                                                                                                        this.setState({
                                                                                                            newReferralId: item._id,
                                                                                                            payNowModalReferral: item.questionaryData,
                                                                                                            onlineDataPayment: item,
                                                                                                            group: result
                                                                                                        })
                                                                                                    })

                                                                                                }
                                                                                            }}>
                                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                    {/* <div className="tabel-action-tooltip" onClick={() => {
                                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                        method: 'GET',
                                                                                        headers: {
                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                            'content-type': 'application/json'
                                                                                        },
                                                                                    }).then(res => res.json()).then((result) => {
                                                                                        this.setState({
                                                                                            newReferralId: item._id,
                                                                                            payNowModalReferral: item.questionaryData,
                                                                                            onlineDataPayment: item,
                                                                                            group: result
                                                                                        })
                                                                                    })

                                                                                }
                                                                            }}>
                                                                                <Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} />
                                                                                <div style={{ width: 180 }} className="chat-icons-tooltip">
                                                                                    {'Change payment method'.translate(this.props.lang)}
                                                                                </div>
                                                                            </div> */}
                                                                                </div>
                                                                                :
                                                                                item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ?
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <div >
                                                                                            {'Online'.translate(this.props.lang)}
                                                                                        </div>
                                                                                        {/* <Button color='primary' className='button-change-payment-method' onClick={() => {
                                                                                if (item.questionaryData && item.questionaryData.group) {
                                                                                    fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                                        method: 'GET',
                                                                                        headers: {
                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                            'content-type': 'application/json'
                                                                                        },
                                                                                    }).then(res => res.json()).then((result) => {
                                                                                        this.setState({
                                                                                            newReferralId: item._id,
                                                                                            payNowModalReferral: item.questionaryData,
                                                                                            onlineDataPayment: item,
                                                                                            group: result
                                                                                        })
                                                                                    })

                                                                                }
                                                                            }}>
                                                                                {'Change payment method'.translate(this.props.lang)}
                                                                            </Button> */}
                                                                                        {/*<div><Isvg src={changePayment} style={{ width: 24, minWidth: 24, cursor: 'pointer' }} /><div className="chat-icons-tooltip">{'Change payment method'.translate(this.props.lang)}</div></div>*/}
                                                                                    </div>
                                                                                    : item.freePayment == 1 ? 'Free card'.translate(this.props.lang)
                                                                                        : item.freePayment == 2 ? 'Under 23'.translate(this.props.lang)
                                                                                            : item.freePayment == 3 ? 'Under 20'.translate(this.props.lang)
                                                                                                : item.freePayment == 4 ? 'Under 18'.translate(this.props.lang)
                                                                                                    : item.freePayment == 5 ? 'Older than 85'.translate(this.props.lang)
                                                                                                        : item.freePayment == 6 ? 'Under 18 (child)'.translate(this.props.lang)
                                                                                                            :
                                                                                                            null,
                                                        status: item.paymentMethod ?
                                                            item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                'Pending payment'.translate(this.props.lang)
                                                                :
                                                                item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && !item.attachedInvoice ?
                                                                    'Invoice requested'.translate(this.props.lang)
                                                                    :
                                                                    item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && item.attachedInvoice ?
                                                                        'Pending payment'.translate(this.props.lang)

                                                                        :
                                                                        ''
                                                            :
                                                            null,
                                                    }
                                                }))}
                                                actions={
                                                    [
                                                        {
                                                            component: <Button color="primary" style={{ marginRight: 10 }}>{"Pay now".translate(this.props.lang)}</Button>,
                                                            onClick: (item) => {
                                                                if (item.type != 'revisit') {
                                                                    if (item.questionaryData && item.questionaryData.group) {
                                                                        fetch(API_ENDPOINT + '/data/clinic-groups/' + item.questionaryData.group, {
                                                                            method: 'GET',
                                                                            headers: {
                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                'content-type': 'application/json'
                                                                            },
                                                                        }).then(res => res.json()).then((result) => {
                                                                            this.setState({
                                                                                newReferralId: item._id,
                                                                                payNowModalReferral: item.questionaryData,
                                                                                onlineDataPayment: item,
                                                                                group: result
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({ payNowModal: item })

                                                                }
                                                            },
                                                            condition: (item) => {
                                                                if (item.paymentMethod != 1 && item.paymentMethod != 2 && item.questionaryPrice) {
                                                                    return true
                                                                }
                                                            }
                                                        },
                                                        // {
                                                        //     component: <Button color="primary" style={{ marginRight: 10 }}>{"Cancel".translate(this.props.lang)}</Button>,
                                                        //     onClick: (item) => {
                                                        //         this.setState({
                                                        //             deleteBill: item
                                                        //         })
                                                        //     },
                                                        //     condition: (item) => {
                                                        //         if (item.paymentCanceldStatus) {
                                                        //             return true
                                                        //         }
                                                        //     }
                                                        // },

                                                        // {
                                                        //     component: <Button color="primary" style={{ marginRight: 10 }}>{"Change payment method".translate(this.props.lang)}</Button>,
                                                        //     onClick: (item) => {
                                                        //         if (item.type == 'revisit') {
                                                        //             this.updatePaymentMethodControl(item)
                                                        //         } else {
                                                        //             this.updatePaymentMethod(item, true)

                                                        //         }
                                                        //     },
                                                        //     condition: (item) => {
                                                        //         if (item.dropIn && item.methodPayment && item.paymentMethod == 3) {
                                                        //             return false
                                                        //         } else if (item.methodPayment && item.paymentMethod == 3) {
                                                        //             return true
                                                        //         }
                                                        //     }
                                                        // },
                                                        // {
                                                        //     component: <Button color="primary" style={{ marginRight: 10 }}>{"Pay online".translate(this.props.lang)}</Button>,
                                                        //     onClick: (item) => this.findGroup(item),
                                                        //     condition: (item) => {
                                                        //         if (item.paymentMethod && item.paymentMethod == 3) {
                                                        //             return true
                                                        //         }
                                                        //     }
                                                        // },
                                                        {
                                                            component: <h6 style={{ fontSize: 14, fontWeight: 400 }}>{'Pending payment'.translate(this.props.lang)}</h6>,
                                                            onClick: (item) => {
                                                                // console.log(item.methodPayment)
                                                                if (item.paymentMethod && item.paymentMethod == 2 && item.attachedInvoice) {

                                                                    if (typeof window != 'undefined') {

                                                                        window.open(API_ENDPOINT + item.attachedInvoice + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&rid=${item._id}`, '_blank')
                                                                    }
                                                                }
                                                            },
                                                            condition: (item) => {
                                                                if (item.methodPayment && ((item.paymentMethod == 1 && item.status == 'Pending payment'.translate(this.props.lang)) || (item.paymentMethod == 2 && item.status == 'Pending payment'.translate(this.props.lang)))) {
                                                                    return true
                                                                }
                                                            }
                                                        },
                                                        {
                                                            component: <div className="tabel-action-tooltip"><Isvg src={download} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Download Invoice'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => {
                                                                if (typeof window != 'undefined') {

                                                                    window.open(API_ENDPOINT + item.attachedInvoice + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&rid=${item._id}`, '_blank')
                                                                }
                                                            },
                                                            condition: (item) => {
                                                                if (item.paymentMethod && item.paymentMethod == 2 && item.attachedInvoice) {
                                                                    return true
                                                                }
                                                            }
                                                        },

                                                        {
                                                            component: <h6 style={{ fontSize: 14, fontWeight: 400 }}>{'Invoice requested'.translate(this.props.lang)}</h6>,
                                                            onClick: (item) => { },
                                                            condition: (item) => {
                                                                if (item.paymentMethod && item.paymentMethod == 2 && item.status == 'Invoice requested'.translate(this.props.lang)) {
                                                                    return true
                                                                }
                                                            }
                                                        },


                                                    ]
                                                }
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            // hideFilters={true}
                                            ></ListBuilder>



                                        </Col>
                                    :
                                    null
                            }
                            {
                                this.state.tabIndex == 2 ?
                                    (this.state.windowWidth !== null && this.state.windowWidth < 768) ? <div>
                                        {this.state.itemsInvoice?.length <= 0 ?
                                            <p className='no-conversations-p'>{"No payments".translate(this.props.lang)}</p> : null}
                                        <ListBuilderMob
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.totalInvoice}
                                            showNumeration={false}
                                            rawItems={this.state.itemsInvoice}
                                            loadMoreItems={() => {
                                                this.updateParams('entries', Number(params?.entries) + 10)
                                            }}
                                            disableLoadMore={this.state.itemsInvoice && this.state.totalInvoice && Number(this.state.totalInvoice) <= this.state.itemsInvoice?.length}
                                            fields={[
                                                { type: 'title', name: 'clinic' },
                                                { type: 'subtitle', name: 'clinicAddress' },
                                                // { type: 'item', name: 'questionaryName', svg: questionaryIcon },
                                                { type: 'item', name: 'version', svg: caseIcon },
                                                { type: 'item', name: 'price', svg: priceIcon, condition: item => item?.price ? true : false },
                                                { type: 'item', name: 'methodPayment', svg: paymentMethodIcon, condition: item => item?.methodPayment ? true : false }
                                            ]}
                                            filterFields={[
                                                {
                                                    name: 'Unpaid'.translate(this.props.lang), onClick: () => {
                                                        if (this.state.tabIndex === 1) return;
                                                        this.setState({ tabIndex: 1 }, () => {
                                                            this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true)
                                                            // this.get()
                                                        })
                                                    },
                                                    active: this.state.tabIndex === 1
                                                },
                                                {
                                                    name: 'Paid cases'.translate(this.props.lang),
                                                    onClick: () => {
                                                        if (this.state.tabIndex === 2) return;
                                                        this.setState({ tabIndex: 2 }, () => {
                                                            this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }], true, true)
                                                            // this.get()
                                                        })
                                                    },
                                                    active: this.state.tabIndex === 2
                                                }
                                            ]}
                                            items={this.state.itemsInvoice?.map((item => {
                                                return {
                                                    ...item,
                                                    price: item?.price ? item.price + " " + currency : '',
                                                    version: item?.version || '',
                                                    recpientClinicName: <>{item.clinic}<br /><span className="clinic-subgroup-name">{item.clinicAddress} </span><br />   </>,
                                                    patient: <>{item.patient}<br /><span className="clinic-subgroup-name">{item.patientAddress} </span><br />   </>,
                                                    methodPayment: item.price == 0 && (item.freePayment < 1 || item.freePayment > 6) ?
                                                        'Free of charge'.translate(this.props.lang) : item.paymentMethod == 4 ? 'Registered Manually'.translate(this.props.lang) : item.paymentMethod == 1 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 ? item.online == 'swish' ? 'Online - Swish'.translate(this.props.lang) : item.online == 'payson' ? 'Online - Card'.translate(this.props.lang) : item.online == 'stripe' ? 'Online - Stripe'.translate(this.props.lang) : 'Online'.translate(this.props.lang)
                                                            : item.freePayment == 1 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Free card'.translate(this.props.lang)
                                                                : item.freePayment == 2 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 23'.translate(this.props.lang)
                                                                    : item.freePayment == 3 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 20'.translate(this.props.lang)
                                                                        : item.freePayment == 4 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18'.translate(this.props.lang)
                                                                            : item.freePayment == 5 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Older than 85'.translate(this.props.lang)
                                                                                : item.freePayment == 6 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18 (child)'.translate(this.props.lang)
                                                                                    :
                                                                                    !item.paymentMethod ?
                                                                                        item.online == 'swish' ?
                                                                                            'Online - Swish'.translate(this.props.lang)
                                                                                            :
                                                                                            item.online == 'payson' ?
                                                                                                'Online - Card'.translate(this.props.lang)
                                                                                                :
                                                                                                item.online == 'stripe' ?
                                                                                                    'Online - Stripe'.translate(this.props.lang)
                                                                                                    :
                                                                                                    null
                                                                                        :
                                                                                        null,
                                                    date: item.tsCreated ? this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')*/ : '',
                                                }

                                            }))}
                                            actions={[
                                                {
                                                    component: <div className='list-builder-button-mob'>{'Read more'.translate(this.props.lang)}</div>,
                                                    onClick: item => this.setState({ invoice: item })
                                                }
                                            ]}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        />
                                    </div>

                                        :
                                        <Col lg="12" className='col-no-padding'>
                                            <h5 className="component-header">{'Paid'.translate(this.props.lang)}</h5>
                                            <ListBuilder
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.totalInvoice}
                                                showNumeration={false}
                                                fields={[
                                                    { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'recpientClinicName', label: 'Clinic'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'invoiceNumber', label: 'Reference'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang) },
                                                ]}
                                                rawItems={this.state.itemsInvoice}
                                                items={this.state.itemsInvoice.map((item => {
                                                    return {
                                                        ...item,
                                                        price: item.price + " " + currency,
                                                        version: item.version,
                                                        recpientClinicName: <>{item.clinic}<br /><span className="clinic-subgroup-name">{item.clinicAddress} </span><br />   </>,
                                                        patient: <>{item.patient}<br /><span className="clinic-subgroup-name">{item.patientAddress} </span><br />   </>,
                                                        methodPayment: item.price == 0 && (item.freePayment < 1 || item.freePayment > 6) ?
                                                            'Free of charge'.translate(this.props.lang) : item.paymentMethod == 4 ? 'Registered Manually'.translate(this.props.lang) : item.paymentMethod == 1 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 ? item.online == 'swish' ? 'Online - Swish'.translate(this.props.lang) : item.online == 'payson' ? 'Online - Card'.translate(this.props.lang) : item.online == 'stripe' ? 'Online - Stripe'.translate(this.props.lang) : 'Online'.translate(this.props.lang)
                                                                : item.freePayment == 1 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Free card'.translate(this.props.lang)
                                                                    : item.freePayment == 2 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 23'.translate(this.props.lang)
                                                                        : item.freePayment == 3 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 20'.translate(this.props.lang)
                                                                            : item.freePayment == 4 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18'.translate(this.props.lang)
                                                                                : item.freePayment == 5 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Older than 85'.translate(this.props.lang)
                                                                                    : item.freePayment == 6 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18 (child)'.translate(this.props.lang)
                                                                                        :
                                                                                        !item.paymentMethod ?
                                                                                            item.online == 'swish' ?
                                                                                                'Online - Swish'.translate(this.props.lang)
                                                                                                :
                                                                                                item.online == 'payson' ?
                                                                                                    'Online - Card'.translate(this.props.lang)
                                                                                                    :
                                                                                                    item.online == 'stripe' ?
                                                                                                        'Online - Stripe'.translate(this.props.lang)
                                                                                                        :
                                                                                                        null
                                                                                            :
                                                                                            null,
                                                        // date: item.unpaidTs ? moment.unix(item.unpaidTs).format(this.props.dateFormat) : '',
                                                        date: item.tsCreated ? this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm') : ''/*this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm') : ''*/,
                                                    }

                                                }))}
                                                actions={
                                                    [
                                                        {
                                                            component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => this.setState({ invoice: item })
                                                        },

                                                    ]
                                                }
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            // hideFilters={true}
                                            ></ListBuilder>

                                        </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>




                </Container>

                {
                    this.state.changePaymentModal ?
                        <InfoModal
                            isOpen={this.state.changePaymentModal}
                            onClose={(item) => {
                                this.setState({
                                    changePaymentModal: item
                                })
                            }}
                            close={true}
                            toggle={() => this.setState({ changePaymentModal: !this.state.changePaymentModal })}
                            header={'Change payment method'.translate(this.props.lang)}
                            info={this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 1 && this.state.paymnetOptions && this.state.paymnetOptions.invoice ?
                                <p>{`You selected payment on site.`.translate(this.props.lang) + ' ' + `Do you want to change it to invoice payment?`.translate(this.props.lang)}</p>
                                : this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 2 && this.state.paymnetOptions && this.state.paymnetOptions.paymentOnSite ?
                                    <p>{`You selected invoice payment.`.translate(this.props.lang) + ' ' + `Do you want to change it to payment on site?`.translate(this.props.lang)}</p>
                                    : this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 3 && this.state.paymnetOptions && (this.state.paymnetOptions.paymentOnSite || this.state.paymnetOptions.invoice) ?
                                        <p>{`You selected online payment.`.translate(this.props.lang) + ' ' + `Do you want to change it?`.translate(this.props.lang)}</p>
                                        :
                                        <p>{`There is no other payment options allowed from this clinic.`.translate(this.props.lang) + ' '}<span>{this.state.paymnetOptions && this.state.paymnetOptions.onlinePayment ? 'Other than what you chosen is possible to pay online.'.translate(this.props.lang) : ''}</span></p>

                            }
                            buttons={
                                this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 3 && this.state.paymnetOptions && this.state.paymnetOptions.paymentOnSite && this.state.paymnetOptions.invoice ?
                                    [
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ onlinePaymentMethodChange: 1 }, () => this.updatePaymentMethod(this.state.changePaymentModal, false))}>{'Payment on site'.translate(this.props.lang)}</Button>,
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ onlinePaymentMethodChange: 2 }, () => this.updatePaymentMethod(this.state.changePaymentModal, false))}>{'Invoice'.translate(this.props.lang)}</Button>,
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ changePaymentModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                                    ]
                                    :
                                    this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 3 && this.state.paymnetOptions && this.state.paymnetOptions.paymentOnSite ?
                                        [
                                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ onlinePaymentMethodChange: 1 }, () => this.updatePaymentMethod(this.state.changePaymentModal, false))}>{'Payment on site'.translate(this.props.lang)}</Button>,
                                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ changePaymentModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                                        ]
                                        :
                                        this.state.changePaymentModal && this.state.changePaymentModal.paymentMethod == 3 && this.state.paymnetOptions && this.state.paymnetOptions.invoice ?
                                            [
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ onlinePaymentMethodChange: 2 }, () => this.updatePaymentMethod(this.state.changePaymentModal, false))}>{'Invoice'.translate(this.props.lang)}</Button>,
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ changePaymentModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                                            ]
                                            :
                                            this.state.paymnetOptions && ((this.state.changePaymentModal.paymentMethod == 1 && this.state.paymnetOptions.invoice) || (this.state.changePaymentModal.paymentMethod == 2 && this.state.paymnetOptions.paymentOnSite)) ?
                                                [
                                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.updatePaymentMethod(this.state.changePaymentModal, false)}>{'Ok'.translate(this.props.lang)}</Button>,
                                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ changePaymentModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                                                ]
                                                :
                                                [
                                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ changePaymentModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                                                ]
                            }
                        />
                        : null
                }

                {this.state.freeCardError ?
                    <InfoModal
                        isOpen={this.state.freeCardError}
                        onClose={(item) => {
                            this.setState({
                                freeCardError: item
                            })
                        }}
                        close={true}
                        header={'Free card validation error'.translate(this.props.lang)}
                        info={'Your free card will not be valid on the day of the scheduled visit.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ freeCardError: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                {
                    this.state.paymnetOptionsControl ?
                        <InfoModal
                            isOpen={this.state.paymnetOptionsControl}
                            onClose={(item) => {
                                this.setState({
                                    paymnetOptionsControl: item
                                })
                            }}
                            close={true}
                            toggle={() => this.setState({ paymnetOptionsControl: !this.state.paymnetOptionsControl })}
                            header={'Change payment method'.translate(this.props.lang)}
                            info={this.state.paymnetOptionsControl && (this.state.paymnetOptionsControl.paymentOnSite || this.state.paymnetOptionsControl.invoice || this.state.paymnetOptionsControl.onlinePayment) ?
                                <p>{'Select one payment method'.translate()}</p>
                                :
                                null

                            }
                            buttons={

                                [
                                    this.state.paymnetOptionsControl && this.state.paymnetOptionsControl.paymentOnSite ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let paymnetOptionsControl = this.state.paymnetOptionsControl;
                                            paymnetOptionsControl.paymentMethod = 1;
                                            this.setState({
                                                paymnetOptionsControl
                                            }, () => {
                                                this.updatePaymentControl(this.state.paymnetOptionsControl)
                                            })
                                        }}>{'Payment on site'.translate(this.props.lang)}</Button>
                                        : null,
                                    this.state.paymnetOptionsControl && this.state.paymnetOptionsControl.invoice ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let paymnetOptionsControl = this.state.paymnetOptionsControl;
                                            paymnetOptionsControl.paymentMethod = 2;
                                            this.setState({
                                                paymnetOptionsControl
                                            }, () => {
                                                this.updatePaymentControl(this.state.paymnetOptionsControl)
                                            })
                                        }}>{'Invoice'.translate(this.props.lang)}</Button>
                                        : null,
                                    this.state.paymnetOptionsControl && this.state.paymnetOptionsControl.onlinePayment ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let paymnetOptionsControl = this.state.paymnetOptionsControl;
                                            paymnetOptionsControl.paymentMethod = 3;
                                            this.setState({
                                                paymnetOptionsControl
                                            }, () => {
                                                this.updatePaymentControl(this.state.paymnetOptionsControl)
                                            })
                                        }}>{'Online'.translate(this.props.lang)}</Button>
                                        : null,
                                ]


                            }
                        />
                        : null
                }
                {this.state.successModalError ?
                    <Modal isOpen={this.state.successModalError} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {this.state.successModalError}
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter className='button-right-blue'>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModalError: null })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.successModal ?
                        <InfoModal
                            isOpen={this.state.successModal}

                            toggle={() => {

                                this.setState({ successModal: null })
                            }}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.successModal}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        successModal: null
                                    })

                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        : null


                }

                {
                    this.state.invoice ?
                        <Modal isOpen={this.state.invoice} centered size='lg' className="modal-footer-modifed">
                            <ModalHeader toggle={() => this.setState({ invoice: !this.state.invoice })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ invoice: !this.state.invoice })}><Isvg src={xIcon} /></button>}>{'InvoiceKey'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent }} onChange={() => { }}></div>

                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                <PdfSave lang={this.props.lang} html={(this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent).replace(/č/g, 'c').replace(/Č/g, 'C').replace(/ć/g, 'c').replace(/Ć/g, 'C')} />
                                <Button color="primary" onClick={() => this.setState({ invoice: null })}> {'Close'.translate(this.props.lang)} </Button>


                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.referral ?
                        <Modal isOpen={this.state.referral} centered>
                            {(this.state.group && !this.state.group.agentId && !this.state.group.apiKey && !this.state.group.bankAccount) || !this.state.onlinePayment || this.state.referral.dropIn ?
                                <ModalHeader style={{ margin: 'auto' }} toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}><Isvg src={xIcon} /></button>}>{'Online payment'.translate(this.props.lang)}</ModalHeader>

                                : this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor ?
                                    <ModalHeader style={{ margin: 'auto' }} toggle={() => this.setState({ referral: !this.state.referral }, () => {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral }, () => {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    })}><Isvg src={xIcon} /></button>}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>
                                    :
                                    <ModalHeader style={{ margin: 'auto' }} toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}><Isvg src={xIcon} /></button>}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>

                            }

                            <ModalBody className="delete-modal">
                                {(this.state.group && !this.state.group.agentId && !this.state.group.apiKey && !this.state.group.bankAccount) || !this.state.onlinePayment ?
                                    <div>
                                        <Isvg src={info} />
                                        <div className='message'>{"This clinic doesn't have online payment".translate(this.props.lang)}</div>
                                    </div>
                                    :
                                    <Isvg src={Card} />
                                }

                            </ModalBody>
                            <ModalFooter className="buttons-right-blue" >
                                <div className="payment-container">
                                    {this.state.referral.type != 'revisit' && this.state.group && this.state.group.agentId && this.state.group.apiKey && (this.state.onlinePayment || this.state.referral.dropIn) ?
                                        <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: this.state.referral, referral: !this.state.referral }, () => {
                                            // console.log(this.state.paysonModal)
                                        })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                        : null}
                                    {this.state.referral.type != 'revisit' && this.state.group && this.state.group.bankAccount && (this.state.onlinePayment || this.state.referral.dropIn) ?
                                        <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: this.state.referral, referral: !this.state.referral })}><Isvg src={SwishSvg} /></Button>
                                        : null}
                                    {this.state.referral.type == 'revisit' && this.state.group && this.state.group.agentId && this.state.group.apiKey && (this.state.onlinePayment || this.state.referral.dropIn) ?
                                        <Button className="payment-button-payson" onClick={() => this.setState({ paysonModalControl: this.state.referral, referral: !this.state.referral }, () => {
                                            // console.log(this.state.paysonModalControl)
                                        })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                        : null}
                                    {this.state.referral.type == 'revisit' && this.state.group && this.state.group.bankAccount && (this.state.onlinePayment || this.state.referral.dropIn) ?
                                        <Button className="payment-button-swish" onClick={() => this.setState({ swishModalControl: this.state.referral, referral: !this.state.referral })}><Isvg src={SwishSvg} /></Button>
                                        : null}
                                </div>
                                {(this.state.group && !this.state.group.agentId && !this.state.group.apiKey && !this.state.group.bankAccount) || (!this.state.onlinePayment && !this.state.referral.dropIn) ?
                                    <Button color="primary" onClick={() => this.setState({ referral: !this.state.referral })}> {'Close'.translate(this.props.lang)} </Button>

                                    : null}
                            </ModalFooter>


                        </Modal>

                        : null
                }

                {
                    this.state.failureModal ?
                        <Modal isOpen={this.state.failureModal} centered>
                            {/* <ModalHeader >{'Failed payment!'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="c-modal-body">
                                {/* <img src={warrning} /> */}
                                <p style={{ paddingTop: 30, paddingRight: 20, paddingLeft: 20 }}>{'Please try again or choose another payment method. Unpaid visits are deleted within 48 hours.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">

                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ paysonModal: this.props.referralStatusBefore, failureModal: !this.state.failureModal }, () => this.props.clearStatusBefore())}>{'Retry'.translate(this.props.lang)}</Button>
                                {/* <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ referral: this.props.referralStatusBefore, failureModal: !this.state.failureModal })}>{'Change payment method'.translate(this.props.lang)}</Button> */}

                                {this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor ?
                                    <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ failureModal: !this.state.failureModal }, () => {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    })}>{'Cancel'.translate(this.props.lang)}</Button>
                                    :
                                    <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ failureModal: !this.state.failureModal }, () => {
                                        // this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    })}>{'Cancel'.translate(this.props.lang)}</Button>}
                            </ModalFooter>


                        </Modal>

                        : null
                }
                {this.state.failureModal2 ?
                    <Modal isOpen={this.state.failureModal2} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <Isvg src={info} /> */}
                            {<p className='message'>{"Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang)}</p>}

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ failureModal2: null })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.dropInErrorModal ?
                        <Modal isOpen={this.state.dropInErrorModal} centered>
                            {/* <ModalHeader >{'Failed payment!'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="c-modal-body">
                                {/* <img src={warrning} /> */}
                                <p style={{ paddingTop: 30, paddingRight: 20, paddingLeft: 20 }}>{this.state.dropInErrorModal.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">

                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                    if (this.state.dropInErrorModalData && this.state.dropInErrorModalReferralForDelete && this.state.dropInErrorModalReferral)
                                        fetch(API_ENDPOINT + '/data/clinic-groups/' + this.state.dropInErrorModalData.group, {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                'content-type': 'application/json'
                                            },
                                        }).then(res => res.json()).then((result) => {
                                            this.setState({
                                                newReferralId: this.state.dropInErrorModalReferralForDelete,
                                                payNowModalReferral: this.state.dropInErrorModalData,
                                                onlineDataPayment: this.state.dropInErrorModalReferral,
                                                group: result,
                                                dropInErrorModal: null
                                            })
                                        })
                                }}>{'Retry'.translate(this.props.lang)}</Button>


                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ dropInErrorModal: !this.state.dropInErrorModal }, () => {
                                    this.deleteReferral(this.state.dropInErrorModalReferralForDelete);
                                })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>


                        </Modal>

                        : null
                }

                {
                    this.state.paysonModalControl ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({ failureModal2: true, payNowModal: null })
                                this.setState({
                                    paysonModalControl: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.paysonModalControl.refData && this.state.paysonModalControl.price ? this.state.paysonModalControl.price : this.state.paysonModalControl.refData && this.state.paysonModalControl.refData.questionaryPrice ? this.state.paysonModalControl.refData.questionaryPrice : this.state.paysonModalControl.questionaryPrice}

                            referral={this.state.paysonModalControl._id}
                            doctor={this.state.paysonModalControl.doctor ? this.state.paysonModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyPayments={true}
                        />


                        : null
                }
                {
                    this.state.stripeModalControl ?

                        <Stripe
                            {...this.props}
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({ failureModal2: true, payNowModal: null })
                                this.setState({
                                    stripeModalControl: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.stripeModalControl.refData && this.state.stripeModalControl.price ? this.state.stripeModalControl.price : this.state.stripeModalControl.refData && this.state.stripeModalControl.refData.questionaryPrice ? this.state.stripeModalControl.refData.questionaryPrice : this.state.stripeModalControl.questionaryPrice}

                            referral={this.state.stripeModalControl._id}
                            doctor={this.state.stripeModalControl.doctor ? this.state.stripeModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyPayments={true}
                        />


                        : null
                }
                {
                    this.state.paysonModal ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({ failureModal2: true })
                                this.setState({
                                    paysonModal: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            referral={this.state.paysonModal.refData && this.state.paysonModal.referral ? this.state.paysonModal.referral : this.state.paysonModal.refData && this.state.paysonModal.refData._id ? this.state.paysonModal.refData._id : this.state.paysonModal._id}
                            data={this.state.paysonModal.refData && this.state.paysonModal.refData.data ? this.state.paysonModal.refData.data : null}
                            price={this.state.paysonModal.refData && this.state.paysonModal.price ? this.state.paysonModal.price : this.state.paysonModal.refData && this.state.paysonModal.refData.questionaryPrice ? this.state.paysonModal.refData.questionaryPrice : this.state.paysonModal.questionaryPrice}
                            doctor={this.state.paysonModal.refData && this.state.paysonModal.refData.doctor ? this.state.paysonModal.refData.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyPayments={true}
                        />


                        : null
                }
                {
                    this.state.stripeModal ?

                        <Stripe
                            {...this.props}
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({ failureModal2: true })
                                this.setState({
                                    stripeModal: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            referral={this.state.stripeModal.refData && this.state.stripeModal.referral ? this.state.stripeModal.referral : this.state.stripeModal.refData && this.state.stripeModal.refData._id ? this.state.stripeModal.refData._id : this.state.stripeModal._id}
                            data={this.state.stripeModal.refData && this.state.stripeModal.refData.data ? this.state.stripeModal.refData.data : null}
                            price={this.state.stripeModal.refData && this.state.stripeModal.price ? this.state.stripeModal.price : this.state.stripeModal.refData && this.state.stripeModal.refData.questionaryPrice ? this.state.stripeModal.refData.questionaryPrice : this.state.stripeModal.questionaryPrice}
                            doctor={this.state.stripeModal.refData && this.state.stripeModal.refData.doctor ? this.state.stripeModal.refData.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyPayments={true}
                        />


                        : null
                }
                {
                    this.state.swishModalControl ?
                        <Swish
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            open={this.state.swishModalControl}
                            openModal={(item) => {
                                this.setState({ failureModal2: true, payNowModal: null })
                                // console.log(this.props.referralStatusBefore)
                                this.setState({
                                    swishModalControl: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            getStringDateInverseTs={data => this.props.getStringDateInverseTs(data)}
                            price={this.state.swishModalControl.refData && this.state.swishModalControl.price ? this.state.swishModalControl.price : this.state.swishModalControl.refData && this.state.swishModalControl.refData.questionaryPrice ? this.state.swishModalControl.refData.questionaryPrice : this.state.swishModalControl.questionaryPrice}
                            phone={this.props.uData.phone}
                            referralNumber={this.state.swishModalControl.controlNumber ? this.state.swishModalControl.controlNumber : null}
                            referral={this.state.swishModalControl._id}
                            doctor={this.state.swishModalControl.doctor ? this.state.swishModalControl.doctor : null}
                            patient={this.props.uData._id}
                            callbackFunction={() => this.callbackAfterPaying()}
                        // data={this.state.swishModalControl.refData && this.state.swishModalControl.refData.data ? this.state.swishModalControl.refData.data : null}

                        />


                        : null
                }
                {
                    this.state.swishModal ?

                        <Swish
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            open={this.state.swishModal}
                            openModal={(item) => {
                                this.setState({ failureModal2: true })
                                this.setState({
                                    swishModal: item
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            getStringDateInverseTs={data => this.props.getStringDateInverseTs(data)}
                            price={this.state.swishModal.refData && this.state.swishModal.price ? this.state.swishModal.price : this.state.swishModal.refData && this.state.swishModal.refData.questionaryPrice ? this.state.swishModal.refData.questionaryPrice : this.state.swishModal.questionaryPrice}
                            phone={this.props.uData.phone}
                            referralNumber={this.state.swishModal.refData && this.state.swishModal.referralNumber ? this.state.swishModal.referralNumber : this.state.swishModal.refData && this.state.swishModal.refData.patient && this.state.swishModal.refData.patient.referralNumber ? this.state.swishModal.refData.patient.referralNumber : this.state.swishModal.patient.referralNumber}
                            referral={this.state.swishModal.refData && this.state.swishModal.referral ? this.state.swishModal.referral : this.state.swishModal.refData && this.state.swishModal.refData._id ? this.state.swishModal.refData._id : this.state.swishModal._id}
                            doctor={this.state.swishModal.refData && this.state.swishModal.refData.doctor ? this.state.swishModal.refData.doctor : null}
                            patient={this.props.uData._id}
                            data={this.state.swishModal.refData && this.state.swishModal.refData.data ? this.state.swishModal.refData.data : null}
                            callbackFunction={() => this.callbackAfterPaying()}
                        />


                        : null
                }

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        : null
                }
                {
                    this.state.payNowModal ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModal}
                            toggle={() => this.setState({ payNowModal: null, failureModal2: true })}
                            regions={this.state.regions}
                            updatePaymentMethod={(method) => this.updatePaymentControl(method)}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodFreeCard(data)}
                            openPayson={(data) => this.openPayson(data)}
                            openSwish={(data) => this.openSwish(data)}
                            openStripe={(data) => this.openStripe(data)}
                        >
                        </PayNowModal>

                        : null
                }
                {
                    this.state.payNowModalReferral ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModalReferral}
                            toggle={() => {
                                this.setState({ failureModal2: true })
                                this.setState({ payNowModalReferral: null }, () => {
                                    if (this.state.dropInErrorModalReferralForDelete) {
                                        this.deleteReferral(this.state.dropInErrorModalReferralForDelete)
                                    }
                                })
                            }}
                            regions={this.state.regions}
                            dropIn={this.state.isDropIn ? true : false}
                            groupPaymentData={{
                                agentId: this.state.group?.agentId,
                                apiKey: this.state.group?.apiKey,
                                bankAccount: this.state.group?.bankAccount,

                                stripeAccountID: this.state.group?.stripeAccountID,

                                canUsePaysonForPayment: this.state.group?.canUsePaysonForPayment,
                                canUseSwishForPayment: this.state.group?.canUseSwishForPayment,
                                canUseStripeForPayment: this.state.group?.canUseStripeForPayment
                            }}
                            updatePaymentMethod={(method) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = method.paymentMethod;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,

                                }, () => {
                                    this.updateReferral()
                                })
                            }}
                            updatePaymentMethodFreeCard={(data) => {
                                // alert(data)
                                this.updatePaymentMethodFreeCardReferral(data)
                            }}
                            openPayson={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    payson: data,
                                    swish: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openSwish={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    swish: data,
                                    payson: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openStripe={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    stripe: data,
                                    payson: null,
                                    swish: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                        >
                        </PayNowModal>

                        : null
                }

                {/* {
                    this.state.deleteBill ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteBill}
                            toggle={() => this.setState({ deleteBill: null })}
                            handler={() => { this.delete(this.state.deleteBill._id); this.setState({ deleteBill: null }) }}
                        >
                            {'Are you sure that you want to cancel the payment for case '.translate(this.props.lang)} <strong>{this.state.deleteBill.patient ? this.state.deleteBill.patient.referralNumber + (this.state.deleteBill.version != 0 ? '.' + this.state.deleteBill.version : '') : this.state.deleteBill.controlNumber ? `${this.state.deleteBill.controlNumber}` : ''}</strong>?
                        </DeleteModal>
                        :
                        null
                } */}


            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(MyPayments));