import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';


import Grid from './components/grid';
import Toolbox from './components/toolbox';
import gridIcon from './assets/grid.svg';
import buttonIcon from './assets/button.svg';
import textIcon from './assets/txt.svg';
import imageIcon from './assets/image.svg';
import loginIcon from './assets/bankid.svg';
import accountIcon from './assets/login.svg';
import mapIcon from './assets/landing-map.svg';

import Toggle from '../components/forms/fields/toggle';

import Switch from '../components/forms/fields/switch';



import { v4 as uuidv4 } from 'uuid';
import ToReact from './toReact';

function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}


function getPosition(e) {
    var posx = 0;
    var posy = 0;

    if (!e) var e = window.event;

    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft +
            document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop +
            document.documentElement.scrollTop;
    }

    return {
        x: posx,
        y: posy
    }
}


/**
* AppBuilder
* @author   Milan Stanojevic
*/
class AppBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            layout: 'lg',
            activeElement: 'root',
            nodes: {},
            nodesHistory: [],
            inverseNodesHistory: [],
            loggedIn: false,
            isActive: false
        }


    }


    saveChange = (disableCheck = false) => {
        let timestamp = new Date().getTime() / 1000;
        if (disableCheck || (!this.lastSave || (timestamp - this.lastSave >= 2))) {
            let nodes = JSON.parse(JSON.stringify(this.state.nodes));
            let nodesHistory = this.state.nodesHistory;
            nodesHistory.push(nodes);
            this.setState({ nodesHistory });
            this.lastSave = timestamp;
        }
    }

    undo = () => {
        let nodesHistory = this.state.nodesHistory;
        if (!nodesHistory.length)
            return;


        let nodes = JSON.parse(JSON.stringify(this.state.nodes));
        let inverseNodesHistory = this.state.inverseNodesHistory;
        inverseNodesHistory.push(nodes);



        let prevNodes = nodesHistory.pop();
        // console.log('------------- undo')
        this.setState({
            nodes: prevNodes,
            nodesHistory,
            inverseNodesHistory
        }, () => this.props.handleChange(this.state.nodes));

    }

    redo = () => {
        let nodesHistory = this.state.inverseNodesHistory;
        if (!nodesHistory.length)
            return;

        this.saveChange(true);

        let prevNodes = nodesHistory.pop();
        // console.log('------------- redo')
        this.setState({
            nodes: prevNodes,
            inverseNodesHistory: nodesHistory
        }, () => this.props.handleChange(this.state.nodes));

    }



    componentDidMount() {


        this.setState({
            nodes: this.props.json ? this.props.json : {},
            layout: this.props.layout ? this.props.layout : 'lg'
        }, this.forceUpdate)
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateNeeded != this.props.updateNeeded) {
            this.setState({
                nodes: this.props.json ? this.props.json : {}
            }, this.forceUpdate)
        }
    }

    handleElementHover = (event, id, value) => {
        event.stopPropagation();
        if (!value) {
            this.setState({ activeElement: null });
        } else {
            this.setState({ activeElement: id });
        }
    }


    changeElement = (node, name, value) => {
        this.saveChange();

        let nodes = this.state.nodes;
        if (!value)
            delete nodes[node][name]
        else
            nodes[node][name] = value;

        // console.log('------------- changeElement')

        this.setState({ nodes }, () => this.props.handleChange(this.state.nodes))
    }

    changeVisibility = (node, layout, value) => {
        this.saveChange();

        let nodes = this.state.nodes;
        nodes[node].visibility[layout] = value;
        if (!nodes[node].visibility.lg && !nodes[node].visibility.sm && !nodes[node].visibility.xs && !nodes[node].visibility.app) {
            nodes[node].visibility.lg = true;
        }

        // console.log('------------- changeVisibility')
        this.setState({ nodes }, () => this.props.handleChange(this.state.nodes))

    }
    changeHideElement = (node, name, value) => {
        this.saveChange();

        let nodes = this.state.nodes;
        nodes[node][name] = value;


        // console.log('------------- changeHideElement')
        this.setState({ nodes }, () => this.props.handleChange(this.state.nodes))

    }
    changeMultipleStyle = (node, obj) => {
        this.saveChange();

        let nodes = JSON.parse(JSON.stringify(this.state.nodes));
        if (!nodes[node]['style']) {
            nodes[node]['style'] = {};
        }
        if (!nodes[node]['activeStyle']) {
            nodes[node]['activeStyle'] = {};
        }
        if (!nodes[node]['responsiveStyle']) {
            nodes[node]['responsiveStyle'] = {
                sm: {},
                xs: {},
                app: {}
            };
        }



        if (this.state.isActive) {
            nodes[node]['activeStyle'] = { ...nodes[node]['activeStyle'], ...obj };
        } else if (this.state.layout === 'lg') {
            nodes[node]['style'] = { ...nodes[node]['style'], ...obj };
        } else if (this.state.layout === 'sm') {
            nodes[node]['responsiveStyle']['sm'] = { ...nodes[node]['responsiveStyle']['sm'], ...obj };
        } else if (this.state.layout === 'xs') {
            nodes[node]['responsiveStyle']['xs'] = { ...nodes[node]['responsiveStyle']['xs'], ...obj };
        } else if (this.state.layout === 'app') {
            nodes[node]['responsiveStyle']['app'] = { ...nodes[node]['responsiveStyle']['app'], ...obj };
        }


        // console.log('------------- changeMultipleStyle')
        this.setState({ nodes: nodes }, () => {
            this.forceUpdate()
            this.props.handleChange(this.state.nodes)
        })

    }

    changeStyle = (node, name, value) => {
        this.saveChange();

        let nodes = JSON.parse(JSON.stringify(this.state.nodes));
        // console.log(node, name, value)
        if (!nodes[node]['style']) {
            nodes[node]['style'] = {};
        }
        if (!nodes[node]['activeStyle']) {
            nodes[node]['activeStyle'] = {};
        }
        if (!nodes[node]['responsiveStyle']) {
            nodes[node]['responsiveStyle'] = {
                sm: {},
                xs: {},
                app: {}
            };
        }
        if (this.state.isActive) {
            nodes[node]['activeStyle'][name] = value;
        } else if (this.state.layout === 'lg') {
            nodes[node]['style'][name] = value;
        } else if (this.state.layout === 'sm') {
            nodes[node]['responsiveStyle']['sm'][name] = value;
        } else if (this.state.layout === 'xs') {
            nodes[node]['responsiveStyle']['xs'][name] = value;
        } else if (this.state.layout === 'app') {
            nodes[node]['responsiveStyle']['app'][name] = value;
        }



        // console.log('------------- changeStyle')
        this.setState({ nodes: nodes }, () => {
            this.forceUpdate()
            this.props.handleChange(this.state.nodes)
        })
    }
    changeProps = (node, name, value, protocol = null) => {
        this.saveChange();

        let nodes = JSON.parse(JSON.stringify(this.state.nodes));

        if (!nodes[node]['props']) {
            nodes[node]['props'] = {};
        }
        if (!nodes[node]['activeProps']) {
            nodes[node]['activeProps'] = {};
        }
        if (!nodes[node]['responsiveProps']) {
            nodes[node]['responsiveProps'] = {
                sm: {},
                xs: {},
                app: {}
            };
        }

        if (this.state.isActive) {
            nodes[node]['activeProps'][name] = value;
        } else if (this.state.layout === 'lg') {
            nodes[node]['props'][name] = value;
            if (protocol) { nodes[node]['props']['protocol'] = protocol; }
        } else if (this.state.layout === 'sm') {
            nodes[node]['responsiveProps']['sm'][name] = value;
            if (protocol) { nodes[node]['responsiveProps']['sm']['protocol'] = protocol; }
        } else if (this.state.layout === 'xs') {
            nodes[node]['responsiveProps']['xs'][name] = value;
            if (protocol) { nodes[node]['responsiveProps']['xs']['protocol'] = protocol; }
        } else if (this.state.layout === 'app') {
            nodes[node]['responsiveProps']['app'][name] = value;
            if (protocol) { nodes[node]['responsiveProps']['app']['protocol'] = protocol; }
        }



        // console.log('------------- changeProps')
        this.setState({ nodes: nodes }, () => {
            this.forceUpdate()
            this.props.handleChange(this.state.nodes)
        })
    }


    changeLayout = (node, layout, width) => {
        this.saveChange();

        let detectedLayout;

        if (width < 480 && width != 355) {
            detectedLayout = 'xs';
        }
        if (width == 355) {
            detectedLayout = 'app';
        }
        if (width < 768 && width >= 480) {
            detectedLayout = 'sm';
        }
        if (width >= 768) {
            detectedLayout = 'lg';
        }
        let nodes = this.state.nodes;

        if (detectedLayout == this.state.layout) {

            if (layout.lg && (false || detectedLayout === 'lg')) {
                for (let i = 0; i < layout.lg.length; i++) {

                    if (!nodes[layout.lg[i].i]) {
                        continue;
                    }

                    nodes[layout.lg[i].i].lg = layout.lg[i];
                }
            }
            if (layout.sm && (false || detectedLayout === 'sm')) {
                for (let i = 0; i < layout.sm.length; i++) {
                    if (!nodes[layout.sm[i].i]) {
                        continue;
                    }

                    nodes[layout.sm[i].i].sm = layout.sm[i];

                }
            }
            if (layout.xs && (false || detectedLayout === 'xs')) {

                for (let i = 0; i < layout.xs.length; i++) {
                    if (!nodes[layout.xs[i].i]) {
                        continue;
                    }

                    nodes[layout.xs[i].i].xs = layout.xs[i];

                }
            }

            if (layout.app && (false || detectedLayout === 'app')) {

                for (let i = 0; i < layout.app.length; i++) {
                    if (!nodes[layout.app[i].i]) {
                        continue;
                    }

                    nodes[layout.app[i].i].app = layout.app[i];

                }
            }
        } else {
            for (let i = 0; i < layout[detectedLayout].length; i++) {
                if (!nodes[layout[detectedLayout][i].i]) {
                    continue;
                }

                nodes[layout[detectedLayout][i].i][this.state.layout] = layout[detectedLayout][i];

            }

        }

        let originalNodes = JSON.parse(JSON.stringify(nodes));
        try {
            for (var node in nodes) {
                if (nodes[node].parent == 'root') {
                    if (this.state.layout == 'lg' && nodes[node]?.needsUpdateOnDimensionChangeLg) {
                        nodes[node].lg = { ...nodes[node].lg, ...nodes[node].needsUpdateOnDimensionChangeLg };
                        delete nodes[node].needsUpdateOnDimensionChangeLg;
                    }
                    if (this.state.layout == 'sm' && nodes[node]?.needsUpdateOnDimensionChangeSm) {
                        nodes[node].sm = { ...nodes[node].sm, ...nodes[node].needsUpdateOnDimensionChangeSm };
                        delete nodes[node].needsUpdateOnDimensionChangeSm;
                    }
                    if (this.state.layout == 'xs' && nodes[node]?.needsUpdateOnDimensionChangeXs) {
                        nodes[node].xs = { ...nodes[node].xs, ...nodes[node].needsUpdateOnDimensionChangeXs };
                        delete nodes[node].needsUpdateOnDimensionChangeXs;
                    }
                    if (this.state.layout == 'app' && nodes[node]?.needsUpdateOnDimensionChangeApp) {
                        nodes[node].app = { ...nodes[node].app, ...nodes[node].needsUpdateOnDimensionChangeApp };
                        delete nodes[node].needsUpdateOnDimensionChangeApp;
                    }
                } else {
                    if (!originalNodes[originalNodes[node].parent]?.needsUpdateOnDimensionChangeLg) {
                        if (this.state.layout == 'lg' && nodes[node]?.needsUpdateOnDimensionChangeLg) {
                            nodes[node].lg = { ...nodes[node].lg, ...nodes[node].needsUpdateOnDimensionChangeLg };
                            delete nodes[node].needsUpdateOnDimensionChangeLg;
                        }
                    }
                    if (!originalNodes[originalNodes[node].parent]?.needsUpdateOnDimensionChangeSm) {

                        if (this.state.layout == 'sm' && nodes[node]?.needsUpdateOnDimensionChangeSm) {
                            nodes[node].sm = { ...nodes[node].sm, ...nodes[node].needsUpdateOnDimensionChangeSm };
                            delete nodes[node].needsUpdateOnDimensionChangeSm;
                        }
                    }
                    if (!originalNodes[originalNodes[node].parent]?.needsUpdateOnDimensionChangeXs) {

                        if (this.state.layout == 'xs' && nodes[node].needsUpdateOnDimensionChangeXs) {
                            nodes[node].xs = { ...nodes[node].xs, ...nodes[node].needsUpdateOnDimensionChangeXs };
                            delete nodes[node].needsUpdateOnDimensionChangeXs;
                        }
                    }
                    if (!originalNodes[originalNodes[node].parent]?.needsUpdateOnDimensionChangeApp) {

                        if (this.state.layout == 'app' && nodes[node]?.needsUpdateOnDimensionChangeApp) {
                            nodes[node].app = { ...nodes[node].app, ...nodes[node].needsUpdateOnDimensionChangeApp };
                            delete nodes[node].needsUpdateOnDimensionChangeApp;
                        }
                    }
                }

            }
        } catch (e) {
            console.log(e)
        }
        this.setState({ nodes }, () => this.props.handleChange(this.state.nodes))

    }

    addElement = (layout) => {
        this.saveChange();

        // console.log(layout)
        let nodes = this.state.nodes;
        let id = uuidv4();
        //nodes[id] = layout;
        nodes[id] = {
            lg: { w: 20, h: 10, x: layout.x, y: layout.y },
            sm: { w: 100, h: 10, x: layout.x, y: layout.y },
            xs: { w: 100, h: 10, x: layout.x, y: layout.y },
            app: { w: 100, h: 10, x: layout.x, y: layout.y },

        };
        if (this.state.layout == 'app') {
            nodes[id].visibility = { lg: false, sm: false, xs: false, app: true };
        } else {
            nodes[id].visibility = { lg: true, sm: true, xs: true, app: true };
        }

        nodes[id].style = {};
        nodes[id].activeStyle = {};
        nodes[id].responsiveStyle = { sm: {}, xs: {}, app: {} };
        nodes[id].props = {};
        nodes[id].activeProps = {};
        nodes[id].responsiveProps = { sm: {}, xs: {}, app: {} };


        if (this.state.activeElement == 'root' || this.state.nodes[this.state.activeElement].type == 'grid') {
            nodes[id].parent = this.state.activeElement;
        } else {
            nodes[id].parent = this.state.nodes[this.state.activeElement].parent;
        }
        nodes[id].type = this.state.dragElementType;

        delete nodes[id].i;

        if (this.state.dragElementType == 'text') {
            nodes[id].value = 'Text element';
        }
        if (this.state.dragElementType == 'button') {
            nodes[id].value = 'Button';
            nodes[id].style = {
                backgroundColor: '#568AC8',
                borderTopLeftRadius: '10',
                borderTopRightRadius: '10',
                borderBottomLeftRadius: '10',
                borderBottomRightRadius: '10',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ffffff'

            }
        }
        if (this.state.dragElementType == 'button2') {
            nodes[id].value = 'Button';
            nodes[id].style = {
                backgroundColor: '#568AC8',
                borderTopLeftRadius: '10',
                borderTopRightRadius: '10',
                borderBottomLeftRadius: '10',
                borderBottomRightRadius: '10',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ffffff'

            }
        }
        if (this.state.dragElementType == 'account') {
            nodes[id].style = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }
            nodes[id].lg = { w: 10, h: 5, x: layout.x, y: layout.y };
            nodes[id].sm = { w: 30, h: 5, x: layout.x, y: layout.y };
            nodes[id].xs = { w: 30, h: 5, x: layout.x, y: layout.y };
            nodes[id].app = { w: 30, h: 5, x: layout.x, y: layout.y };

        }
        if (this.state.dragElementType == 'login') {
            nodes[id].style = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }

            nodes[id].lg = { w: 45, h: 80, x: layout.x, y: layout.y, minW: 45, maxW: 45, minH: 80, maxH: 80 };
            nodes[id].sm = { w: 55, h: 80, x: layout.x, y: layout.y, minW: 55, maxW: 55, minH: 80, maxH: 80 };
            nodes[id].xs = { w: 105, h: 80, x: layout.x, y: layout.y, minW: 105, maxW: 105, minH: 80, maxH: 80 };
            nodes[id].app = { w: 105, h: 80, x: layout.x, y: layout.y, minW: 105, maxW: 105, minH: 80, maxH: 80 };

        }
        if (this.state.dragElementType == 'map') {
            nodes[id].style = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }

            nodes[id].lg = { w: 100, h: 30, x: layout.x, y: layout.y };
            nodes[id].sm = { w: 100, h: 30, x: layout.x, y: layout.y };
            nodes[id].xs = { w: 100, h: 30, x: layout.x, y: layout.y };
            nodes[id].app = { w: 100, h: 30, x: layout.x, y: layout.y };

        }
        if (this.state.dragElementType == 'mainInfo') {
            nodes[id].style = {
                display: 'flex',
                // alignItems: 'center',
                justifyContent: 'flex-end',
                paddingTop: 100
            }
            nodes[id].lg = { w: 100, h: 80, x: layout.x, y: layout.y, minW: 100, maxW: 100, minH: 80, maxH: 80 };
            nodes[id].sm = { w: 100, h: 80, x: layout.x, y: layout.y, minW: 100, maxW: 100, minH: 80, maxH: 80 };
            nodes[id].xs = { w: 100, h: 80, x: layout.x, y: layout.y, minW: 100, maxW: 100, minH: 80, maxH: 80 };
            nodes[id].app = { w: 100, h: 80, x: layout.x, y: layout.y, minW: 100, maxW: 100, minH: 80, maxH: 80 };

        }
        // console.log('------------- addElement')
        this.setState({ nodes }, () => this.props.handleChange(this.state.nodes))

    }

    removeElement = (node) => {
        this.saveChange();

        let nodes = this.state.nodes;
        delete nodes[node];
        // console.log('------------- removeElement')
        this.setState({ nodes: nodes, activeElement: 'root' }, () => this.props.handleChange(this.state.nodes));

    }

    handleRightClick = (node, event) => {
        // console.log(node, event);
        event.preventDefault();
        event.stopPropagation();
        console.log(getPosition(event));

        let offset = getOffset(this.appBuilderRef);
        let pos = getPosition(event);
        pos.y = pos.y - offset.top;
        pos.x = pos.x - offset.left;

        this.setState({
            contextMenu: {
                node: node,
                position: pos
            }
        })
    }

    cutElement = () => {
        this.saveChange();
        let nodes = this.state.nodes;
        let element = JSON.parse(JSON.stringify(nodes[this.state.contextMenu.node]));
        delete nodes[this.state.contextMenu.node];
        this.setState({
            activeElement: 'root',
            nodes,
            clipboard: element,
            clipboardNode: this.state.contextMenu.node,
            contextMenu: null
        })
    }
    copyElement = () => {
        let nodes = this.state.nodes;
        let element = JSON.parse(JSON.stringify(nodes[this.state.contextMenu.node]));
        // console.log(element);
        this.setState({
            clipboard: element,
            clipboardNode: this.state.contextMenu.node,
            contextMenu: null

        })
    }
    pasteElement = () => {
        this.saveChange();
        let nodes = this.state.nodes;
        let element = JSON.parse(JSON.stringify(this.state.clipboard));

        let id = uuidv4();
        element.needsUpdateOnDimensionChangeLg = {
            w: element.lg.w,
            h: element.lg.h
        };
        element.needsUpdateOnDimensionChangeSm = {
            w: element.sm.w,
            h: element.sm.h
        };
        element.needsUpdateOnDimensionChangeXs = {
            w: element.xs.w,
            h: element.xs.h
        };
        element.needsUpdateOnDimensionChangeApp = {
            w: element.app.w,
            h: element.app.h
        };

        // console.log(element);

        if (this.state.contextMenu.node == 'root' || (nodes[this.state.contextMenu.node].parent == 'root' && nodes[this.state.contextMenu.node].type != 'grid')) {
            element.parent = 'root';
            nodes[id] = JSON.parse(JSON.stringify(element));
        } else if (nodes[this.state.contextMenu.node].type == 'grid') {
            element.parent = this.state.contextMenu.node;
            nodes[id] = JSON.parse(JSON.stringify(element));
        }

        if (nodes[id].type == 'grid') {
            for (var node in nodes) {
                if (nodes[node].parent == this.state.clipboardNode) {
                    let newElement = JSON.parse(JSON.stringify(nodes[node]));
                    /*newElement.needsUpdateOnDimensionChangeLg = {
                        w: newElement.lg.w,
                        h: newElement.lg.h
                    };
                    newElement.needsUpdateOnDimensionChangeSm = {
                        w: newElement.sm.w,
                        h: newElement.sm.h
                    };
                    newElement.needsUpdateOnDimensionChangeXs = {
                        w: newElement.xs.w,
                        h: newElement.xs.h
                    };
*/
                    newElement.parent = id;
                    nodes[uuidv4()] = newElement;
                }
            }

        }


        this.setState({
            nodes,
            contextMenu: null
        })
    }

    render() {
        return (
            <div className="app-builder" style={{ position: 'relative' }} ref={(node) => this.appBuilderRef = node}>

                <div className='import-button-tooltip-wrap'>
                    <div className="app-builder-components" style={{ display: this.props.preview ? 'none' : 'flex' }}>

                        <div
                            className="droppable-element"
                            draggable={true}
                            unselectable="on"
                            onDragStart={e => {
                                this.setState({
                                    dragElementType: 'grid'
                                })
                                e.dataTransfer.setData("text/plain", "")
                            }}
                        >
                            <Isvg src={gridIcon} />
                            <div className='tooltip'>
                                <p>{'Grid'.translate(this.props.lang)}</p>
                            </div>
                        </div>
                        {
                            this.props.location != '/my-data/main-clinic-data' ?
                                <div
                                    className="droppable-element"
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => {
                                        this.setState({
                                            dragElementType: 'text'
                                        })
                                        e.dataTransfer.setData("text/plain", "")
                                    }}
                                >
                                    <Isvg src={textIcon} />
                                    <div className='tooltip'>
                                        <p>{'Text'.translate(this.props.lang)}</p>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            this.props.location != '/my-data/main-clinic-data' ?
                                <div
                                    className="droppable-element"
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => {
                                        this.setState({
                                            dragElementType: 'image'
                                        })
                                        e.dataTransfer.setData("text/plain", "")
                                    }}
                                >
                                    <Isvg src={imageIcon} />
                                    <div className='tooltip'>
                                        <p>{'Image'.translate(this.props.lang)}</p>
                                    </div>

                                </div>
                                :
                                null
                        }

                        {
                            this.props.location != '/my-data/main-clinic-data' ?
                                <div
                                    className="droppable-element"
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => {
                                        this.setState({
                                            dragElementType: 'button'
                                        })
                                        e.dataTransfer.setData("text/plain", "")
                                    }}
                                >
                                    <Isvg src={buttonIcon} />
                                    <div className='tooltip'>
                                        <p>{'Button'.translate(this.props.lang)}</p>
                                    </div>

                                </div>
                                :
                                null
                        }
                        {/* {
    this.props.location == '/my-data/main-clinic-data' ?
        <div
            className="droppable-element"
            draggable={true}
            unselectable="on"
            onDragStart={e => {
                this.setState({
                    dragElementType: 'button2'
                })
                e.dataTransfer.setData("text/plain", "")
            }}
        >
            <Isvg src={buttonIcon} />
            <div className='tooltip'>
                <p>{'Button'.translate(this.props.lang)}</p>
            </div>

        </div>
        :
        null
} */}
                        {
                            this.props.location != '/my-data/main-clinic-data' ?
                                <>
                                    <div
                                        className="droppable-element"
                                        draggable={true}
                                        unselectable="on"
                                        onDragStart={e => {
                                            this.setState({
                                                dragElementType: 'account'
                                            })
                                            e.dataTransfer.setData("text/plain", "")
                                        }}
                                    >
                                        <Isvg src={accountIcon} />
                                        <div className='tooltip'>
                                            <p>{'Account'.translate(this.props.lang)}</p>
                                        </div>

                                    </div>
                                    <div
                                        className="droppable-element"
                                        draggable={true}
                                        unselectable="on"
                                        onDragStart={e => {
                                            this.setState({
                                                dragElementType: 'login'
                                            })
                                            e.dataTransfer.setData("text/plain", "")
                                        }}
                                    >
                                        <Isvg src={loginIcon} />
                                        <div className='tooltip'>
                                            <p>{'Login'.translate(this.props.lang)}</p>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                        {/* 
{
    this.props.location == '/my-data/main-clinic-data' ?
        <div
            className="droppable-element"
            draggable={true}
            unselectable="on"
            onDragStart={e => {
                this.setState({
                    dragElementType: 'map'
                })
                e.dataTransfer.setData("text/plain", "")
            }}
        >
            <Isvg src={mapIcon} style={{ width: 32, minWidth: 32 }} />
            <div className='tooltip'>
                <p>{'Map'.translate(this.props.lang)}</p>
            </div>

        </div>
        :
        null
} */}

                        {
                            this.props.location == '/my-data/main-clinic-data' ?
                                <div
                                    className="droppable-element"
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => {
                                        this.setState({
                                            dragElementType: 'mainInfo'
                                        })
                                        e.dataTransfer.setData("text/plain", "")
                                    }}
                                >
                                    <Isvg src={accountIcon} style={{ width: 32, minWidth: 32 }} />
                                    <div className='tooltip'>
                                        <p>{'Main info'.translate(this.props.lang)}</p>
                                    </div>

                                </div>
                                :
                                null
                        }

                        <div className="undo-redo">
                            <div className='button-tooltip-wrap'>
                                <button className={this.state.nodesHistory.length == 0 ? 'disabled button-undo' : 'button-undo'} onClick={() => this.undo()}><i className="fa fa-undo" /></button>

                                <div className='tooltip'>
                                    <p>{'Undo'.translate(this.props.lang)}</p>
                                </div>
                            </div>
                            <div className='button-tooltip-wrap'>
                                <button className={this.state.inverseNodesHistory.length == 0 ? 'disabled button-redo' : 'button-redo'} onClick={() => this.redo()}><i className="fa fa-undo" /></button>

                                <div className='tooltip'>
                                    <p>{'Redo'.translate(this.props.lang)}</p>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className='button-tooltip-wrap'>

                            </div>
                        </div>


                        <div className="devices">
                            <div>
                                <div style={{ height: 33, width: 260 }}>
                                    <Switch value={this.state.loggedIn} label={"Logged".translate(this.props.lang)} onChange={(e) => {
                                        this.setState({ loggedIn: !this.state.loggedIn }, () => {

                                        })
                                    }} />
                                </div>
                            </div>
                            <div style={{ marginRight: 50 }}>
                                <div style={{ height: 33, width: 260 }}>
                                    <Switch value={this.state.isActive} label={"Active state".translate(this.props.lang)} onChange={(e) => {
                                        this.setState({ isActive: !this.state.isActive }, () => {

                                        })
                                    }} />
                                </div>
                            </div>

                            <div className='button-tooltip-wrap'>
                                <button className={this.state.layout == 'lg' ? 'active' : ''} onClick={() => this.setState({ layout: 'lg' }, () => {
                                    this.forceUpdate()
                                    if (this.props.changeLayout) {
                                        this.props.changeLayout(this.state.layout)
                                    }
                                })}><i className="fa fa-desktop" /></button>
                                <div className='tooltip'>
                                    <p>{'Desktop'.translate(this.props.lang)}</p>
                                </div>
                            </div>

                            <div className='button-tooltip-wrap'>
                                <button className={this.state.layout == 'sm' ? 'active' : ''} onClick={() => this.setState({ layout: 'sm' }, () => {
                                    this.forceUpdate()
                                    if (this.props.changeLayout) {
                                        this.props.changeLayout(this.state.layout)
                                    }
                                })}><i className="fa fa-tablet" /></button>
                                <div className='tooltip'>
                                    <p>{'Tablet'.translate(this.props.lang)}</p>
                                </div>
                            </div>

                            <div className='button-tooltip-wrap'>
                                <button className={this.state.layout == 'xs' ? 'active' : ''} onClick={() => this.setState({ layout: 'xs' }, () => {
                                    this.forceUpdate()
                                    if (this.props.changeLayout) {
                                        this.props.changeLayout(this.state.layout)
                                    }
                                })}><i className="fa fa-mobile" /></button>
                                <div className='tooltip'>
                                    <p>{'Mobile'.translate(this.props.lang)}</p>
                                </div>
                            </div>
                            <div className='button-tooltip-wrap'>
                                <button className={this.state.layout == 'app' ? 'active' : ''} onClick={() => this.setState({ layout: 'app' }, () => {
                                    this.forceUpdate()
                                    if (this.props.changeLayout) {
                                        this.props.changeLayout(this.state.layout)
                                    }
                                })}><i className="fa fa-mobile" /></button>
                                <div className='tooltip'>
                                    <p>{'App'.translate(this.props.lang)}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                    {
                        this.state.layout == 'app' ?
                            <div className='import-mobile-template'>
                                <button onClick={() => {
                                    this.forceUpdate()
                                    if (this.props.importMobileToApp) {
                                        this.props.importMobileToApp(this.state.nodes)
                                    }
                                }}>
                                    {'Import mobile template'.translate(this.props.lang)}
                                </button>
                            </div>
                            :
                            null
                    }

                </div>

                <div className="app-builder-area">
                    <div onClick={(e) => this.handleElementHover(e, 'root', true)} className="app-builder-root" style={{ maxWidth: this.state.layout == 'lg' ? 920 : this.state.layout == 'sm' ? 768 : this.state.layout == 'xs' ? 360 : 355 }} onContextMenu={(e) => this.handleRightClick('root', e)}>
                        <Grid selectedClinic={this.props.selectedClinic} selectedGroup={this.props.selectedGroup} mainData={this.props.mainData} loggedIn={this.state.loggedIn} isActive={this.state.isActive} handleRightClick={this.handleRightClick} removeElement={this.removeElement} layout={this.state.layout} addElement={this.addElement} changeLayout={this.changeLayout} changeElement={this.changeElement} id={'root'} nodes={this.state.nodes} cols={100} handleElementHover={this.handleElementHover} activeElement={this.state.activeElement} lang={this.props.lang} ></Grid>
                    </div>
                    <Toolbox listing={this.props.listing} enterprise={this.props.enterprise} libraryIcons={this.props.libraryIcons} isActive={this.state.isActive} changeMultipleStyle={this.changeMultipleStyle} changeProps={this.changeProps} changeVisibility={this.changeVisibility} changeHideElement={this.changeHideElement} layout={this.state.layout} changeStyle={this.changeStyle} nodes={this.state.nodes} activeElement={this.state.activeElement} changeElement={this.changeElement} lang={this.props.lang} location={this.props.location} />
                </div>

                {
                    this.state.contextMenu ?
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                            <div className="app-builder-context-menu-overlay" onClick={() => this.setState({ contextMenu: null })}></div>
                            <div className="app-builder-context-menu" style={{ position: 'absolute', top: this.state.contextMenu.position.y, left: this.state.contextMenu.position.x }}>
                                <ul>
                                    <li><button onClick={this.cutElement}>{'Cut'.translate(this.props.lang)}</button></li>
                                    <li><button onClick={this.copyElement}>{'Copy'.translate(this.props.lang)}</button></li>
                                    <li><button onClick={this.pasteElement}>{'Paste'.translate(this.props.lang)}</button></li>
                                    <li><button onClick={this.cutElement}>{'Remove'.translate(this.props.lang)}</button></li>
                                </ul>
                            </div>

                        </div>
                        :

                        null
                }

                {/* <button onClick={() => {
                    localStorage.setItem('test', JSON.stringify(this.state.nodes));
                }}>Save to local storage</button> */}
                {/* 
                { typeof window != 'undefined' ?
                    <ToReact layout={this.state.layout} nodes={JSON.parse(localStorage.getItem('test'))} />
                    :
                    null
                } */}
            </div>
        )
    }
}

export default AppBuilder;