import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, Alert, Modal, ModalBody, ModalFooter, ModalHeader

} from 'reactstrap';
import Isvg from "react-inlinesvg";

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Checkbox from './forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import PaysonSvg from '../assets/svg/payson.svg';
import SwishSvg from '../assets/svg/swish.svg';
import Stripe from '../assets/svg/stripe.svg'

import DatePicker from '../components/forms/fields/datePicker';
import striptags from 'striptags';


/**
* Pay now modal
* @author   Aleksandar Dabic
*/
class PayNow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            openPaymentModal: false,
        };
    }

    componentDidMount() {
        this.setState({
            value: this.props.isOpen,
            price: this.props.isOpen.questionaryPrice ? this.props.isOpen.questionaryPrice : this.props.isOpen.price,
            canConfirm: false,
            groupPaymentData: this.props.isOpen.groupPaymentData ? this.props.isOpen.groupPaymentData : this.props.groupPaymentData
        }, () => {
            let possiblePaymentMethods = []
            if ((this.state.value.priceList && this.state.value.priceList.freeCard) || this.state.value.haveFreeCard) {
                possiblePaymentMethods.push(4)
            }
            if ((this.state.value.priceList && this.state.value.priceList.onlinePayment) || this.state.value.onlinePayment) {
                possiblePaymentMethods.push(3)
            }
            if ((this.state.value.priceList && this.state.value.priceList.paymentOnSite) || this.state.value.paymentOnSite && !this.props.dropIn) {
                possiblePaymentMethods.push(1)
            }
            if ((this.state.value.priceList && this.state.value.priceList.invoice) || this.state.value.invoice) {
                possiblePaymentMethods.push(2)
            }

            if (possiblePaymentMethods.length == 1) {
                if (possiblePaymentMethods[0] == 1) {
                    this.props.updatePaymentMethod({ _id: this.state.value._id, paymentMethod: 1 })
                }
                if (possiblePaymentMethods[0] == 2) {
                    this.props.updatePaymentMethod({ _id: this.state.value._id, paymentMethod: 2, requsetedInvoice: true })
                }
                if (possiblePaymentMethods[0] == 3) {
                    // this.setState({ selectedPaymentMethod: 3, openPaymentModal: true })
                    let possibleOnlinePaymentMethods = []
                    if (this.state.groupPaymentData?.agentId && this.state.groupPaymentData?.apiKey && this.state.groupPaymentData?.canUsePaysonForPayment) {
                        possibleOnlinePaymentMethods.push('payson')
                    }
                    if (this.state.groupPaymentData?.bankAccount && this.state.groupPaymentData?.canUseSwishForPayment) {
                        possibleOnlinePaymentMethods.push('swish')
                    }
                    if (this.state.groupPaymentData?.stripeAccountID && this.state.groupPaymentData?.canUseStripeForPayment) {
                        possibleOnlinePaymentMethods.push('stripe')
                    }
                    if (possibleOnlinePaymentMethods.length == 1) {
                        if (possibleOnlinePaymentMethods[0] == 'payson') {
                            this.props.openPayson(this.state.value)
                        }
                        if (possibleOnlinePaymentMethods[0] == 'swish') {
                            this.props.openSwish(this.state.value)
                        }
                        if (possibleOnlinePaymentMethods[0] == 'stripe') {
                            this.props.openStripe(this.state.value)
                        }
                    } else {
                        this.setState({ selectedPaymentMethod: 3, openPaymentModal: true })
                    }
                }
            } else {
                this.setState({ openPaymentModal: true })
            }
        })
    }

    render() {
        return (
            <Modal isOpen={this.state.openPaymentModal} centered size={this.props.size ? this.props.size : null}>
                <ModalHeader style={{ margin: 'auto' }}>{'Pay now'.translate(this.props.lang)}</ModalHeader>
                <ModalBody className="delete-modal">

                    {
                        this.state.value ?
                            <Row>
                                <Col lg='12'>
                                    <FormGroup>
                                        <Label>{'Select payment method'.translate(this.props.lang)}</Label>
                                        <Input type='select' value={this.state.selectedPaymentMethod} onChange={(e) => {
                                            this.setState({
                                                freeCardNumber: null,
                                                freeCardRegion: null,
                                                freeCardValidFrom: null,
                                                freeCardValidTo: null,
                                                canConfirm: false
                                            })
                                            if (e.target.value != 'None') {
                                                this.setState({ selectedPaymentMethod: e.target.value }, () => {
                                                    if (this.state.selectedPaymentMethod == 4) {
                                                        this.setState({ price: 0 })
                                                    } else {
                                                        this.setState({ price: this.state.value.questionaryPrice ? this.state.value.questionaryPrice : this.state.value.price })
                                                    }
                                                })
                                            } else {
                                                this.setState({ selectedPaymentMethod: null, price: this.state.value.questionaryPrice })
                                            }
                                        }}>
                                            <option value={null}>{'None'.translate(this.props.lang)}</option>
                                            {
                                                (this.state.value.priceList && this.state.value.priceList.freeCard) || this.state.value.haveFreeCard ?
                                                    <option value={4}>{'Free card'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                (this.state.value.priceList && this.state.value.priceList.onlinePayment) || this.state.value.onlinePayment ?
                                                    <option value={3}>{'Online'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                (this.state.value.priceList && this.state.value.priceList.paymentOnSite) || this.state.value.paymentOnSite && !this.props.dropIn ?
                                                    <option value={1}>{'On site'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                (this.state.value.priceList && this.state.value.priceList.invoice) || this.state.value.invoice ?
                                                    <option value={2}>{'Invoice'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg='12'>
                                    <FormGroup>
                                        <Label>{'Amount'.translate(this.props.lang)}</Label>
                                        <Input type='text' value={this.state.price} disabled />
                                    </FormGroup>
                                </Col>
                                <Col lg='12'>
                                    {
                                        this.state.selectedPaymentMethod == 4 ?
                                            <div className="flex-mob-column">
                                                <FormGroup>
                                                    <Label>{'Free card number'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.freeCardNumber} onChange={(e) => {
                                                        e.target.value = striptags(e.target.value)
                                                        this.setState({ freeCardNumber: e.target.value }, () => {
                                                            if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                this.setState({ canConfirm: true })
                                                            } else {
                                                                this.setState({ canConfirm: false })
                                                            }
                                                        })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Issued at'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.freeCardRegion} onChange={(e) => {
                                                        if (e.target.value != 'None') {
                                                            this.setState({ freeCardRegion: e.target.value }, () => {
                                                                if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                    this.setState({ canConfirm: true })
                                                                } else {
                                                                    this.setState({ canConfirm: false })
                                                                }
                                                            })
                                                        } else {
                                                            this.setState({ freeCardRegion: null }, () => {
                                                                if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                    this.setState({ canConfirm: true })
                                                                } else {
                                                                    this.setState({ canConfirm: false })
                                                                }
                                                            })
                                                        }
                                                    }}>
                                                        <option value={null}>{'None'.translate(this.props.lang)}</option>
                                                        {
                                                            this.props.regions && this.props.regions.map((item, idx) => {
                                                                return (
                                                                    <option value={item._id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup className="datepicker-mob-up-calendar">
                                                    <DatePicker
                                                        label={'Valid from'.translate(this.props.lang)}
                                                        value={this.state.freeCardValidFrom}
                                                        onChange={(e) => {
                                                            this.setState({ freeCardValidFrom: e }, () => {
                                                                if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                    this.setState({ canConfirm: true })
                                                                } else {
                                                                    this.setState({ canConfirm: false })
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    {/* <Label>{'Valid from'.translate(this.props.lang)}</Label>
                                                    <Input type='date' value={this.state.freeCardValidFrom} onChange={(e) => {
                                                        this.setState({ freeCardValidFrom: e.target.value }, () => {
                                                            if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                this.setState({ canConfirm: true })
                                                            } else {
                                                                this.setState({ canConfirm: false })
                                                            }
                                                        })
                                                    }} /> */}
                                                </FormGroup>
                                                <FormGroup className="datepicker-mob-up-calendar">
                                                    <DatePicker
                                                        label={'Valid to'.translate(this.props.lang)}
                                                        value={this.state.freeCardValidTo}
                                                        onChange={(e) => {
                                                            this.setState({ freeCardValidTo: e }, () => {
                                                                if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                    this.setState({ canConfirm: true })
                                                                } else {
                                                                    this.setState({ canConfirm: false })
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    {/* <Label>{'Valid to'.translate(this.props.lang)}</Label>
                                                    <Input type='date' value={this.state.freeCardValidTo} onChange={(e) => {
                                                        this.setState({ freeCardValidTo: e.target.value }, () => {
                                                            if (this.state.freeCardNumber && this.state.freeCardValidFrom && this.state.freeCardValidTo && this.state.freeCardRegion) {
                                                                this.setState({ canConfirm: true })
                                                            } else {
                                                                this.setState({ canConfirm: false })
                                                            }
                                                        })
                                                    }} /> */}
                                                </FormGroup>

                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.selectedPaymentMethod == 3 ?
                                            <div className="payment-container">
                                                {this.state.groupPaymentData?.agentId && this.state.groupPaymentData?.apiKey && this.state.groupPaymentData?.canUsePaysonForPayment ?
                                                    <Button className="payment-button-payson" onClick={() => this.props.openPayson(this.state.value)}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                                    : null}
                                                {this.state.groupPaymentData?.bankAccount && this.state.groupPaymentData?.canUseSwishForPayment ?
                                                    <Button className="payment-button-swish" onClick={() => this.props.openSwish(this.state.value)}><Isvg src={SwishSvg} /></Button>
                                                    : null}
                                                {this.state.groupPaymentData?.stripeAccountID && this.state.groupPaymentData?.canUseStripeForPayment ?
                                                    <Button className="payment-button-payson" onClick={() => this.props.openStripe(this.state.value)}><Isvg src={Stripe} /> <span>{'Credit card'.translate(this.props.lang)}</span></Button>
                                                    : null}
                                            </div>
                                            :
                                            null
                                    }
                                </Col>




                            </Row>
                            :
                            null
                    }

                </ModalBody>
                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                    {
                        this.state.selectedPaymentMethod == 4 ?
                            <Button type="submit" color="primary" onClick={() => {
                                let obj = {
                                    freeCardNumber: this.state.freeCardNumber,
                                    freeCardValidFrom: this.state.freeCardValidFrom,
                                    freeCardValidTo: this.state.freeCardValidTo,
                                    freeCardRegion: this.state.freeCardRegion,
                                    _id: this.state.value._id
                                }
                                this.props.updatePaymentMethodFreeCard(obj)
                            }} disabled={!this.state.canConfirm} >{'Confirm'.translate(this.props.lang)}</Button>
                            :
                            null
                    }
                    {
                        this.state.selectedPaymentMethod == 1 ?
                            <Button type="submit" color="primary" disabled={this.props.loadingUpdatePaymentMethod} onClick={() => this.props.updatePaymentMethod({ _id: this.state.value._id, paymentMethod: 1 })}  >{'Confirm'.translate(this.props.lang)}</Button>
                            :
                            null
                    }
                    {
                        this.state.selectedPaymentMethod == 2 ?
                            <Button type="submit" color="primary" disabled={this.props.loadingUpdatePaymentMethod} onClick={() => this.props.updatePaymentMethod({ _id: this.state.value._id, paymentMethod: 2, requsetedInvoice: true })} >{'Request invoice'.translate(this.props.lang)}</Button>
                            :
                            null
                    }
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'Cancel'.translate(this.props.lang)}</Button>
                </ModalFooter>
            </Modal>

        );
    }
}

export default PayNow;