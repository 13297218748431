import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input
} from 'reactstrap';


import FormBuilder from '../../../components/forms/modalFormBuilder';
import { required } from '../../../components/forms/validation';
import Isvg from 'react-inlinesvg';
import Search from '../../../components/search';

import ListBuilder from '../../../components/listBuilder';
import DeleteModal from '../../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../../components/referralClinics';
import moment from 'moment';
import PrintHelper from '../../../components/printHelper';
import View from '../../../assets/svg/eye.svg'
import info from '../../../assets/svg/info.svg';
// import FormPreview from '../../../components/formPreview';
import FormPreview from '../../forms/previewAnsweredForm';

import ReferralContent from '../../../components/referralContent';

import profileUser from '../../../assets/images/user.png';
import notification from '../../../assets/svg/notification.svg';
import moreIcon from '../../../assets/svg/more.svg';
import xIcon from '../../../assets/svg/x.svg';
import warrning from '../../../assets/svg/warrning.svg'

import changeTime from '../../../assets/svg/change_time.svg'

import arrowLeftIcon from '../../../assets/svg/arrow_left.svg';



// import AnswerRevisitForm from '../../../components/answerRevisitForm';
import AnswerRevisitForm from '../../forms/revisitForm';


import PayNowModal from '../../../components/payNowModal';
import Payson from '../../../components/payson';
import Swish from '../../swish';

import PatientCasesMob from './patientCasesMob';
import RescheduleWebBookingCalendar from './rescheduleWebBookingCalendar_v2';
import InfoModal from '../../../components/infoModal';
import Stripe from '../../../components/stripe';
import ErrorModal from '../../../components/errorModal';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Patient's referrals
* @author   Stefan Pantic
*/
class Referrals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            activeRevisitForm: 1,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,

        };
    }


    get = (callback) => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet
                    // loading: null
                }, () => {
                    if (this.state.referral && this.state.items && this.state.items.length && this.state.items.filter(el => el._id == this.state.referral._id).length) {
                        let index = this.state.items.findIndex(el => el._id == this.state.referral._id);
                        if (index > -1) {
                            this.setState({ referral: this.state.items[index] })
                        }
                    }
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    if (callback) {
                        callback();
                    }
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (this.props[0].location.state && this.props[0].location.state.successModal) {
            this.setState({ successModal: this.props[0].location.state.successModal }, () => {
                this.props.clearStatusBefore()
                this.props[0].history.push({
                    state: { successModal: null }
                })
            })
        }
        if (this.props[0].location.state && this.props[0].location.state.referralPreviewObj) {
            let referralPreviewObj = { ...this.props[0].location.state.referralPreviewObj }
            this.props[0].history.push({
                state: { referralPreviewObj: null }
            })
            if (referralPreviewObj.referralId) {
                let params = {};
                if (this.state.useState) {
                    params = this.state.params;
                } else {
                    params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
                }
                // console.log(referralPreviewObj)
                if (String(params.page) != String(referralPreviewObj.page)) {
                    this.updateParams('page', referralPreviewObj.page)
                    this.get(() => {
                        this.openReferral(referralPreviewObj.referralId)
                    })
                } else {
                    if (this.state.items && this.state.items.length && this.state.items.filter(el => el._id == referralPreviewObj.referralId).length) {
                        this.openReferral(referralPreviewObj.referralId)
                    } else {
                        this.get(() => {
                            this.openReferral(referralPreviewObj.referralId)
                        })
                    }

                }
            }



        }



        this.inteval = setInterval(() => {
            this.get();
        }, 10 * 1000);

        window.addEventListener('resize', this.resizeWindow);


    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        }
    }

    openReferral = (referralId) => {
        let items = this.state.items;
        console.log(items, referralId)
        if (items && items.length && referralId && items.filter(el => el._id == referralId).length) {
            let index = items.findIndex(el => el._id == referralId);
            if (index > -1 && items[index]) {
                this.setState({ referral: items[index] })
            }
        }

    }
    componentWillUnmount() {
        if (this.inteval) {
            clearInterval(this.inteval)
        }

        window.removeEventListener('resize', this.resizeWindow);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevState.referral != this.state.referral && !this.state.referral && this.state.activeRevisitForm !== 1) {
            this.setState({ activeRevisitForm: 1 })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }
    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }




    /**
    * Update seen status of referral when patient open scheduled referral
    * @author   Stefan Pantic
    * @Objectparam    {String} id                 referral's id
    */
    update = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + `/referrals/seen/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },

            }).then(res => res.json()).then((result) => {
                this.setState({
                    loading: null
                })
            })

        })
    }




    /**
    * Reject scheduled referral
    * @author   Stefan Pantic
    * @Objectparam    {String} data             reason for rejecting referral
    */
    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        rejectForm: false,
                        requestAnotherDateForm: false
                    })
                }

            })
        })
    }

    updatePaymentControl = (data) => {
        // console.log(data)
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                // console.log(result)
                if (result.paymentMethod == 1) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Payment must be made "On site" before the visit.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else if (result.paymentMethod == 2) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                }
            })
        })
    }
    updatePaymentMethodFreeCard = (data) => {
        // console.log(this.state.payNowModal)
        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowModal && this.state.payNowModal.startEventReservedDate && (new Date(data.freeCardValidFrom) > new Date(this.state.payNowModal.startEventReservedDate) || new Date(data.freeCardValidTo) < new Date(this.state.payNowModal.startEventReservedDate))) {
            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            // alert(data)
            data.freeCard = true;
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        loading: false,
                        successModal: 'Remember to take your free card with you.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)
                        this.setState({ tabIndex: 2 })

                    })
                    this.get();


                })
            })
        }


    }
    openPayson = (data) => {
        this.setState({
            paysonModalControl: data,

        })
    }
    openStripe = (data) => {
        this.setState({
            stripeModalControl: false
        }, () => {
            this.setState({
                stripeModalControl: data,

            })
        })

    }
    openSwish = (data) => {
        this.setState({
            swishModalControl: data,

        }, () => {
            // console.log(this.state.swishModalControl)
        })
    }
    callbackAfterPaying = () => {
        let paidControls = [];
        if (this.state.paidControls && this.state.paidControls.length) {
            paidControls = this.state.paidControls;
        }
        if (this.state.referral && this.state.referral._id) {
            paidControls.push(this.state.referral._id)
            this.setState({ paidControls })
        }
        this.setState({ payNowModal: null, swishModalControl: null, swishModal: null, successModal: 'Payment successful.'.translate(this.props.lang) }, () => {

            this.get()
        })
    }

    rejectWebBookingReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.rejectWebBooking._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        rejectWebBooking: null,
                    })
                }

            })
        })
    }

    rescheduleAppointment = (item) => {
        if (item.groupAlias) {
            fetch(API_ENDPOINT + '/data/without-token/professions/all/' + item.groupAlias, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    isDropIn: this.state.isDropIn,
                    uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    let availableProfessions = [];
                    for (let i = 0; i < result.length; i++) {
                        availableProfessions.push({
                            name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                            value: result[i]._id
                        })
                    }

                    this.setState({ availableProfessions }, () => {
                        this.setState({ rescheduleAppointment: item, unRebookModal: false })
                    })
                }



            })
        }




    }
    // rescheduleAppointment = (item) => {
    //     if (item.groupAlias) {
    //         fetch(API_ENDPOINT + '/data/without-token/professions/all/' + item.groupAlias, {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 isDropIn: this.state.isDropIn,
    //                 uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
    //             })
    //         }).then(res => res.json()).then((result) => {
    //             if (result) {
    //                 let availableProfessions = [];
    //                 for (let i = 0; i < result.length; i++) {
    //                     availableProfessions.push({
    //                         name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
    //                         value: result[i]._id
    //                     })
    //                 }

    //                 this.setState({ availableProfessions }, () => {
    //                     this.setState({ rescheduleAppointment: item, unRebookModal: false })
    //                 })
    //             }



    //         })
    //     }




    // }

    backFromCalendar = () => {
        this.setState({
            rescheduleAppointment: null,
            selectedTime: null,
            availableProfessions: []
        });
    }
    checkPayment = (refId) => {
        if (refId) {
            fetch(API_ENDPOINT + '/referrals/' + refId, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result) {

                    // because of another notification for requested invoice doctor can not see notification that patient rescheduled webbooking we delete userNotifications for same case so just one show
                    // if (result.referralIsPaid) {
                    let selectedTime = `${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(result.patientReservedTimestamp).format(this.props.dateFormat)*/ : null}${this.props.lang == 'se' ? ' kl. ' : ' '}${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, 'HH:mm')/*moment.unix(result.patientReservedTimestamp).format('HH:mm')*/ : null}`;

                    let successText = `Thanks! We will remind you via notifications before your meeting that takes place`.translate(this.props.lang) + ` ${selectedTime}`;
                    if (!result?.onlineVisit) {
                        let arr = [];
                        if (result?.clinic?.name) { arr.push(result?.clinic?.name) }
                        if (result?.clinic?.street) { arr.push(result?.clinic?.street) }
                        if (result?.clinic?.zip) { arr.push(result?.clinic?.zip) }
                        if (result?.clinic?.city) { arr.push(result?.clinic?.city) }

                        if (!arr.length) { successText += '.'; }
                        else {
                            successText += `, ${arr.join(', ')}.`;
                        }
                        // successText += `, ${result?.clinic?.name}, ${result?.clinic?.street ? result.clinic.street : ''}, ${result?.clinic?.zip ? result.clinic.zip : ''} ${result?.clinic?.city ? result.clinic.city : ''}.`;
                    } else {
                        successText += '.'
                    }
                    this.setState({ successModal: successText })

                    // } else {
                    //     this.setState({ payNowReferralModal: result })
                    // }

                }
            })
        }

    }

    updatePaymentMethodReferralFreeCard = (data) => {

        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowReferralModal && this.state.payNowReferralModal.patientReservedTimestamp && (new Date(data.freeCardValidFrom) > new Date(this.props.getStringDateTs(this.state.payNowReferralModal.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${`${this.props.dateFormat} HH:mm`} HH:mm`)*/) || new Date(data.freeCardValidTo) < new Date(this.props.getStringDateTs(this.state.payNowReferralModal.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/))) {

            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            data.freeCard = true;
            let onlinePaymentMethod = this.state.onlinePaymentMethod;
            onlinePaymentMethod = 1
            // let referral = this.state.referral;
            // referral.price = 0

            this.setState({
                onlinePaymentMethod,
                // referral
                freeCardData: data
            }, () => {
                this.updateReferral('freeCard')
            })

            // this.updateReferral('freeCard')
        }


    }
    updateReferral = (type) => {
        let referral = this.state.payNowReferralModal;
        let alias = referral.groupAlias;
        let data = {
            freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
            paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
        }
        if (this.state.freeCardData) {
            data.freeCardData = this.state.freeCardData
        }

        if (this.state.payson) {
            data.payson = true
        }
        if (this.state.swish) {
            data.swish = true
        }
        if (this.state.stripe) {
            data.stripe = true
        }
        if (data.paymentMethod == 2) {
            data.requsetedInvoice = true;
        }

        this.setState({
            loadingPay: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referrals/update/' + referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.paymentMethod == 2 || result.paymentMethod == 1) {
                        this.setState({
                            payNowReferralModal: null
                        }, () => {
                            if (result.paymentMethod == 2) {
                                this.setState({
                                    successPayModal: "You have successfully scheduled a visit. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the \"My payments\" page.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else if (result.paymentMethod == 1) {
                                this.setState({
                                    successPayModal: "You have successfully scheduled a visit. Payment must be made \"On site\" before the visit.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else {
                                this.props[0].history.push('/my-data/personal-referrals/referrals')
                            }


                        })
                    } else if (result.payson) {
                        this.setState({
                            paysonModal: referral,
                            payNowReferralModal: null
                        })
                    } else if (result.swish) {
                        this.setState({
                            swishModal: referral,
                            payNowReferralModal: null
                        })
                    } else if (result.stripe) {
                        this.setState({
                            stripeModal: false
                        }, () => {
                            this.setState({
                                stripeModal: referral,
                                // payNowReferralModal: null
                            })
                        })

                    } else if (result.freePayment == 1) {
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + referral._id, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: alias })
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                payNowReferralModal: null
                            }, () => {
                                if (type && type == 'freeCard') {
                                    this.setState({
                                        successPayModal: "You have successfully scheduled a visit. Remember to take your free card with you.".translate(this.props.lang),
                                        successLink: '/my-data/personal-referrals/referrals'
                                    })
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })
                        })

                    }

                }
                this.setState({
                    freeCardData: null
                })

            })
        })

    }

    unRebookBBCRCase = (item) => {
        if (item?._id) {
            let obj = {
                _id: item._id,
                requestNewAppointment: item.requestNewAppointment,
                noNewAppointment: item.noNewAppointment,
                reason: item.reason
            }

            this.setState({ _loadingUnRebook: true }, () => {
                fetch(API_ENDPOINT + '/referrals/un-rebook/case', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            _loadingUnRebook: false
                        })
                    } else {
                        this.setState({ unRebookBBCRModal: null, _loadingUnRebook: false })

                    }

                    this.get()
                })
            })
        }



    }

    getSavedRevisitForms = (referralId, groupObj) => new Promise((resolve) => {
        if (!referralId || !groupObj || (!groupObj.id && !groupObj.alias)) return resolve(false);
        try {
            let url = `${API_ENDPOINT}/users/revisit-questionaries/saved/data?referralId=${referralId}`;
            if (groupObj.id) url += `&groupId=${groupObj.id}`;
            if (groupObj.alias) url += `&groupAlias=${groupObj.alias}`;

            this.setState({ loading: true, savedRevisitForms: [] }, async () => {
                const newState = {
                    loading: false
                };
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                    }
                });
                if (!response || !response.ok) {
                    this.setState(newState);
                    return resolve(false);
                }

                const result = await response.json();
                if (!result || !result.savedForms) {
                    this.setState(newState);
                    return resolve(false);
                }

                newState.savedRevisitForms = result.savedForms;
                this.setState(newState);
                return resolve(true);
            });
        } catch (err) {
            console.log('--- getSavedRevisitForms err: ', err);
            return resolve(false);
        }
    });

    listForms = async (item) => {
        if (!item || !item.revisitFormsArray) return;
        let groupAlias;
        if (item.alias) {
            groupAlias = item.alias
        }
        if (
            !item.alias
            && item && item.recipientClinic
            && this.props.uData.patientGroups
            && this.props.uData.patientGroups.filter?.(group => group && group.clinics && group.clinics.filter?.(clinic => clinic == item?.recipientClinic).length)?.length
        ) {
            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == item.recipientClinic).length)[0];
            if (group && group.alias) {
                groupAlias = group.alias;
            }
        }

        await this.getSavedRevisitForms(item._id, { alias: groupAlias }); // TODO
        this.setState({
            formsToAnswer: item.revisitFormsArray
        });
    }


    render() {
        // if (this.state.answerRevisitForm) console.log('FORM : ', this.state.answerRevisitForm)
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        const statusColors = {
            'waiting-for-approval': 'orange',
            'waiting': 'orange',
            'waiting-for-payment': 'orange',
            'disallowed': 'red',
            'in-progress': 'green',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'canceled-by-clinic': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'revisit-scheduled': 'green',
            'requested-new-appointment': 'orange',
            'external': 'gray',
        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }

        if (this.state.windowWidth < 768) {
            return (
                <PatientCasesMob {...this.props} regions={this.state.regions} generateSections={this.generateSections} />
            )
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'senderClinicData', label: 'Sender'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'questionaryName', label: 'Reason'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Referral date'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    rowBackground={(item) => { return item.status == 'scheduled' || item.status === 'visit-rescheduled' ? '#d4ffd9' : null }}
                                    rawItems={this.state.items}
                                    items={this.state.items.map((item => {
                                        if (item.status === 'closed' && !item.dropIn && item.patientReservedTimestamp) {
                                            item.closedTimestamp = item.patientReservedTimestamp;
                                        }
                                        return {
                                            ...item,
                                            version: item.patient.referralNumber + (item.version != 0 ? '.' + item.version : ''),
                                            questionaryName: <> {item.questionaryName ? this.props.lang == 'en' ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)') : ''}<br />
                                                <span style={item && item.revisitForms && item.revisitFormsArray && item.revisitFormsArray.filter(forms => forms.finishedForm).length != item.revisitForms.length ? { color: 'red' } : { color: 'green' }}>{item && item.revisitForms && item.revisitFormsArray ? 'Forms:'.translate(this.props.lang) + ' ' + item.revisitFormsArray.filter(forms => forms.finishedForm).length + '/' + item.revisitForms.length : ''} </span></>,

                                            senderClinicData: <>{item.patient ? item.patient.name : null}<br /><span className="clinic-subgroup-name">{item.patient.socialSecurityNumber} </span> </>,

                                            recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span><br /> <span className="clinic-subgroup-name">{item.doctorName}</span>  </>,
                                            originalStatus: item.status,
                                            status: <>
                                                <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                {item.status !== 'waiting-for-approval' && item.status !== 'waiting-for-payment' && item.status !== 'waiting' ?
                                                    <span>(
                                                        {item.status == 'disallowed' ?
                                                            // moment.unix(item.disallowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                            this.props.getStringDateTs(item.disallowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                            :
                                                            item.status == 'return-for-update' ?
                                                                // moment.unix(item.returnTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                this.props.getStringDateTs(item.returnTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                :
                                                                item.status == 'allowed' ?
                                                                    // moment.unix(item.allowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                    this.props.getStringDateTs(item.allowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                    :
                                                                    item.status == 'patient-requested-another-time' ?
                                                                        // moment.unix(item.patientRequestTimeTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                        this.props.getStringDateTs(item.patientRequestTimeTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                        :
                                                                        item.status == 'patient-rejected' ?
                                                                            // moment.unix(item.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                            this.props.getStringDateTs(item.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                            :
                                                                            item.status == 'patient-accepted' ?
                                                                                // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                :
                                                                                item.status == 'patient-accepted' ?
                                                                                    // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                    this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                    :
                                                                                    item.status == 'clinic-accepted-requested-time' ?
                                                                                        // moment.unix(item.clinicAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                        this.props.getStringDateTs(item.clinicAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                        :
                                                                                        item.status == 'clinic-rejected-requested-time' ?
                                                                                            // moment.unix(item.clinicRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                            this.props.getStringDateTs(item.clinicRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                            :
                                                                                            item.status == 'approved-by-region-manager' ?
                                                                                                // moment.unix(item.approvedByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                this.props.getStringDateTs(item.approvedByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                :
                                                                                                item.status == 'canceled-by-region-manager' ?
                                                                                                    // moment.unix(item.canceledByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                    this.props.getStringDateTs(item.canceledByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                    :
                                                                                                    item.status == 'scheduled' ?
                                                                                                        // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                        this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                        :
                                                                                                        item.status == 'visit-rescheduled' ?
                                                                                                            // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                            this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                            :
                                                                                                            item.status == 'revisit-scheduled' ?
                                                                                                                // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat}${!item.hideTimeFromThePatient ? ' HH:mm' : ''}`)
                                                                                                                this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat}${!item.hideTimeFromThePatient ? ' HH:mm' : ''}`)
                                                                                                                :
                                                                                                                item.status == 'not-booked' ?
                                                                                                                    // moment.unix(item.notBookedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                    this.props.getStringDateTs(item.notBookedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                    :
                                                                                                                    item.status == 'closed' ?
                                                                                                                        // moment.unix(item.closedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                        this.props.getStringDateTs(item.closedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                        :
                                                                                                                        item.status == 'in-progress' ?
                                                                                                                            // moment.unix(item.inProgressTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                            this.props.getStringDateTs(item.inProgressTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                            :
                                                                                                                            item.status == 'canceled-by-clinic' ?
                                                                                                                                // moment.unix(item.canceledByClinicTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                this.props.getStringDateTs(item.canceledByClinicTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                                :
                                                                                                                                item.status == 'canceled-by-clinic' ?
                                                                                                                                    // moment.unix(item.canceledByClinicTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                    this.props.getStringDateTs(item.canceledByClinicTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                                    :
                                                                                                                                    item.status == 'requested-new-appointment' ?
                                                                                                                                        // moment.unix(item.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                        this.props.getStringDateTs(item.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)
                                                                                                                                        :

                                                                                                                                        null
                                                        }) {"Timezone: Stockholm".translate(this.props.lang)}</span> : null} </>, timestamp: this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/,

                                        }
                                    }))}
                                    actions={
                                        [
                                            {

                                                component: <div className="tabel-action-tooltip"> <Isvg src={changeTime} /> <div className="chat-icons-tooltip">{'Un/rebook'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    if ((item.status === 'scheduled' || item.status === 'visit-rescheduled') && item.wayOfScheduling === 'webBooking') {
                                                        this.setState({ unRebookModal: item })
                                                    } else {
                                                        this.setState({ unRebookBBCRModal: { ...item, requestNewAppointment: false, noNewAppointment: false, reason: '' } })
                                                    }

                                                },
                                                condition: (item) => {
                                                    if ((((item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled') && item.wayOfScheduling === 'webBooking') || ((item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled') && item.wayOfScheduling === 'bookingByClinic') || (item.originalStatus === 'revisit-scheduled' || item.originalStatus === 'visit-rescheduled')) && item.patientReservedTimestamp - 15 * 60 > new Date().getTime() / 1000) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }


                                            },
                                            {

                                                component: <div className="tabel-action-tooltip"> <Isvg src={View} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    // {
                                                    //     fetch(API_ENDPOINT + '/notification/seen/update-type', {
                                                    //         method: 'POST',
                                                    //         headers: {
                                                    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    //             'content-type': 'application/json'
                                                    //         },
                                                    //         body: JSON.stringify({ type: item._id, user: this.props.uData._id })
                                                    //     }).then(res => res.json()).then((result) => {
                                                    //         this.props.verifyUser()
                                                    //         this.setState({ referral: item })
                                                    //         this.update(item._id)
                                                    //     })
                                                    // }
                                                    this.setState({ referral: item }, () => {
                                                        this.setState({ refresh: new Date().getTime() })
                                                    })

                                                }

                                            },



                                            // {
                                            //     component: <div className="tabel-action-tooltip">  test <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                            //     onClick: (item) => this.setState({ referralContentPreview: item }, () => {
                                            //         this.setState({ refresh: new Date().getTime() })
                                            //     })
                                            // },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.unRebookBBCRModal ?
                        <Modal isOpen={this.state.unRebookBBCRModal} centered >
                            <ModalHeader>
                                <h5>{'Un/rebook'.translate(this.props.lang)}</h5>
                            </ModalHeader>
                            <ModalBody className='un-rebook-case-modal-body-wrap'>
                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = true;
                                    unRebookBBCRModal.noNewAppointment = false;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.requestNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"Request new appointment".translate(this.props.lang)}</h6>
                                </div>

                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = false;
                                    unRebookBBCRModal.noNewAppointment = true;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.noNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"No new appointment".translate(this.props.lang)}</h6>
                                </div>
                                {
                                    this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment ?
                                        <FormGroup className='reason-wrap'>
                                            <Label>{this.state.unRebookBBCRModal.requestNewAppointment ? 'What time would best suit you?'.translate(this.props.lang) : "State reason:".translate(this.props.lang)}</Label>
                                            <Input type='textarea' value={this.state.unRebookBBCRModal.reason} onChange={(e) => {
                                                let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                                unRebookBBCRModal.reason = e.target.value;
                                                this.setState({ unRebookBBCRModal })
                                            }} />
                                        </FormGroup>
                                        :
                                        null
                                }


                            </ModalBody>

                            <ModalFooter className="buttons-right-blue column-buttons">
                                <Button
                                    color="primary"
                                    style={{ margin: 10 }}
                                    disabled={(this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment) && this.state.unRebookBBCRModal.reason?.trim() && !this.state._loadingUnRebook ? false : true}
                                    onClick={() => {
                                        let item = { ...this.state.unRebookBBCRModal }
                                        this.unRebookBBCRCase(item)
                                    }}
                                >
                                    {'Submit'.translate(this.props.lang)}
                                </Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookBBCRModal: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.unRebookModal ?
                        <Modal isOpen={this.state.unRebookModal} centered >

                            <ModalFooter className="buttons-right-blue column-buttons">
                                {
                                    this.state.unRebookModal.activeWebBooking ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let item = { ...this.state.unRebookModal }
                                            this.rescheduleAppointment(item)

                                        }}>{'Reschedule appointment'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }

                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    let item = { ...this.state.unRebookModal }
                                    this.setState({ rejectWebBooking: item, unRebookModal: false })
                                }}>{'Cancel appointment'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.rejectWebBooking ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectWebBookingReferral(data)}
                            isOpen={this.state.rejectWebBooking}
                            toggle={() => this.setState({ rejectWebBooking: null })}
                            title={'Cancel appointment'.translate(this.props.lang)}
                            buttonText={'Confirm cancellation'.translate(this.props.lang)}
                            additionalButton={<Button color="primary" onClick={() => this.setState({ rejectWebBooking: !this.state.rejectWebBooking })}>{'Close'.translate(this.props.lang)}</Button>}
                            modalFooterClassname={'buttons-right-blue'}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    height: 100,
                                                    label: 'Reason for cancellation'.translate(this.props.lang),
                                                    validate: [required('Reason for cancellation is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.rescheduleAppointment ?
                        <Modal isOpen={this.state.rescheduleAppointment} centered size="xxl" className="calendar-modal webbooking-calendar-patient">
                            <ModalHeader>

                                <ModalHeader
                                    toggle={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })}
                                    close={<button type="button"
                                        className='close'
                                        style={{ position: 'absolute', top: '15px', right: '15px' }}
                                        onClick={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })}
                                    ><Isvg src={xIcon} /></button>}
                                    className='webbooking-calendar-patient-header'
                                >
                                    <div className='back-button-wrap' onClick={() => this.backFromCalendar()}>
                                        <Isvg src={arrowLeftIcon} />
                                        <div className='back-button-text'>{'Back'.translate(this.props.lang)}</div>
                                    </div>
                                    <div className='webbooking-calendar-patient-title'>{'Reschedule appointment'.translate(this.props.lang)}</div>
                                    <div className='empty-div'></div>
                                </ModalHeader>

                            </ModalHeader>
                            <ModalBody>
                                <RescheduleWebBookingCalendar
                                    {...this.props}
                                    referral={this.state.rescheduleAppointment}
                                    closeCalendarModal={(item) => this.setState({ rescheduleAppointment: item })}
                                    personalQuestionary={this.state.rescheduleAppointment.questionaryData}
                                    selectedReferral={this.state.rescheduleAppointment.questionaryData}
                                    backFromCalendar={() => this.backFromCalendar()}
                                    setSelectedTime={(selectedTime) => this.setState({ selectedTime })}
                                    price={this.state.rescheduleAppointment.questionaryData?.price}
                                    availableProfessions={this.state.availableProfessions}
                                    changeSelectedTime={this.state.rescheduleAppointment._id}
                                    displayPaymentInfoText={this.state.rescheduleAppointment.displayPaymentInfoText ? true : false}
                                    // createdReferral={(item) => this.setState({ createdReferral: item }, () => this.sendReferral())}
                                    createdReferral={(item) => {
                                        this.checkPayment(item)

                                        this.setState({ rescheduleAppointment: null }, () => {
                                            this.get()
                                        })
                                    }}
                                />


                            </ModalBody>

                        </Modal>

                        :
                        null
                }
                {
                    this.state.payNowReferralModal ?
                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowReferralModal.questionaryData}
                            toggle={() => {
                                this.setState({
                                    successPayModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowReferralModal: false,
                                })
                            }}
                            regions={this.state.regions}
                            dropIn={false}
                            groupPaymentData={this.state.payNowReferralModal.groupPaymentData}
                            updatePaymentMethod={(method) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = method.paymentMethod;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,

                                }, () => {
                                    this.updateReferral()
                                })
                            }}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodReferralFreeCard(data)}
                            openPayson={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    payson: data,
                                    swish: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openSwish={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    swish: data,
                                    payson: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openStripe={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    stripe: data,
                                    payson: null,
                                    swish: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                        >
                        </PayNowModal>
                        :
                        null
                }
                {this.state.paysonModal ?

                    <Payson
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            paysonModal: item
                        }, () =>
                            // this.setState({
                            //     successModal: true
                            // })
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            })
                        )}
                        referral={this.state.paysonModal._id}
                        price={this.state.paysonModal.questionaryPrice}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.stripeModal ?

                    <Stripe
                        {...this.props}
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            stripeModal: item
                        }, () =>
                            // this.setState({
                            //     successModal: true
                            // })
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            })
                        )}
                        referral={this.state.stripeModal._id}
                        price={this.state.stripeModal.questionaryPrice}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        getStringDateInverseTs={this.props.getStringDateInverseTs}
                        clearStatusBefore={() => this.props.clearStatusBefore()}
                        verifyUser={() => this.props.verifyUser(null, null, true)}
                        lang={this.props.lang}
                        history={this.props[0]}
                        open={this.state.swishModal}
                        phone={this.props.uData.phone}
                        price={this.state.swishModal.questionaryPrice}
                        referralNumber={this.state.swishModal.patient && this.state.swishModal.patient.referralNumber}
                        referral={this.state.swishModal._id}
                        openModal={(item) => this.setState({
                            swishModal: item
                        }, () =>
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            }))}
                        callbackFunction={() =>
                            this.setState({
                                successPayModal: "Payment successful.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/referrals'
                            })}
                    />

                    : null}


                {
                    this.state.referral ?
                        <ReferralContent
                            {...this.props}
                            dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            data={this.state.referral}
                            uData={this.props.uData}
                            enterprise={true}
                            paidControls={this.state.paidControls}
                            screen={'patient-cases'}
                            doctorPage={false}
                            rejectForm={(referral) => {
                                this.setState({ rejectForm: referral })
                            }}
                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form }, () => {
                                    console.log(this.state.previewAnswersModal, this.state.referral)
                                })
                            }}
                            payForTheVisit={() => {
                                if (this.state.referral) {
                                    this.setState({ payNowModal: this.state.referral.bill && this.state.referral.bill.caseObj })

                                }
                            }}
                            formsToAnswer={this.listForms}
                            sendEntireReferralOnCheckForms={true}
                            closeReferralContent={(data) => {
                                if (data) {
                                    this.setState({
                                        referral: null
                                    })
                                }
                            }}
                            hideReject={this.state.referral?.wayOfScheduling === 'webBooking' || this.state.referral?.wayOfScheduling === 'bookingByClinic' || this.state.referral?.status === 'revisit-scheduled' || this.state.referral?.status === 'visit-rescheduled' ? true : false}
                        />
                        :
                        null
                }

                {this.state.referral && false ?
                    <Modal isOpen={this.state.referral} centered toggle={() => this.setState({ referral: !this.state.referral })} size="lg">
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }} onChange={() => { }}></div>
                            {
                                this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length && this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                    this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }}></div>
                                            {/* <hr/> */}
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData }}></div> */}
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id)}}></div> */}
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData }}></div> */}
                                            {/* <Button color='primary' onClick={() => { this.setState({ previewAnswersModal: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] }) }}>{'Preview answers'.translate(this.props.lang)}</Button> */}
                                            <hr />
                                        </div>
                                        :
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'You have to fill out the form'.translate(this.props.lang)}</p>
                                            <hr />
                                        </div>

                                    :
                                    null
                            }
                            {
                                this.state.referral && this.state.referral.answers && this.state.referral.questionaryData && this.state.referral.questionaryData.items ?
                                    <div style={{ marginBottom: 10 }}>
                                        <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.state.referral }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                    </div>
                                    :
                                    null
                            }
                            <div className='patient-referral-preview-status-wrap'>
                                <div>
                                    <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status]}</p>
                                    {this.state.referral.reason ?
                                        <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason}</p>
                                        :
                                        null}
                                    {this.state.referral.patientReservedTimestamp ?
                                        <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat}${!this.state.referral.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.state.referral.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                        :
                                        null}
                                    {
                                        this.state.referral.status == 'patient-requested-another-time' ?
                                            <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                            :
                                            null
                                    }

                                </div>

                                {/* {
                                    this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length && this.state.referral.status == 'revisit-scheduled' ?
                                        <div className='additional-buttons'>
                                            <Button color='primary'
                                                onClick={() => {
                                                    this.setState({ payNowModal: this.state.referral.bill && this.state.referral.bill.caseObj })
                                                }}
                                                disabled={this.state.referral.bill && this.state.referral.bill.caseObj && this.state.referral.bill.caseObj.paymentMethod != 1 && this.state.referral.bill.caseObj.paymentMethod != 2 && this.state.referral.bill.caseObj.questionaryPrice ? false : true}>
                                                {'Pay for the visit'.translate(this.props.lang)}
                                            </Button>

                                            <Button color='primary' onClick={() => { this.setState({ formsToAnswer: this.state.referral.revisitFormsArray }) }}>{'Answer forms'.translate(this.props.lang)}</Button>
                                        </div>
                                        :
                                        null
                                } */}
                                {
                                    this.state.referral.status == 'revisit-scheduled' || this.state.referral.status === 'visit-rescheduled' ?
                                        <div className='additional-buttons'>
                                            <Button color='primary'
                                                onClick={() => {
                                                    this.setState({ payNowModal: this.state.referral.bill && this.state.referral.bill.caseObj })
                                                }}
                                                disabled={(!this.state.paidControls || (this.state.paidControls && !this.state.paidControls.filter(el => el == this.state.referral._id).length)) && (this.state.referral.bill && this.state.referral.bill.caseObj && this.state.referral.bill.caseObj.paymentMethod != 1 && this.state.referral.bill.caseObj.paymentMethod != 2 && this.state.referral.bill.caseObj.questionaryPrice) ? false : true}>
                                                {'Pay for the visit'.translate(this.props.lang)}
                                            </Button>
                                            {
                                                this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length ?
                                                    <Button color='primary' onClick={() => { this.listForms(this.state.referral) }}>{'Answer forms'.translate(this.props.lang)}</Button>

                                                    :
                                                    null
                                            }

                                        </div>
                                        :
                                        null
                                }



                                {
                                    this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length ?
                                        <div className='doctor-preview-revisit-forms'>
                                            <div className='doctor-preview-revisit-forms-header'>
                                                {
                                                    this.state.activeRevisitForm ?
                                                        <span>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.state.referral.revisitFormsArray.length})</span></span>
                                                        :
                                                        <span>{this.state.referral.revisitFormsArray[0].name} <span>(1/{this.state.referral.revisitFormsArray.length})</span></span>
                                                }
                                            </div>
                                            <div className='buttons-next-prev-wrap'>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm > 1) {
                                                        activeRevisitForm--;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Previous'.translate(this.props.lang)}</Button>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.state.referral.revisitFormsArray.length ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm < this.state.referral.revisitFormsArray.length) {
                                                        activeRevisitForm++;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Next'.translate(this.props.lang)}</Button>
                                            </div>
                                        </div>
                                        :
                                        null

                                }
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>

                            {
                                this.state.referral.patientReservedTimestamp && (this.state.referral.status == 'scheduled' || this.state.referral.status === 'visit-rescheduled' || this.state.referral.status == 'clinic-rejected-requested-time' || this.state.referral.status == 'revisit-scheduled') ?
                                    <>
                                        {/*<Button color="success" onClick={() => this.acceptReferral({})}><i className="fa fa-check" /> Accept</Button>*/}
                                        <Button color="danger" className="danger-button" onClick={() => this.setState({ rejectForm: this.state.referral })}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                        {/*<Button color="primary" onClick={() => this.setState({ requestAnotherDateForm: this.state.referral })}><i className="fa fa-undo" /> Request another date </Button>*/}
                                    </>

                                    :
                                    null
                            }
                            <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />
                            <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                        </ModalFooter>

                    </Modal>

                    :
                    null
                }
                {this.state.freeCardError ?
                    <InfoModal
                        isOpen={this.state.freeCardError}
                        onClose={(item) => {
                            this.setState({
                                freeCardError: item
                            })
                        }}
                        close={true}
                        header={'Free card validation error'.translate(this.props.lang)}
                        info={'Your free card will not be valid on the day of the scheduled visit.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ freeCardError: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {
                    this.state.payNowModal ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModal}
                            toggle={() => this.setState({ payNowModal: null, successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang) })}
                            regions={this.state.regions}
                            updatePaymentMethod={(method) => this.updatePaymentControl(method)}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodFreeCard(data)}
                            openPayson={(data) => this.openPayson(data)}
                            openSwish={(data) => this.openSwish(data)}
                            openStripe={(data) => this.openStripe(data)}
                        >
                        </PayNowModal>

                        : null
                }
                {this.state.formsToAnswer ?
                    <Modal isOpen={this.state.formsToAnswer} centered size='lg'>
                        <ModalHeader toggle={() => this.setState({ formsToAnswer: !this.state.formsToAnswer, savedRevisitForms: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                            onClick={() => this.setState({
                                formsToAnswer: !this.state.formsToAnswer,
                                savedRevisitForms: [],
                                // eventID: null,
                                selectedEventAnswers: null
                            })}>&times;</button>}>{'Forms to answer'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            <FormGroup>
                                {this.state.formsToAnswer && this.state.formsToAnswer.length ?
                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hideFilters={true}
                                        hidePagination
                                        hideEntries
                                        actionLabel={'Action'.translate(this.props.lang)}
                                        fields={
                                            [
                                                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: false },
                                            ]
                                        }
                                        rawItems={this.state.formsToAnswer}
                                        items={this.state.formsToAnswer.map((item, idx) => {
                                            return {
                                                ...item,
                                            }
                                        })}

                                        actions={
                                            [

                                                {
                                                    component: <Button color="primary" className="tabel-action-tooltip">{'Continue'.translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        if (!item) return;

                                                        let _data = {};

                                                        const savedForm = this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(item.revisitQuestionaryId));

                                                        if (savedForm && savedForm.state) {
                                                            let states = JSON.parse(JSON.stringify(savedForm.state))
                                                            let data = {}
                                                            if (states.skippedQuestions) {
                                                                data.skippedQuestions = states.skippedQuestions;
                                                                delete states.skippedQuestions
                                                            }
                                                            if (states.answers) {
                                                                data.answers = states.answers;
                                                                delete states.answers
                                                            }
                                                            if (states.nextButtonClicked) {
                                                                data.nextButtonClicked = states.nextButtonClicked;
                                                                delete states.nextButtonClicked
                                                            }
                                                            if (states.questionsQueue) {
                                                                data.questionsQueue = states.questionsQueue;
                                                                delete states.questionsQueue
                                                            }
                                                            if (states.currentQuestion) {
                                                                data.currentQuestion = states.currentQuestion;
                                                                delete states.currentQuestion
                                                            }
                                                            if (states.backAnswers) {
                                                                data.backAnswers = states.backAnswers;
                                                                delete states.backAnswers
                                                            }
                                                            _data = data;
                                                            this.setState({ resumeForm: savedForm._id })
                                                        }

                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }

                                                        this.setState({
                                                            answerRevisitForm: { ...item, groupAlias: groupAlias, referralId: this.state.referral?._id, _data: _data && typeof _data === 'object' && Object.keys(_data)?.length > 0 ? _data : null }
                                                        });

                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            if (form.revisitQuestionaryId && this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(form.revisitQuestionaryId))) {
                                                                const savedForm = this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(form.revisitQuestionaryId));
                                                                if (!savedForm || savedForm.questionaryNotAcitve || savedForm.questionaryNotExists || savedForm.questionaryModified) {
                                                                    return false
                                                                }
                                                                return true;
                                                            }
                                                            return false;
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },

                                                {
                                                    component: <Button color="primary" className="tabel-action-tooltip">{'Answer the form'.translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }
                                                        this.setState({
                                                            answerRevisitForm: { ...item, groupAlias: groupAlias, referralId: this.state.referral?._id }
                                                        })
                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },
                                                {
                                                    component: <Button style={{ backgroundColor: 'green' }} className="tabel-action-tooltip">{"See my answers".translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }
                                                        this.setState({
                                                            answerRevisitForm: { ...item, disabledForm: true, groupAlias: groupAlias }
                                                        }, () => {
                                                            if (item.answers) {
                                                                this.setState({
                                                                    questionaryAnswers: { ...item.answers }
                                                                })
                                                            }

                                                        })
                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            return false
                                                        } else {
                                                            return true
                                                        }
                                                    }
                                                },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :
                                    <strong>{"No forms to answer on selected day".translate(this.props.lang)}</strong>
                                }
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={() => this.setState({
                                formsToAnswer: !this.state.formsToAnswer,
                                savedRevisitForms: [],
                                // eventID: null,
                                selectedEventAnswers: null
                            })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.answerRevisitForm ?
                        <Modal isOpen={this.state.answerRevisitForm} centered size={'lg'}>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                                onClick={() => {
                                    if (this.state.answerRevisitForm && this.state.answerRevisitForm.answers) {
                                        if (JSON.stringify(this.state.answerRevisitForm.answers) != JSON.stringify(this.state.questionaryAnswers)) {
                                            this.setState({
                                                errorFormModal: 'Your data will not be saved.'
                                            })
                                        } else {
                                            this.setState({
                                                questionaryAnswers: {},
                                                answerRevisitForm: null,
                                                resumeForm: null,
                                                referralData: null,
                                                referralTotalData: null
                                            })
                                        }

                                    } else {
                                        this.setState({
                                            errorFormModal: 'Your data will not be saved.'
                                        })
                                    }


                                }}><Isvg src={xIcon} /></button>}>
                                <p>{this.state.answerRevisitForm.name}</p>
                                {/* <p style={{ fontSize: 16, fontWeight: 500 }}>{this.state.answerRevisitForm.description}</p> */}
                            </ModalHeader>

                            <ModalBody className="run-modal">
                                <AnswerRevisitForm
                                    {...this.props}
                                    _savedRevisitForm={this.state.answerRevisitForm?._data || null}
                                    sections={this.generateSections(this.state.answerRevisitForm.items)}
                                    intelligentReferral={this.state.answerRevisitForm.intelligentReferral}
                                    saveForm={this.state.answerRevisitForm.saveForm && !this.state.answerRevisitForm.finishedForm && !this.state.answerRevisitForm.disabledForm}
                                    form={this.state.answerRevisitForm}
                                    closeForm={() => {
                                        this.setState({
                                            questionaryAnswers: {},
                                            answerRevisitForm: null,
                                            resumeForm: null,
                                            referralData: null,
                                            referralTotalData: null,
                                            formsToAnswer: null,
                                            savedRevisitForms: [],
                                        })
                                    }}
                                    setResumeForm={(id, callback) => {
                                        this.setState({ resumeForm: id }, () => {
                                            if (callback) { callback() }
                                        })
                                    }}
                                    alias={this.state.answerRevisitForm.groupAlias ? this.state.answerRevisitForm.groupAlias : null}
                                    referralId={this.state.answerRevisitForm.referralId ? this.state.answerRevisitForm.referralId : null}
                                    answer={(item, callback) => {
                                        this.setState({ questionaryAnswers: item }, () => {
                                            if (callback) {
                                                callback()
                                            }
                                        })
                                    }}
                                    generateAnswers={(html, callback) => this.setState({ referralData: html }, () => {

                                        if (callback) {
                                            callback()
                                        }
                                    })}
                                    disabledForm={this.state.answerRevisitForm.disabledForm}
                                    answers={this.state.questionaryAnswers}
                                    saveAnswers={() => {

                                        if (this.state.answerRevisitForm && this.state.answerRevisitForm.revisitQuestionaryId && this.state.referral && this.state.referral._id && this.state.questionaryAnswers) {
                                            fetch(API_ENDPOINT + '/referrals/patient/answer/revisit/questionary', {
                                                method: 'POST',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    revisitQuestionaryId: this.state.answerRevisitForm.revisitQuestionaryId,
                                                    answers: this.state.questionaryAnswers,
                                                    referralId: this.state.referral._id,
                                                    referralData: this.state.referralData,
                                                    resumeForm: this.state.resumeForm
                                                    // referralTotalData: this.state.referralTotalData
                                                })
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (status >= 200 && status < 300) {
                                                    this.setState({
                                                        questionaryAnswers: {},
                                                        answerRevisitForm: null,
                                                        resumeForm: null,
                                                        referralData: null,
                                                        // referralTotalData: null,
                                                        formsToAnswer: null,
                                                        savedRevisitForms: []
                                                    }, () => this.get())
                                                }

                                            })
                                        }

                                    }}
                                />
                                {/* <AnswerRevisitForm
                                    uData={this.props.uData}
                                    lang={this.props.lang}
                                    sections={this.generateSections(this.state.answerRevisitForm.items)}
                                    intelligentReferral={this.state.answerRevisitForm.intelligentReferral}
                                    alias={this.state.answerRevisitForm.groupAlias ? this.state.answerRevisitForm.groupAlias : null}
                                    answer={(item) => {
                                        this.setState({ questionaryAnswers: item })
                                    }}
                                    generateAnswers={(html, callback) => this.setState({ referralData: html }, () => {

                                        if (callback) {
                                            callback()
                                        }
                                    })}
                                    generateTotals={(html, callback) => this.setState({ referralTotalData: html }, () => {
                                        if (callback) {
                                            callback()
                                        }
                                    })}
                                    // date={this.state.scheduleModal.date}
                                    // selectedEventAnswers={this.state.selectedEventAnswers}
                                    answerRevisitForm={this.state.answerRevisitForm}
                                    disabledForm={this.state.answerRevisitForm.disabledForm}
                                    answers={this.state.questionaryAnswers}
                                    // days={this.state.days}
                                    saveAnswers={() => {

                                        if (this.state.answerRevisitForm && this.state.answerRevisitForm.revisitQuestionaryId && this.state.referral && this.state.referral._id && this.state.questionaryAnswers) {
                                            fetch(API_ENDPOINT + '/referrals/patient/answer/revisit/questionary', {
                                                method: 'POST',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    revisitQuestionaryId: this.state.answerRevisitForm.revisitQuestionaryId,
                                                    answers: this.state.questionaryAnswers,
                                                    referralId: this.state.referral._id,
                                                    referralData: this.state.referralData,
                                                    referralTotalData: this.state.referralTotalData
                                                })
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (status >= 200 && status < 300) {
                                                    this.setState({
                                                        questionaryAnswers: {},
                                                        answerRevisitForm: null,
                                                        referralData: null,
                                                        referralTotalData: null,
                                                        formsToAnswer: null
                                                        // selectedEventAnswers: null
                                                    }, () => this.get())
                                                }

                                            })
                                        }

                                    }}
                                /> */}
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.swishModalControl ?
                        <Swish
                            {...this.props}
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            open={this.state.swishModalControl}
                            openModal={(item) => {
                                // console.log(this.props.referralStatusBefore)
                                console.log(item)
                                this.setState({
                                    swishModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null

                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        // this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.swishModalControl.refData && this.state.swishModalControl.price ? this.state.swishModalControl.price : this.state.swishModalControl.refData && this.state.swishModalControl.refData.questionaryPrice ? this.state.swishModalControl.refData.questionaryPrice : this.state.swishModalControl.questionaryPrice}
                            phone={this.props.uData.phone}
                            referralNumber={this.state.swishModalControl.controlNumber ? this.state.swishModalControl.controlNumber : null}
                            referral={this.state.swishModalControl._id}
                            doctor={this.state.swishModalControl.doctor ? this.state.swishModalControl.doctor : null}
                            patient={this.props.uData._id}
                            callbackFunction={() => this.callbackAfterPaying()}
                        // data={this.state.swishModalControl.refData && this.state.swishModalControl.refData.data ? this.state.swishModalControl.refData.data : null}

                        />


                        : null
                }
                {
                    this.state.paysonModalControl ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({
                                    paysonModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.paysonModalControl.refData && this.state.paysonModalControl.price ? this.state.paysonModalControl.price : this.state.paysonModalControl.refData && this.state.paysonModalControl.refData.questionaryPrice ? this.state.paysonModalControl.refData.questionaryPrice : this.state.paysonModalControl.questionaryPrice}

                            referral={this.state.paysonModalControl._id}
                            doctor={this.state.paysonModalControl.doctor ? this.state.paysonModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyCases={true}
                            referralPreviewObj={{
                                referralId: this.state.referral ? this.state.referral._id : null,
                                page: params.page
                            }}
                        />


                        : null
                }
                {
                    this.state.stripeModalControl ?

                        <Stripe
                            {...this.props}
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({
                                    stripeModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.stripeModalControl.refData && this.state.stripeModalControl.price ? this.state.stripeModalControl.price : this.state.stripeModalControl.refData && this.state.stripeModalControl.refData.questionaryPrice ? this.state.stripeModalControl.refData.questionaryPrice : this.state.stripeModalControl.questionaryPrice}

                            referral={this.state.stripeModalControl._id}
                            doctor={this.state.stripeModalControl.doctor ? this.state.stripeModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyCases={true}
                            referralPreviewObj={{
                                referralId: this.state.referral ? this.state.referral._id : null,
                                page: params.page
                            }}
                        />


                        : null
                }
                {
                    this.state.errorFormModal ?
                        <Modal isOpen={this.state.errorFormModal} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">


                                {/* <img src={warrning} /> */}
                                <div className="message">
                                    {
                                        this.state.errorFormModal.translate(this.props.lang)
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>

                                <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({
                                    questionaryAnswers: {},
                                    answerRevisitForm: null,
                                    resumeForm: null,
                                    referralData: null,
                                    errorFormModal: null,
                                    referralTotalData: null,
                                    // selectedEventAnswers: null,
                                })} >{'Close'.translate(this.props.lang)}</Button>

                                <Button style={{ marginRight: 5, minWidth: 100 }} color="primary" onClick={() => this.setState({
                                    errorFormModal: null,
                                })} >{'Back'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {this.state.successPayModal ?
                    <Modal isOpen={this.state.successPayModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {
                                    this.state.successPayModal == 'Free Card payment successful.' ?
                                        'Free Card payment successful.'.translate(this.props.lang)
                                        :
                                        this.state.successPayModal == 'free' ?
                                            'Based on your age, you are entitled to a free visit.'.translate(this.props.lang)
                                            :
                                            this.state.successPayModal.translate(this.props.lang)
                                    // 'Your referral has been sent.'.translate(this.props.lang)
                                }
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successPayModal: null }, () => {
                                if (this.state.successLink) {
                                    this.props[0].history.push(this.state.successLink)
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.successModal ?
                    <Modal isOpen={this.state.successModal} centered zIndex={99999}>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {this.state.successModal}
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModal: null })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

                {
                    this.state.rejectForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectReferral(data)}
                            isOpen={this.state.rejectForm}
                            toggle={() => this.setState({ rejectForm: null })}
                            title={'Reject shceduled time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reject reason'.translate(this.props.lang),
                                                    validate: [required('Reject reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.requestAnotherDateForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.requestAnotherDate(data)}
                            isOpen={this.state.requestAnotherDateForm}
                            toggle={() => this.setState({ requestAnotherDateForm: null })}
                            title={'Request another date'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} centered toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} size="xl">
                            <ModalHeader>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {

                                    this.forward(clinic);
                                    /*let addressLines = [];
                                    if (clinic.postAddress) {
                                        addressLines = this.splitAddress(clinic.postAddress);
                                    } else {
                                        addressLines = [clinic.street, clinic.zip + ' ' + clinic.city, ''];
                                    }
                
                                    this.setState({
                                        recipientData: {
                                            ...clinic,
                                            name: clinic.vantetiderName ? clinic.vantetiderName : clinic.name,
                                            address1: addressLines[0],
                                            address2: addressLines[1],
                                            address3: addressLines[2]
                                        },
                                        selectClinicModal: null
                                    })*/
                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral, items: this.state.previewAnswersReferralModal.questionaryData?.items }} sections={this.generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ _id: this.state.previewAnswersModal._id, name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items }} sections={this.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true} hideTotalAndSubtotal={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Referrals));