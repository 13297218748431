import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import ListBuilder from '../../components/listBuilder';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import viewIcon from '../../assets/svg/eye.svg';
import xIcon from '../../assets/svg/x.svg';
import garbageIcon from '../../assets/svg/garbage.svg';
import walletIcon from '../../assets/svg/wallet.svg';
import warrning from '../../assets/svg/warrning.svg';
import attachInvoice from '../../assets/svg/attach.svg'
import Payson from '../../components/payson';
import Swish from '../swish';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import PdfSave from '../../components/pdvSave';
import InfoModal from '../../components/infoModal';
import striptags from 'striptags';
import { Player } from "@lottiefiles/react-lottie-player";
import moment from 'moment';
import downloadIcon from '../../assets/svg/download.svg';
import deleteDocument from '../../assets/svg/delete-document.svg'

import billGrey from '../../assets/svg/bill_grey.svg';
import infoIcon from "../../assets/svg/link-new-tab.svg";
import DatePicker from '../../components/forms/fields/datePicker';
import Checkbox from '../../components/forms/fields/checkbox';
import DeleteModal from '../../components/deleteModal';

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Payments page
* @author   Stefan Pantic
*/
class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            itemsInvoice: [],
            groups: [],
            total: 0,
            totalInvoice: 0,
            loading: true,
            changeButton: false,
            paymentMethod: 0,
            tabIndex: 1,
            deleteInvoice: null,
            editPaymentUnpaid: null,
            editPaymentUnpaidText: '',
            editPaymentPaid: null,
            editPaymentPaidText: '',
            notesPaymentUnpaid: null,
            notesPaymentPaid: null,
            notesPaymentText: '',
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1,
                alias: localStorage.getItem('groupAlias'),
                clinic: this.props.selectedClinic,
                group: this.props.selectedGroup,
                columnVisibility: this.state.tabIndex === 1 ? 'payments-unpaid' : this.state.tabIndex === 2 ? 'payments-paid' : null

            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _documentUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup);
                formData.append('fileType', 'invoices')
                fetch(API_ENDPOINT + '/data/upload-document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then(parseText).then(({ result, status }) => {
                    if (status != 200) {
                        this.setState({ error: result.translate(this.props.lang) })
                    } else {
                        this.setState({
                            choosenFile: result,
                            _documentUploading: null
                        })
                    }
                })
            }
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        if (params.filter) {
            this.setState({
                updateFilterField: new Date().getTime()
            })
        }

        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }

    }
    registerEvents = async () => {

        this.props.socket.on('newPayment', (data) => {
            this.get();
        })




    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.props.socket) {
            this.props.socket.removeListener('newPayment');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 },
                    [
                        {
                            name: 'sortField', value: field
                        }
                        ,
                        {
                            name: 'sortType', value: type
                        }
                    ],
                    false));

        }
    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }

    /**
    * Call api that generate invoice when referral is paid
    * @author   Stefan Pantic
    * @Objectparam    {String} id                       referral id
    * @Objectparam    {String} transaction              transaction id
    * @Objectparam    {String} alias                    group alias
    * @Objectparam    {String} group                    group id
    * @Objectparam    {String} paymentMethodReference   payment method
    */
    generateInvoice = (refData) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/generate-invoice/' + refData._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ type: refData.type, transaction: this.state.reference, alias: localStorage.getItem('groupAlias'), group: localStorage.getItem('group'), paymentMethodReference: refData.paymentMethod })
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, referral: null, reference: '', paymentMethod: null })
                this.get()
            })
        })

    }

    updatePaymentMethod = (data, id, paymentMethod) => {

        if (id == null) {
            let dataObj = {
                paymentMethod: paymentMethod,
            }

            if (paymentMethod === 2) {
                dataObj.requestInvoice = true;
            }

            fetch(API_ENDPOINT + '/referrals/referrals/update/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(dataObj)
            }).then(res => res.json()).then((result) => {
                if (result && paymentMethod === 1) {
                    this.generateInvoice(data)
                } else {
                    this.sendInvoice(data)
                }



            })
        } else {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ _id: id, paymentMethod: paymentMethod })
                }).then(res => res.json()).then((result) => {
                    this.setState({ loading: false })
                    if (paymentMethod === 1) {
                        data.paymentMethod = paymentMethod;
                        data.type = 'revisit';
                        this.generateInvoice(data)
                    } else if (paymentMethod === 2) {
                        this.sendInvoice(data)
                    }

                })
            })
        }

    }

    deletePayment = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/invoice/delete/' + data, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, deletePayment: null })
                this.get()
            })
        })

    }


    deleteUnpaidReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/unpaid/delete/' + data._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, deleteUnpaid: null })
                this.get()
            })
        })
    }
    sendInvoice = (item) => {
        let obj = {
            attachedInvoice: this.state.choosenFile,
        }
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/referrals/send/invoice/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                this.setState({ choosenFile: null, attachInvoiceModal: null }, () => {
                    this.get()
                    this.props.verifyUser()
                })

            })
        })
    }

    deleteSelectedInvoice = (item) => {
        if (!item) return;
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/referrals/delete/invoice/' + item._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then(dta => {
                console.log('DELETE INVOICE DATA:', dta);
                this.setState({ loading: false, deleteInvoice: null }, () => {
                    this.get()
                    this.props.verifyUser()
                })
            })
        })
    }


    // Functions for updating payments - manually register new payment and add notes
    updateUnpaidCase = (caseItem, note) => {
        if (!caseItem) return;
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/referrals/transfer-payment/unpaid-to-paid', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: this.props.selectedGroup,
                    clinicId: this.props.selectedClinic,
                    caseId: caseItem._id,
                    type: caseItem.type,
                    note,
                })
            }).then(res => res.json()).then((result) => {
                if (result && result.error) {
                    this.setState({ loading: false, editPaymentUnpaid: null, editPaymentUnpaidText: '', error: result.error });
                    this.get();
                } else if (result && result.success) {
                    this.setState({ loading: false, editPaymentUnpaid: null, editPaymentUnpaidText: '' });
                    this.get();
                }
            })

        });
    }

    updatePaidCase = (caseItem, note) => {
        if (!caseItem) return;
        console.log('case item: ', caseItem);
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/referrals/transfer-payment/paid-to-unpaid', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    invoiceId: caseItem._id,
                    groupId: this.props.selectedGroup,
                    clinicId: this.props.selectedClinic,
                    note,
                })
            }).then(res => res.json()).then((result) => {
                if (result && result.error) {
                    this.setState({ loading: false, editPaymentPaid: null, editPaymentPaidText: '', error: result.error });
                    this.get();
                } else if (result && result.success) {
                    this.setState({ loading: false, editPaymentPaid: null, editPaymentPaidText: '' });
                    this.get();
                }
            })

        });
    }

    delete = (data) => {
        fetch(API_ENDPOINT + '/bills/data/delete/' + data.paymentCanceldStatus, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            this.setState({
                deleteBill: null
            })
            this.get();


        })
    }

    addNewNote = (type, caseItem, note) => {
        if (!caseItem || !type || !note || note === '' || note.trim() === '') return;
        let caseId;
        if (type === 'unpaid') caseId = caseItem._id;
        else caseId = caseItem.referralId;
        fetch(API_ENDPOINT + '/referrals/transfer-payment/new-note', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                caseId,
                note,
                type,
            })
        }).then(res => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({ loading: false, notesPaymentPaid: null, notesPaymentUnpaid: null, notesPaymentText: '', error: result.error });
                this.get();
            } else if (result && result.success) {
                this.setState({ loading: false, notesPaymentPaid: null, notesPaymentUnpaid: null, notesPaymentText: '' });
                this.get();
            }
        })
    }
    exportData = () => {
        let data = { ...this.state.exportModal, clinic: this.props.selectedClinic, lang: this.props.lang }

        this.setState({ loadingExport: true }, () => {
            fetch(API_ENDPOINT + '/bills/data/export', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (!result?.error) {
                    this.setState({ loadingExport: false, exportModal: null })
                    if (result && result.success && result.file) {
                        window.location.href = API_ENDPOINT + `${result.file}`;
                    }
                }
            })
        })
    }
    render() {

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }

        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let canTransferPayments = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].canTransferPayments;
        let exportPaymentData = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].exportPaymentData;

        let unpaidFields = [];
        if (canTransferPayments) {
            unpaidFields = [
                { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'recpientClinicName', label: 'Recipient'.translate(this.props.lang), allowSort: false },
                { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang) },
                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'vatPriceString', label: 'Varav moms'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'caseObj.patientReservedTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'tsCreated', label: 'Created'.translate(this.props.lang), allowSort: true },
            ]
        } else {
            unpaidFields = [
                { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'recpientClinicName', label: 'Recipient'.translate(this.props.lang), allowSort: false },
                { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang) },
                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'caseObj.patientReservedTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'tsCreated', label: 'Created'.translate(this.props.lang), allowSort: true },
            ]
        }
        let paidFields = [];

        if (canTransferPayments) {
            paidFields = [
                { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'clinicInfo', label: 'Recipient'.translate(this.props.lang), allowSort: false },
                { type: 'text', name: 'patientInfo', label: 'Patient'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'invoiceNumber', label: 'Reference'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'vatPriceString', label: 'Varav moms'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'visitTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'tsCreated', label: 'Payment date'.translate(this.props.lang), allowSort: true },
                // { type: 'text', name: 'tsCreated', label: 'Created'.translate(this.props.lang), allowSort: true },

            ]
        } else {
            paidFields = [
                { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'clinicInfo', label: 'Recipient'.translate(this.props.lang), allowSort: false },
                { type: 'text', name: 'patientInfo', label: 'Patient'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'invoiceNumber', label: 'Reference'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'visitTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'tsCreated', label: 'Payment date'.translate(this.props.lang), allowSort: true },
                // { type: 'text', name: 'tsCreated', label: 'Created'.translate(this.props.lang), allowSort: true },

            ]
        }

        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                {
                    this.state.loadingExport ?
                        <div className="export-loader-wrap">
                            <div className="loader-wrap">
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    renderer={'svg'}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                ></Player>

                            </div>
                        </div>
                        :
                        null
                }
                {
                    this.state.exportModal ?
                        <Modal centered isOpen={this.state.exportModal}>
                            <ModalHeader style={{ margin: 'auto' }}>{'Export'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody >

                                <FormGroup>
                                    <DatePicker
                                        label={'Date from'.translate(this.props.lang)}
                                        value={this.state.exportModal.startDate}
                                        // minDate={new Date()}
                                        dateFormat={this.props.dateFormat}
                                        maxDate={new Date(this.state.exportModal.endDate)}
                                        onChange={(e) => {
                                            let exportModal = this.state.exportModal;
                                            exportModal.startDate = e;

                                            this.setState({ exportModal });
                                        }} />
                                </FormGroup>
                                <FormGroup>
                                    <DatePicker
                                        label={'Date to'.translate(this.props.lang)}
                                        value={this.state.exportModal.endDate}
                                        // minDate={new Date()}
                                        dateFormat={this.props.dateFormat}
                                        onChange={(e) => {
                                            let exportModal = this.state.exportModal;
                                            exportModal.endDate = e;

                                            this.setState({ exportModal });
                                        }} />
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let exportModal = this.state.exportModal;
                                            exportModal.unpaid = e.target.checked;
                                            this.setState({ exportModal });
                                        }}
                                        checked={this.state.exportModal.unpaid}
                                        label={"Unpaid".translate(this.props.lang)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let exportModal = this.state.exportModal;
                                            exportModal.paid = e.target.checked;
                                            this.setState({ exportModal });
                                        }}
                                        checked={this.state.exportModal.paid}
                                        label={"Paid".translate(this.props.lang)}
                                    />
                                </FormGroup>



                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button disabled={(!this.state.exportModal.unpaid && !this.state.exportModal.paid) || this.state.loadingExport ? true : false} color="primary" style={{ margin: 10 }} onClick={() => {
                                    this.exportData()
                                }}>{'Export'.translate(this.props.lang)}</Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ exportModal: null })

                                }>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                <Container fluid>
                    <Row>
                        <div className='panel'>

                            <div className="panel-header">
                                <h5 className="component-header">{'Payments'.translate(this.props.lang)}</h5>
                                {
                                    exportPaymentData && canTransferPayments ?
                                        <Button color='primary' onClick={() => {
                                            let today = new Date();
                                            let monthAgo = new Date();
                                            monthAgo.setMonth(monthAgo.getMonth() - 1);
                                            console.log('test', monthAgo);
                                            console.log('startDAte', moment.unix(monthAgo.getTime() / 1000).format(this.props.dateFormat));
                                            console.log('format', this.props.dateFormat);
                                            let exportModal = {
                                                startDate: moment.unix(monthAgo.getTime() / 1000).format(this.props.dateFormat),
                                                endDate: moment.unix(today.getTime() / 1000).format(this.props.dateFormat),
                                                paid: false,
                                                unpaid: false
                                            }
                                            this.setState({ exportModal })
                                        }}>{'Export'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }

                            </div>

                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 }, () => {
                                        this.updateParams('filter', params.filter)
                                        this.setState({
                                            updateFilterField: new Date().getTime()
                                        })
                                    })}>{"Unpaid".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 }, () => {
                                        this.updateParams('filter', params.filter)
                                        this.setState({
                                            updateFilterField: new Date().getTime()
                                        })
                                    })}>{"Paid cases".translate(this.props.lang)}</Button>
                                </div>
                            </Col>
                            {/* UNPAID */}
                            {this.state.tabIndex == 1 ?

                                <Col lg="12" className='col-no-padding'>
                                    {this.state.items.length > 0 ? <p></p> :
                                        <p> {'Do not have unpaid referrals'.translate(this.props.lang)}</p>}
                                    <h5 className="component-header">{'Unpaid'.translate(this.props.lang)}</h5>
                                    {

                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            updateFilterField={this.state.updateFilterField}
                                            columnVisibility={'payments-unpaid'} //columnVisibility
                                            uData={this.props.uData} //columnVisibility
                                            updateListBuilderFields={this.props.updateListBuilderFields} //columnVisibility
                                            get={this.get}//columnVisibility
                                            fields={unpaidFields}
                                            rawItems={this.state.items}
                                            items={this.state.items.map((item => {
                                                return {
                                                    ...item,
                                                    version: item.patient ? item.patient.referralNumber + (item.version != 0 ? '.' + item.version : '') : item.controlNumber ? `${item.controlNumber}` : '',
                                                    recpientClinicName: <>{item.recpientClinicName ? item.recpientClinicName : ''}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName ? item.recpientClinicSubgroupName : ''} </span><br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span>  </>,
                                                    patient: <>{item.patient ? item.patient.name : item.patientName ? item.patientName : ''}<br /><span className="clinic-subgroup-name">{item.patient ? item.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : ''} </span> </>,
                                                    questionaryName: this.props.lang == 'en' ? item.questionaryName ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.visitReasonName ? item.visitReasonName : '' : item.questionaryName ? item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)') : item.visitReasonName ? item.visitReasonName : '',
                                                    price: item.questionaryPrice + " " + currency,
                                                    vatPriceString: item.vatPriceString && item.questionaryPrice && Number(item.questionaryPrice) > 0 && item.vatPriceString !== 'N/A' ? item.vatPriceString : '0.00 ' + currency,
                                                    methodPayment:
                                                        item.paymentCanceldStatus ? <p style={{ color: 'red' }}>{"Visit has been cancelled".translate(this.props.lang)}</p> :
                                                            <div className='payments-unpaid-payment-method'>
                                                                {(item.type == 'revisit' && item.priceList && item.priceList.paymentOnSite && !item.priceList.onlinePayment && !item.priceList.invoice && !item.priceList.freeCard) || ((item.type !== 'revisit' && item.questionaryData && item.questionaryData.paymentOnSite && !item.questionaryData.onlinePayment && !item.questionaryData.invoice && !item.questionaryData.freeCard)) ? 'On site'.translate(this.props.lang) : !item.paymentMethod && (item.type == 'revisit' && item.priceList && !item.priceList.paymentOnSite && !item.priceList.onlinePayment && item.priceList.invoice && !item.priceList.freeCard) || (item.type !== 'revisit' && item.questionaryData && !item.questionaryData.paymentOnSite && !item.questionaryData.onlinePayment && item.questionaryData.invoice && !item.questionaryData.freeCard) ? 'Invoice'.translate(this.props.lang) : item.type == 'revisit' && item.paymentMethod ?
                                                                    item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                        'On site'.translate(this.props.lang) : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ?
                                                                            'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ?
                                                                                'Online'.translate(this.props.lang) : ''
                                                                    : item.questionaryPrice == 0 ? 'Free of charge'.translate(this.props.lang) : item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ? 'Online'.translate(this.props.lang)
                                                                        : item.freePayment == 1 ? 'Free card'.translate(this.props.lang)
                                                                            : item.freePayment == 2 ? 'Under 23'.translate(this.props.lang)
                                                                                : item.freePayment == 3 ? 'Under 20'.translate(this.props.lang)
                                                                                    : item.freePayment == 4 ? 'Under 18'.translate(this.props.lang)
                                                                                        : item.freePayment == 5 ? 'Older than 85'.translate(this.props.lang)
                                                                                            : item.freePayment == 6 ? 'Under 18 (child)'.translate(this.props.lang)
                                                                                                :
                                                                                                null}
                                                                <p>
                                                                    {item.paymentMethod ?
                                                                        item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                            'Pending payment'.translate(this.props.lang)
                                                                            :
                                                                            item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && !item.attachedInvoice ?
                                                                                'Invoice requested'.translate(this.props.lang)
                                                                                :
                                                                                item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && item.attachedInvoice ?
                                                                                    'Pending payment'.translate(this.props.lang)
                                                                                    :
                                                                                    ''
                                                                        :
                                                                        null}
                                                                </p>
                                                            </div>,
                                                    status: item.paymentMethod ?
                                                        item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                            'Pending payment'.translate(this.props.lang)
                                                            :
                                                            item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && !item.attachedInvoice ?
                                                                'Invoice requested'.translate(this.props.lang)
                                                                :
                                                                item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && item.attachedInvoice ?
                                                                    'Pending payment'.translate(this.props.lang)
                                                                    :
                                                                    ''
                                                        :
                                                        null,
                                                    tsCreated: this.props.getStringDateTs(item.tsCreated, this.props.dateFormat)/*moment.unix(item.tsCreated).format(this.props.dateFormat)*/,
                                                    'caseObj.patientReservedTimestamp': item.patientReservedTimestamp ? this.props.getStringDateTs(item.patientReservedTimestamp, this.props.dateFormat + ' HH:mm')/*moment.unix(item.patientReservedTimestamp).format(this.props.dateFormat + ' HH:mm')*/ : ''
                                                    // item.type == 'revisit' && item.startTime ?
                                                    //     moment.unix(item.startTime).format(this.props.dateFormat + ' HH:mm')
                                                    //     :
                                                    //     item.wayOfScheduling == 'webBooking' && item.patientReservedTimestamp ?
                                                    //         moment.unix(item.patientReservedTimestamp).format(this.props.dateFormat + ' HH:mm')
                                                    //         :
                                                    //         item.wayOfScheduling == 'bookingByClinic' && item.patientReservedTimestamp ?
                                                    //             moment.unix(item.patientReservedTimestamp).format(this.props.dateFormat + ' HH:mm')
                                                    //             :
                                                    //             ''
                                                }
                                            }))}
                                            actions={
                                                [
                                                    {

                                                        component: <div className="tabel-action-tooltip"><Isvg src={walletIcon} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Register payment'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ referral: item }),
                                                        condition: (item) => {
                                                            // if (item.doctorPermission) {
                                                            //     return false
                                                            // } else
                                                            if ((item.paymentMethod == 1 || item.paymentMethod == 2) && item.status == 'Pending payment'.translate(this.props.lang)) {
                                                                return true
                                                            } else if (!item.paymentMethod && ((item.type == 'revisit' && item.priceList && item.priceList.paymentOnSite && !item.priceList.onlinePayment && !item.priceList.invoice && !item.priceList.freeCard) || (item.type !== 'revisit' && item.questionaryData && item.questionaryData.paymentOnSite && !item.questionaryData.onlinePayment && !item.questionaryData.invoice && !item.questionaryData.freeCard))) {
                                                                return true
                                                            }
                                                        }
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={deleteDocument} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Delete payment'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => {
                                                            this.setState({
                                                                deleteBill: item
                                                            })
                                                        },
                                                        condition: (item) => {
                                                            if (item.paymentCanceldStatus) {
                                                                return true
                                                            }
                                                        }
                                                    },
                                                    {

                                                        component: <div className="tabel-action-tooltip"><Isvg src={downloadIcon} height={48} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Download invoice'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => {
                                                            if (typeof window != 'undefined') {

                                                                window.open(API_ENDPOINT + item.attachedInvoice + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&rid=${item._id}`, '_blank')
                                                            }
                                                        },
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else if ((item.paymentMethod == 1 || item.paymentMethod == 2) && item.status == 'Pending payment'.translate(this.props.lang) && item.attachedInvoice) {
                                                                return true
                                                            }
                                                        }
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={billGrey} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ editPaymentUnpaid: item }),
                                                        condition: (item) => {
                                                            if (item && this.state.canTransferPayments) return true;
                                                            else return false;
                                                        }
                                                    },
                                                    {
                                                        renderComponent: item => <div className="tabel-action-tooltip"><Isvg src={infoIcon} className={item && item.notes && item.notes.length ? 'wallet-icon wallet-icon-green' : "wallet-icon"} /> <div className="chat-icons-tooltip">
                                                            <span>{item && item.notes && item.notes.length ? 'Notes available'.translate(this.props.lang) /*<ul style={{listStyle: 'none', textAlign: 'left', padding: 5}}>{item.notes.map((note, i) => {
                                                                let name;
                                                                note.username ? (name = note.username) : (name = note.name);
                                                                let date;
                                                                if (note.timestamp) {
                                                                    try {
                                                                        let dateObj = new Date(note.timestamp);
                                                                        console.log(dateObj);
                                                                        if (dateObj) {
                                                                            date = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
                                                                        }
                                                                    } catch(err) {
                                                                        console.log('date err: ', err);
                                                                    }
                                                                    
                                                                }
                                                                let message;
                                                                switch (note.action) {
                                                                    case 'unpaid-to-paid': message = 'marked this case as paid on'.translate(this.props.lang); break;
                                                                    case 'paid-to-unpaid': message = 'marked this case as unpaid on'.translate(this.props.lang); break;
                                                                    case 'new-note': message = 'added a new note on'.translate(this.props.lang)
                                                                }

                                                                let addedNote;
                                                                if (note.note && note.note !== '' && note.note.trim() !== '') {
                                                                    if (note.action === 'new-note') {
                                                                        addedNote = ': ' + `"${note.note}"`
                                                                    } else {
                                                                        addedNote = ', ' + 'with note'.translate(this.props.lang) + ': ' + `"${note.note}"`;
                                                                    }
                                                                }
                                                                let string;
                                                                if (name && message && date) {
                                                                    string = name + ' ' + message + ' ' + date;
                                                                    if (addedNote) string += addedNote;
                                                                }
                                                                return <li key={i}>{string ? string + '.' : 'Note'.translate(this.props.lang)}</li>
                                                            })}</ul>*/ : 'Note'.translate(this.props.lang)}</span>
                                                        </div> </div>,
                                                        onClick: (item) => this.setState({ notesPaymentUnpaid: item }),
                                                        condition: (item) => {
                                                            if (item && this.state.canTransferPayments) return true;
                                                            else return false;
                                                        }
                                                    },
                                                    {

                                                        component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Delete invoice'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ deleteInvoice: item }),
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else if ((item.paymentMethod == 1 || item.paymentMethod == 2) && item.status == 'Pending payment'.translate(this.props.lang) && item.attachedInvoice) {
                                                                return true
                                                            }
                                                        }
                                                    },
                                                    {

                                                        component: <div className="tabel-action-tooltip"><Isvg src={attachInvoice} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Attach invoice'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ attachInvoiceModal: item }),
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else if (item.paymentMethod == 2 && item.status == 'Invoice requested'.translate(this.props.lang)) {
                                                                return true
                                                            } else if (!item.paymentMethod && ((item.type == 'revisit' && item.priceList && !item.priceList.paymentOnSite && !item.priceList.onlinePayment && item.priceList.invoice && !item.priceList.freeCard) || (item.type !== 'revisit' && item.questionaryData && !item.questionaryData.paymentOnSite && !item.questionaryData.onlinePayment && item.questionaryData.invoice && !item.questionaryData.freeCard))) {
                                                                return true
                                                            }
                                                        }
                                                    },

                                                    // {

                                                    //     component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                    //     onClick: (item) => this.setState({ deleteUnpaid: item }),
                                                    //     condition: (item) => {
                                                    //         if (item.doctorPermission || item.type == 'revisit') {
                                                    //             return false
                                                    //         } else {
                                                    //             return true
                                                    //         }
                                                    //     }
                                                    // },

                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        // hideFilters={true}
                                        ></ListBuilder>

                                    }


                                </Col>
                                : null}

                            {/* PAID */}
                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <h5 className="component-header">{'Paid'.translate(this.props.lang)}</h5>
                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.totalInvoice}
                                            showNumeration={false}
                                            columnVisibility={'payments-paid'} //columnVisibility
                                            updateFilterField={this.state.updateFilterField}
                                            uData={this.props.uData} //columnVisibility
                                            updateListBuilderFields={this.props.updateListBuilderFields} //columnVisibility
                                            get={this.get}//columnVisibility
                                            fields={paidFields}
                                            rawItems={this.state.itemsInvoice}
                                            items={this.state.itemsInvoice.map((item => {
                                                return {
                                                    ...item,
                                                    version: item.version,
                                                    invoiceNumber: item.invoiceNumber ? item.invoiceNumber : 'N/A',
                                                    price: item.price + " " + currency,
                                                    vatPriceString: item.vatPriceString && item.vatPriceString !== 'N/A' ? item.vatPriceString : '0.00 ' + currency,
                                                    patientInfo: <>{item.patient}<br /><span className="clinic-subgroup-name">{item.patientSocialSecurityNumber} </span>{/*<br /><span className="clinic-subgroup-name">{item.patientAddress} </span><br />*/} </>,
                                                    clinicInfo: <>{item.clinic}<br /><span className="clinic-subgroup-name">{item.clinicAddress} </span> <br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span></>,

                                                    methodPayment:
                                                        item.price == 0 && (item.freePayment < 1 || item.freePayment > 6) ?
                                                            'Free of charge'.translate(this.props.lang)
                                                            :

                                                            item.paymentMethod == 1 ?
                                                                'On site'.translate(this.props.lang)
                                                                :
                                                                item.paymentMethod == 2 ?
                                                                    'Invoice'.translate(this.props.lang)
                                                                    :
                                                                    item.paymentMethod == 3 ?
                                                                        item.online == 'swish' ?
                                                                            'Online - Swish'.translate(this.props.lang)
                                                                            :
                                                                            item.online == 'payson' ?
                                                                                'Online - Card'.translate(this.props.lang)
                                                                                :
                                                                                item.online == 'stripe' ?
                                                                                    'Online - Stripe'.translate(this.props.lang)
                                                                                    :
                                                                                    'Online'.translate(this.props.lang)
                                                                        : item.paymentMethod == 4 ? 'Registered Manually'.translate(this.props.lang) : item.freePayment == 1 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Free card'.translate(this.props.lang)
                                                                            : item.freePayment == 2 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 23'.translate(this.props.lang)
                                                                                : item.freePayment == 3 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 20'.translate(this.props.lang)
                                                                                    : item.freePayment == 4 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18'.translate(this.props.lang)
                                                                                        : item.freePayment == 5 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Older than 85'.translate(this.props.lang)
                                                                                            : item.freePayment == 6 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18 (child)'.translate(this.props.lang)
                                                                                                :
                                                                                                !item.paymentMethod ?
                                                                                                    item.online == 'swish' ?
                                                                                                        'Online - Swish'.translate(this.props.lang)
                                                                                                        :
                                                                                                        item.online == 'payson' ?
                                                                                                            'Online - Card'.translate(this.props.lang)
                                                                                                            :
                                                                                                            item.online == 'stripe' ?
                                                                                                                'Online - Stripe'.translate(this.props.lang)
                                                                                                                :
                                                                                                                null
                                                                                                    :
                                                                                                    null,
                                                    // date: item.unpaidTs ? moment.unix(item.unpaidTs).format(this.props.dateFormat) : '',
                                                    tsCreated: item.tsCreated ? this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')*/ : '',
                                                    visitTimestamp: item.visitTimestamp ? this.props.getStringDateTs(item.visitTimestamp, this.props.dateFormat + ' HH:mm')/*moment.unix(item.visitTimestamp).format(this.props.dateFormat + ' HH:mm')*/ : '',

                                                }
                                            }))}
                                            actions={
                                                [
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ invoice: item }),

                                                    },
                                                    {

                                                        component: <div className="tabel-action-tooltip"><Isvg src={downloadIcon} height={48} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Download invoice'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => {
                                                            if (typeof window != 'undefined') {

                                                                window.open(API_ENDPOINT + item.attachedInvoice + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&rid=${item._id}`, '_blank')
                                                            }
                                                        },
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else if ((item.paymentMethod == 1 || item.paymentMethod == 2) /*&& item.status == 'Pending payment'.translate(this.props.lang)*/ && item.attachedInvoice) {
                                                                return true
                                                            }
                                                        }
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={billGrey} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ editPaymentPaid: item }),
                                                        condition: (item) => {
                                                            if (item && this.state.canTransferPayments && (item.paymentMethod === 4 || (item.paymentMethod === 0 && item.freePayment === 1))) return true;
                                                            else return false;
                                                        }
                                                    },
                                                    {
                                                        renderComponent: item => <div className="tabel-action-tooltip"><Isvg src={infoIcon} className={item && item.notes && item.notes.length ? 'wallet-icon wallet-icon-green' : "wallet-icon"} /> <div className="chat-icons-tooltip">
                                                            <span>{item && item.notes && item.notes.length ? 'Notes available'.translate(this.props.lang) /*<ul style={{listStyle: 'none', textAlign: 'left', padding: 5}}>{item.notes.map((note, i) => {
                                                                let name;
                                                                note.username ? (name = note.username) : (name = note.name);
                                                                let date;
                                                                if (note.timestamp) {
                                                                    try {
                                                                        let dateObj = new Date(note.timestamp);
                                                                        console.log(dateObj);
                                                                        if (dateObj) {
                                                                            date = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
                                                                        }
                                                                    } catch(err) {
                                                                        console.log('date err: ', err);
                                                                    }
                                                                    
                                                                }
                                                                let message;
                                                                switch (note.action) {
                                                                    case 'unpaid-to-paid': message = 'marked this case as paid on'.translate(this.props.lang); break;
                                                                    case 'paid-to-unpaid': message = 'marked this case as unpaid on'.translate(this.props.lang); break;
                                                                    case 'new-note': message = 'added a new note on'.translate(this.props.lang)
                                                                }

                                                                let addedNote;
                                                                if (note.note && note.note !== '' && note.note.trim() !== '') {
                                                                    if (note.action === 'new-note') {
                                                                        addedNote = ': ' + `"${note.note}"`
                                                                    } else {
                                                                        addedNote = ', ' + 'with note'.translate(this.props.lang) + ': ' + `"${note.note}"`;
                                                                    }
                                                                }
                                                                let string;
                                                                if (name && message && date) {
                                                                    string = name + ' ' + message + ' ' + date;
                                                                    if (addedNote) string += addedNote;
                                                                }
                                                                return <li key={i}>{string ? string + '.' : 'Note'.translate(this.props.lang)}</li>
                                                            })}</ul>*/ : 'Note'.translate(this.props.lang)}</span>
                                                        </div> </div>,
                                                        onClick: (item) => this.setState({ notesPaymentPaid: item }),
                                                        condition: (item) => {
                                                            if (item && this.state.canTransferPayments/* && (item.paymentMethod === 4 || (item.paymentMethod === 0 && item.freePayment === 1)) */) return true;
                                                            else return false;
                                                        }
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ deletePayment: item }),
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else {
                                                                return true
                                                            }
                                                        }
                                                    },

                                                ]
                                            }

                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        // hideFilters={true}

                                        ></ListBuilder>

                                    </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>


                </Container>

                {this.state.invoice ?
                    <Modal isOpen={this.state.invoice} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ invoice: !this.state.invoice })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ invoice: !this.state.invoice })}><Isvg src={xIcon} /></button>}>{'InvoiceKey'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent }} onChange={() => { }}></div>
                            {/* <PrintHelper lang={this.props.lang} html={this.state.invoice.invoiceContent} /> */}
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <PdfSave lang={this.props.lang} html={(this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent).replace(/č/g, 'c').replace(/Č/g, 'C').replace(/ć/g, 'c').replace(/Ć/g, 'C')} />
                            <Button color="primary" onClick={() => this.setState({ invoice: null })}> {'Close'.translate(this.props.lang)} </Button>


                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.referral ?
                    <Modal isOpen={this.state.referral} centered toggle={() => this.setState({ referral: !this.state.referral })}>
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral, paymentMethod: null, reference: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral, paymentMethod: null, reference: '' })}><Isvg src={xIcon} /></button>}>{'Payment'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            {/* <FormGroup>
                                <Label>{'Payment method'.translate(this.props.lang)}</Label>
                                <Input type='select'
                                    onChange={(e) => this.setState({ paymentMethod: e.target.value })}>

                                    <option value={0}>{'None'.translate(this.props.lang)}</option>
                                    <option value={1}>{'On site'.translate(this.props.lang)}</option>
                                    <option value={2}>{'Invoice'.translate(this.props.lang)}</option>

                                </Input>
                            </FormGroup> */}

                            <FormGroup>
                                <Label>{'Payment method'.translate(this.props.lang)}</Label>
                                <Input type='text'
                                    value={this.state.referral.paymentMethod == 1 ||
                                        ((this.state.referral.type = 'revisit' && this.state.referral.priceList && this.state.referral.priceList.paymentOnSite && !this.state.referral.priceList.onlinePayment && !this.state.referral.priceList.invoice && !this.state.referral.priceList.freeCard && !this.state.referral.paymentMethod) || (this.state.referral.type !== 'revisit' && this.state.referral.questionaryData && this.state.referral.questionaryData.paymentOnSite && !this.state.referral.questionaryData.invoice && !this.state.referral.questionaryData.onlinePayment && !this.state.referral.questionaryData.freeCard)) ?
                                        'On site'.translate(this.props.lang) : this.state.referral.paymentMethod == 2 ||
                                            ((this.state.referral.type = 'revisit' && this.state.referral.priceList && !this.state.referral.priceList.paymentOnSite && !this.state.referral.priceList.onlinePayment && this.state.referral.priceList.invoice && !this.state.referral.priceList.freeCard && !this.state.referral.paymentMethod) || (this.state.referral.type !== 'revisit' && this.state.referral.questionaryData && !this.state.referral.questionaryData.paymentOnSite && this.state.referral.questionaryData.invoice && !this.state.referral.questionaryData.onlinePayment && !this.state.referral.questionaryData.freeCard)) ?
                                            'Invoice'.translate(this.props.lang) : this.state.referral.paymentMethod == 3 ? 'Online'.translate(this.props.lang) : ''}>



                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Reference".translate(this.props.lang)}</Label>
                                <Input type='text'
                                    onChange={(e) => {

                                        this.setState({
                                            reference: striptags(e.target.value)
                                        })
                                    }}
                                    value={this.state.reference}
                                />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' disabled={this.state.referral.paymentMethod != 0 ? false : true} onClick={() => {
                                if ((this.state.referral.type === 'revisit' && this.state.referral.priceList && this.state.referral.priceList.paymentOnSite && !this.state.referral.priceList.onlinePayment && !this.state.referral.priceList.invoice && !this.state.referral.priceList.freeCard && !this.state.referral.paymentMethod) || (this.state.referral.type !== 'revisit' && this.state.referral.questionaryData && this.state.referral.questionaryData.paymentOnSite && !this.state.referral.questionaryData.onlinePayment && !this.state.referral.questionaryData.invoice && !this.state.referral.questionaryData.freeCard && !this.state.referral.paymentMethod)) {
                                    if (this.state.referral.type === 'revisit') {
                                        this.updatePaymentMethod(this.state.referral, this.state.referral.control, 1)
                                    } else {
                                        this.updatePaymentMethod(this.state.referral, null, 1)
                                    }

                                } else {
                                    this.generateInvoice(this.state.referral)
                                }
                            }}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>


                    </Modal>

                    : null}
                <input ref={(node) => this.documentInput = node} type="file" accept=".pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />

                {



                    this.state.attachInvoiceModal ?
                        <Modal isOpen={this.state.attachInvoiceModal} centered toggle={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })}>
                            <ModalHeader toggle={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })}><Isvg src={xIcon} /></button>}>{'Attach invoice'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody style={{ width: '100%' }}>

                                <button className={this.state._documentUploading ? 'attach-invoice uploading' : this.state.choosenFile ? 'attach-invoice uploaded' : "attach-invoice"} onClick={() => {
                                    if (this.documentInput) this.documentInput.click()
                                }}>
                                    <div className="button-menu-icon-wrap">
                                        <Isvg src={attachInvoice} />
                                    </div>
                                    <div className='chat-icons-text'>
                                        {
                                            this.state.choosenFile ?
                                                'File uploaded'.translate(this.props.lang)
                                                :
                                                'Attach file'.translate(this.props.lang)
                                        }
                                    </div>
                                </button>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' disabled={!this.state.choosenFile} onClick={() => {
                                    if ((this.state.attachInvoiceModal.type = 'revisit' && this.state.attachInvoiceModal.priceList && !this.state.attachInvoiceModal.priceList.paymentOnSite && !this.state.attachInvoiceModal.priceList.onlinePayment && this.state.attachInvoiceModal.priceList.invoice && !this.state.attachInvoiceModal.priceList.freeCard && !this.state.attachInvoiceModal.paymentMethod) || (this.state.attachInvoiceModal.type !== 'revisit' && this.state.attachInvoiceModal.questionaryData && !this.state.attachInvoiceModal.questionaryData.paymentOnSite && !this.state.attachInvoiceModal.questionaryData.onlinePayment && this.state.attachInvoiceModal.questionaryData.invoice && !this.state.attachInvoiceModal.questionaryData.freeCard && !this.state.attachInvoiceModal.paymentMethod)) {
                                        if (this.state.attachInvoiceModal.type === 'revisit') {
                                            this.updatePaymentMethod(this.state.attachInvoiceModal, this.state.attachInvoiceModal.control, 2)
                                        } else {
                                            this.updatePaymentMethod(this.state.attachInvoiceModal, null, 2)
                                        }
                                    } else {
                                        this.sendInvoice(this.state.attachInvoiceModal)
                                    }
                                }}>{'Send'.translate(this.props.lang)}</Button>
                                <Button color='primary' onClick={() => this.setState({ attachInvoiceModal: null, choosenFile: null })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>


                        </Modal>
                        :
                        null
                }

                {
                    this.state.deleteInvoice ? <Modal isOpen={this.state.deleteInvoice} centered toggle={() => this.setState({ deleteInvoice: null })}>
                        {/* <ModalHeader toggle={() => this.setState({deleteInvoice: null})} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ deleteInvoice: null })}><Isvg src={xIcon} /></button>}>
                            {'Delete invoice'.translate(this.props.lang)}
                        </ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{`Are you sure you want to delete the invoice? You can add new invoice later.`.translate(this.props.lang)}</p>
                        </ModalBody>

                        <ModalFooter className='buttons-right-blue'>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                            <Button style={{ minWidth: 100 }} color='danger' onClick={() => this.deleteSelectedInvoice(this.state.deleteInvoice)}>{'Delete'.translate(this.props.lang)}</Button>
                            <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ deleteInvoice: null })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal> : null
                }

                {this.state.paysonModal ?

                    <Payson
                        openModal={(item) => this.setState({
                            paysonModal: item
                        })}
                        referral={this.state.paysonModal._id}
                        data={this.state.paysonModal}
                        price={this.state.paysonModal.questionaryPrice}
                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        getStringDateInverseTs={this.props.getStringDateInverseTs}
                        open={this.state.swishModal}
                        openModal={(item) => this.setState({
                            swishModal: item
                        })}
                    />

                    : null}

                {this.state.editPaymentUnpaid ? <Modal centered isOpen={this.state.editPaymentUnpaid}>
                    {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                    <ModalBody className="delete-modal">
                        <p style={{ marginTop: 10, marginBottom: 30 }}>{'Are you sure you want to mark this case as paid?'.translate(this.props.lang)}</p>
                        <Input type="textarea" placeholder={'Enter note (optional)'.translate(this.props.lang)} style={{ height: 100 }} value={this.state.editPaymentUnpaidText} onChange={(e) => { this.setState({ editPaymentUnpaidText: e.target.value }) }} />
                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                            this.updateUnpaidCase(this.state.editPaymentUnpaid, this.state.editPaymentUnpaidText);
                        }}>{'Yes'.translate(this.props.lang)}</Button>

                        <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ editPaymentUnpaid: null, editPaymentUnpaidText: '' })

                        }>{'Cancel'.translate(this.props.lang)}</Button>

                    </ModalFooter>
                </Modal> : null}

                {this.state.notesPaymentUnpaid ? <Modal size='lg' centered isOpen={this.state.notesPaymentUnpaid}>
                    <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                    <ModalBody className="delete-modal">
                        {this.state.notesPaymentUnpaid?.notes && this.state.notesPaymentUnpaid?.notes.length ? <ul style={{ listStyle: 'none', padding: 5, textAlign: 'left' }}>
                            {this.state.notesPaymentUnpaid.notes.map((note, i) => {
                                let name;
                                note.username ? (name = note.username) : (name = note.name);
                                let date;
                                if (note.timestamp) {
                                    try {
                                        let dateObj = new Date(note.timestamp);
                                        console.log(dateObj);
                                        if (dateObj) {
                                            date = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
                                        }
                                    } catch (err) {
                                        console.log('date err: ', err);
                                    }

                                }
                                let message;
                                switch (note.action) {
                                    case 'unpaid-to-paid': message = 'marked this case as paid on'.translate(this.props.lang); break;
                                    case 'paid-to-unpaid': message = 'marked this case as unpaid on'.translate(this.props.lang); break;
                                    case 'new-note': message = 'added a new note on'.translate(this.props.lang)
                                }

                                let addedNote;
                                if (note.note && note.note !== '' && note.note.trim() !== '') {
                                    if (note.action === 'new-note') {
                                        addedNote = ': ' + `"${note.note}"`
                                    } else {
                                        addedNote = ', ' + 'with note'.translate(this.props.lang) + ': ' + `"${note.note}"`;
                                    }
                                }
                                let string;
                                if (name && message && date) {
                                    string = name + ' ' + message + ' ' + date;
                                    if (addedNote) string += addedNote;
                                }
                                return <li key={i}>{i + 1 + '. '} {' '} {string ? string + '.' : 'Note'.translate(this.props.lang)}</li>
                            })}
                        </ul>
                            :
                            <div style={{ marginBottom: 20 }}>{'No notes yet'.translate(this.props.lang) + '.'}</div>}
                        <Input type="textarea" placeholder={'Enter new note'.translate(this.props.lang)} style={{ height: 100 }} value={this.state.notesPaymentText} onChange={(e) => { this.setState({ notesPaymentText: e.target.value }) }} />
                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button disabled={!this.state.notesPaymentText || this.state.notesPaymentText === '' || this.state.notesPaymentText.trim() === ''} color="primary" style={{ margin: 10 }} onClick={() => {
                            this.addNewNote('unpaid', this.state.notesPaymentUnpaid, this.state.notesPaymentText);
                        }}>{'Save'.translate(this.props.lang)}</Button>

                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ notesPaymentUnpaid: null, notesPaymentText: '' })

                        }>{'Close'.translate(this.props.lang)}</Button>

                    </ModalFooter>
                </Modal> : null}



                {this.state.notesPaymentPaid ? <Modal size='lg' centered isOpen={this.state.notesPaymentPaid}>
                    <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                    <ModalBody className="delete-modal">
                        {this.state.notesPaymentPaid?.notes && this.state.notesPaymentPaid?.notes.length ? <ul style={{ listStyle: 'none', padding: 5, textAlign: 'left' }}>
                            {this.state.notesPaymentPaid.notes.map((note, i) => {
                                let name;
                                note.username ? (name = note.username) : (name = note.name);
                                let date;
                                if (note.timestamp) {
                                    try {
                                        let dateObj = new Date(note.timestamp);
                                        console.log(dateObj);
                                        if (dateObj) {
                                            date = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
                                        }
                                    } catch (err) {
                                        console.log('date err: ', err);
                                    }

                                }
                                let message;
                                switch (note.action) {
                                    case 'unpaid-to-paid': message = 'marked this case as paid on'.translate(this.props.lang); break;
                                    case 'paid-to-unpaid': message = 'marked this case as unpaid on'.translate(this.props.lang); break;
                                    case 'new-note': message = 'added a new note on'.translate(this.props.lang)
                                }

                                let addedNote;
                                if (note.note && note.note !== '' && note.note.trim() !== '') {
                                    if (note.action === 'new-note') {
                                        addedNote = ': ' + `"${note.note}"`
                                    } else {
                                        addedNote = ', ' + 'with note'.translate(this.props.lang) + ': ' + `"${note.note}"`;
                                    }
                                }
                                let string;
                                if (name && message && date) {
                                    string = name + ' ' + message + ' ' + date;
                                    if (addedNote) string += addedNote;
                                }
                                return <li key={i}>{i + 1 + '. '} {' '} {string ? string + '.' : 'Note'.translate(this.props.lang)}</li>
                            })}
                        </ul>
                            :
                            <div style={{ marginBottom: 20 }}>{'No notes yet'.translate(this.props.lang) + '.'}</div>}
                        <Input type="textarea" placeholder={'Enter new note'.translate(this.props.lang)} style={{ height: 100 }} value={this.state.notesPaymentText} onChange={(e) => { this.setState({ notesPaymentText: e.target.value }) }} />
                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button disabled={!this.state.notesPaymentText || this.state.notesPaymentText === '' || this.state.notesPaymentText.trim() === ''} color="primary" style={{ margin: 10 }} onClick={() => {
                            this.addNewNote('paid', this.state.notesPaymentPaid, this.state.notesPaymentText);
                        }}>{'Save'.translate(this.props.lang)}</Button>

                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ notesPaymentPaid: null, notesPaymentText: '' })

                        }>{'Close'.translate(this.props.lang)}</Button>

                    </ModalFooter>
                </Modal> : null}

                {this.state.editPaymentPaid ? <Modal centered isOpen={this.state.editPaymentPaid}>
                    {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                    <ModalBody className="delete-modal">
                        <p style={{ marginTop: 10, marginBottom: 30 }}>{'Are you sure you want to mark this case as unpaid?'.translate(this.props.lang)}</p>
                        <Input type="textarea" placeholder={'Enter note (optional)'.translate(this.props.lang)} style={{ height: 100 }} value={this.state.editPaymentPaidText} onChange={(e) => { this.setState({ editPaymentPaidText: e.target.value }) }} />
                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                            this.updatePaidCase(this.state.editPaymentPaid, this.state.editPaymentPaidText);
                        }}>{'Yes'.translate(this.props.lang)}</Button>

                        <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ editPaymentPaid: null, editPaymentPaidText: '' })

                        }>{'Cancel'.translate(this.props.lang)}</Button>

                    </ModalFooter>
                </Modal> : null}


                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}

                {this.state.deletePayment ?
                    <Modal isOpen={this.state.deletePayment} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{`Are you sure you want to delete the payment?`.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.deletePayment(this.state.deletePayment._id)}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deletePayment: false })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.deleteUnpaid ?
                    <InfoModal
                        isOpen={this.state.deleteUnpaid}
                        onClose={(item) => {
                            this.setState({
                                deleteUnpaid: item
                            })
                        }}
                        toggle={() => this.setState({ deleteUnpaid: !this.state.deleteUnpaid })}
                        header={'Warning'.translate(this.props.lang)}
                        info={'Are you sure you want to delete this referral!'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary' onClick={() => {
                                this.deleteUnpaidReferral(this.state.deleteUnpaid);

                            }}>{'Yes'.translate(this.props.lang)}</Button>,

                            <Button color='primary' onClick={() => {
                                this.setState({
                                    deleteUnpaid: false
                                })

                            }}>{'No'.translate(this.props.lang)}</Button>


                        ]}
                    />

                    :

                    null}

                {this.state._documentUploading ?
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                        </div>
                    </div>
                    : null}

                {
                    this.state.deleteBill ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteBill}
                            toggle={() => this.setState({ deleteBill: null })}
                            handler={() => { this.delete(this.state.deleteBill); this.setState({ deleteBill: null }) }}
                        >
                            {'Are you sure that you want to cancel the payment for case '.translate(this.props.lang)} <strong>{this.state.deleteBill.patient ? this.state.deleteBill.patient.referralNumber + (this.state.deleteBill.version != 0 ? '.' + this.state.deleteBill.version : '') : this.state.deleteBill.controlNumber ? `${this.state.deleteBill.controlNumber}` : ''}</strong>?
                        </DeleteModal>
                        :
                        null
                }
            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Payments));