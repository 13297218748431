import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';

import Isvg from "react-inlinesvg";

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required, checkSocialSecurityNumber, minLength, dateValidate } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import ErrorModal from '../../components/errorModal';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import notificationSettings from "../../assets/svg/notifications_settings.svg";
import moreIcon from "../../assets/svg/more.svg";
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import infoIcon from "../../assets/svg/link-new-tab.svg";


import warrning from '../../assets/svg/warrning.svg'

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const getInitialsFromName = (name) => {
    if (!name?.trim?.()) return 'XX';
    const arr = name.split(' ').filter(n => n && n.trim() ? true : false);
    if (arr.length === 0) return 'XX';
    if (arr.length === 1) {
        const name1 = arr[0];
        if (!name1) return 'XX';
        return name1[0].toLocaleUpperCase();
    }
    if (arr.length > 2) {
        const name1 = arr[0];
        const name2 = arr[arr.length - 1];
        if (!name1?.trim() || !name2?.trim()) return 'XX';
        return name1[0].toLocaleUpperCase() + name2[0].toLocaleUpperCase();
    }

    const [name1, name2] = arr;
    if (!name1?.trim() || !name2?.trim()) return 'XX';
    return name1[0].toLocaleUpperCase() + name2[0].toLocaleUpperCase();
}

/**
* Clinic users page
* @author   Milan Stanojevic
*/
class SubAccounts extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            doctors: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            professions: [],
            total: 0,
            loading: true,
            questionaryModal: false,
            questionaryList: [],
            tabIndex: 1,
            transferToUser: null,
            changeEmail: false,
            tabIndexGroups: 1,
            selectedProfession: '',
            clinicAdmins: [],
        };
    }


    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup, clinic: this.props.selectedClinic, selectedProfession: this.state.selectedProfession })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    componentDidMount() {
        this.getPermissions();
        this.getClinicGroupSettings();
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props.uData && this.props.uData.clinicModules && this.props.uData.clinicModules.length) {
            for (let i = 0; i < this.props.uData.clinicModules.length; i++) {
                if (this.props.uData.clinicModules[i].name == "Vårdutövare") {
                    this.setState({ doctorIdPermissions: this.props.uData.clinicModules[i]._id })
                }
            }
        }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
        }
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevState.form && prevState.form != this.state.form && !this.state.form) {
            this.setState({ changeEmail: false })
        }
        if (this.props.profession && this.props.profession[this.props.selectedGroup] && (this.props.profession[this.props.selectedGroup] == 'Select profession' || this.props.profession[this.props.selectedGroup] == 'Välj profession')) {
            this.props.changeProfession(null)
        }

        if (prevProps.socialSecurityNumber != this.props.socialSecurityNumber && this.props.socialSecurityNumber && (this.props.socialSecurityNumber.length == 12 || (this.props.country === 'denmark' && this.props.socialSecurityNumber.length == 10))) {
            this.checkUser();
        }

        // if (prevProps.name != this.props.name && this.state.form && this.props.name && this.props.name.indexOf(' ') !== -1) {
        //     this.getUsername();
        // }
        if (prevProps.name != this.props.name && this.state.form) {
            if (this.props.name) {
                if (!this.state.form._id || (this.state.form._id && !this.state.form.username))
                    this.getUsername();
            } else {
                if (this.props.username)
                    this.props.changeUsername('')
            }

        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }



    }
    setDoctorPermissions = () => {
        this.setState({ doctorPermissions: true })
    }
    setOffDoctorPermissions = () => {
        this.setState({ doctorPermissions: false })
        this.props.changeQuestionaryList([])
    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update user
    * @author   Milan Stanojevic
    * @Objectparam    {String} clinic                   clinic id
    * @Objectparam    {String} group                    group id
    * @Objectparam    {Object} groupPermissions         groupPermissions for user
    * @Objectparam    {Object} data                     form data
    */
    insertOrUpdate = (data, skipErrorLimit = false) => {

        if (!data.language) {
            data.language = this.state.languages.filter(item => { if (item.key == 'en') return (item) })._id
        }

        if (data.password != data.repeatPassword) {
            this.setState({ error: 'Password and repeat password must be equal.' })
        } else
            this.setState({
                loading: true
            }, () => {
                const shouldReVerifyUser = this.state.form?._id === this.props.uData?._id;
                if (!this.state.form._id) {

                    fetch(API_ENDPOINT + '/users/subaccounts/new', {
                        method: 'PUT',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ ...data, profession: data.profession, groupPermissions: this.state.form.groupPermissions, group: this.props.selectedGroup, clinic: this.props.selectedClinic, skipErrorLimit: skipErrorLimit })
                    }).then(parseJSON).then(({ result, status }) => {

                        if (result.error) {
                            if (result.error == 'Error licence') {
                                this.setState({
                                    errorLimit: {
                                        error: result.error,
                                        data: data
                                    }
                                })
                            } else {
                                this.setState({ error: result.error })
                            }

                        } else {


                            this.setState({
                                form: null,
                                doctorPermissions: false
                            })
                        }
                        // this.props.verifyUser();
                        this.get();

                    })
                } else {
                    let obj = {
                        profession: data.profession,
                        groupPermissions: this.state.form.groupPermissions,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        username: data.username,
                        contacts: data.contacts,
                        skipErrorLimit: skipErrorLimit
                    }
                    if (this.state.changeEmail) {
                        obj.email = data.email;
                    }
                    fetch(API_ENDPOINT + '/users/subaccounts/' + this.state.form._id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(obj)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            if (result.error == 'Error licence') {
                                this.setState({
                                    errorLimit: {
                                        error: result.error,
                                        data: data
                                    }
                                })
                            } else {
                                this.setState({ error: result.error })
                            }

                        } else {


                            this.setState({
                                form: null,
                                doctorPermissions: false
                            })
                        }
                        if (shouldReVerifyUser) this.props.verifyUser();
                        this.get();

                    })
                }
            })
    }

    upgradeUsersProfession = (data, id) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {

            fetch(API_ENDPOINT + '/users/subaccounts-upgrade-profession/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ group: this.props.selectedGroup, profession: data })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    _saving: false,
                    loading: false,
                    deleteModalProfession: null
                }, () => {
                    setTimeout(() => {
                        this.setState({ _saving: false, professionForm: null })
                    }, 1000)
                })
            })
        })
    }

    insertOrUpdateProfesion = (data) => {
        this.setState({
            // loading: true,
            _saving: true
        }, () => {
            if (!data._id) {

                fetch(API_ENDPOINT + '/data/medical-workers-categories/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ ...data, group: this.props.selectedGroup })
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, professionForm: null })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/medical-workers-categories/' + data._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    // this.get();
                    this.setState({
                        _saving: false,
                        // loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, professionForm: null })
                        }, 1000)
                    })
                })
            }
        })
    }

    deleteProfession = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/medical-workers-categories/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error, selectedProfession: id })
                }
                this.get();
            })
        })

    }

    /**
    * Delete user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                   user id
    */
    delete = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/subaccounts/' + data._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ groupId: this.props.selectedGroup, name: data.userData.name })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }
    checkActiveReferrals = (item) => {


        fetch(API_ENDPOINT + '/users/subaccounts/check-active-referrals/' + item._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ groupId: this.props.selectedGroup })
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                // console.log(result)
                if (!result.haveActiveReferrals && result.activeReferrals && result.activeReferrals.length == 0 && !result.activeConsultations && !result.activeEconnectCases) {
                    if (result.inActiveReferrals) {
                        // console.log('delete')

                        this.setState({
                            userChecked: item
                        }, () => {
                            this.checkTransfer()
                        })
                    } else {
                        this.setState({ deleteModal: item })

                    }
                } else {

                    fetch(API_ENDPOINT + '/users/subaccounts', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ groupId: this.props.selectedGroup })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                errorAcitveReferrals: 'The user has active referrals. You must transfer all active referrals to other users before proceeding.',
                                userChecked: item,
                                doctors: result
                            })

                        }

                    })


                }
            }

        })
    }
    checkTransfer = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/check/transfer/referrals', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ groupId: this.props.selectedGroup, transferToUser: this.state.transferToUser, transferFromUser: this.state.userChecked._id })
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                if (this.state.userChecked) {
                    this.setState({ transferToUser: null, deleteModal: this.state.userChecked, errorAcitveReferrals: null }, () => {
                        this.setState({ userChecked: null })
                    })
                }

            }

        })
    }

    /**
    * Update email or sms notification for user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                               user id
    * @Objectparam    {Object} notifications                    notification data
    */
    updateNotifications(item) {
        let data = {};

        if (item._id) {
            data.notifications = {};

            let selectedGroupIdx = 0;
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
            if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].notifications) {
                data.notifications = this.props.uData.clinicGroups[selectedGroupIdx].notifications;
            }

            data.notifications[item._id] = {
                doctor: this.state.notificationsDoctor,
                patient: this.state.notificationsPatient
            }
            this.setState(
                {
                    showPlayer: true,
                },
                () => {
                    data.groupId = this.props.selectedGroup;
                    data.clinic = this.props.selectedClinic;
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/users/account/updateClinicData', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false, notificationModal: null, notificationsDoctor: null, notificationsPatient: null });
                                    }, 1000);
                                })
                            // if (this.state.previewNeeded) {
                            //     this.setState({ previewNeeded: false });
                            //     window.open(window.location.origin + '/' + data.alias, '_blank')
                            // }
                            this.props.verifyUser(() => { }, () => {
                                this.props.changeGroup(data.groupId)
                            });
                        }

                    })
                }
            )
        }
    }

    /**
    * Check user
    * @author   Milan Stanojevic
    * @Objectparam    {String} socialSecurityNumber                               user socialSecurityNumber
    */
    checkUser = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumber })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200) {

                console.log(result)
                if (result.groupPermissions && result.groupPermissions[this.props.selectedGroup] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.state.doctorIdPermissions) != -1) {
                    this.setState({ doctorPermissions: true })
                }

                this.setState({
                    form: result
                }, () => {
                    if (!result.email) {
                        this.setState({ changeEmail: true })
                    }
                    this.props.changeUsername(result.username);
                    this.props.changeEmail(result.email);
                    this.props.changeUserData(result.userData);
                    this.props.changeLanguage(result.language);
                    this.props.changePhone(result.phone);
                    this.setState({ lockFields: true })
                })

            }
            if (status == 405) {
                this.setState({
                    error: 'The user is forbidden!'.translate(this.props.lang)
                })
                this.props.changeSocialSecurityNumber('')
            }
        })

    }

    /**
    * Generate username for user
    * @author   Milan Stanojevic
    * @Objectparam    {String} userId                             user id
    * @Objectparam    {String} name                               user name
    */
    getUsername = () => {
        fetch(API_ENDPOINT + '/users/users/get-username', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.state.form._id ? this.state.form._id : null,
                name: this.props.name,
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.username) {
                this.props.changeUsername(result.username)
            }
        })

    }


    notificationSettingsFun = (item) => {
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].notifications && this.props.uData.clinicGroups[selectedGroupIdx].notifications[item._id]) {

            this.setState({
                notificationsDoctor: this.props.uData.clinicGroups[selectedGroupIdx].notifications[item._id].doctor,
                notificationsPatient: this.props.uData.clinicGroups[selectedGroupIdx].notifications[item._id].patient
            }, () => {
                let arr = this.state.notificationsDoctor;
                if (!arr.set1) { arr.set1 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set2) { arr.set2 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set3) { arr.set3 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set4) { arr.set4 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set5) { arr.set5 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set6) { arr.set6 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set7) { arr.set7 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set8) { arr.set8 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                this.setState({ notificationsDoctor: arr })

                let arr2 = this.state.notificationsPatient;
                if (!arr2.set1) { arr2.set1 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set2) { arr2.set2 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set3) { arr2.set3 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set4) { arr2.set4 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set5) { arr2.set5 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set6) { arr2.set6 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set7) { arr2.set7 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set8) { arr2.set8 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set9) { arr2.set9 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set10) { arr2.set10 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set11) { arr2.set11 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set12) { arr2.set12 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set13) { arr2.set13 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set14) { arr2.set14 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set15) { arr2.set15 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                this.setState({ notificationsPatient: arr2 })
            })
        } else {
            let notificationsDoctorObj = {
                "set1": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set2": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set3": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set4": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set5": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set6": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set7": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set8": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                }
            }
            let notificationsPatientObj = {
                "set1": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set2": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set3": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set4": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set5": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set6": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set7": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set8": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set9": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set10": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set11": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set12": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set13": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                },
                "set14": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                },
                "set15": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                }
            }
            this.setState({
                notificationsDoctor: notificationsDoctorObj,
                notificationsPatient: notificationsPatientObj
            })
        }

        let notificationsDoctorObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        let notificationsPatientObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set9": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set10": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set11": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set12": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set13": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set14": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set15": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        this.setState({
            notificationsDoctorDefault: notificationsDoctorObjDefault,
            notificationsPatientDefault: notificationsPatientObjDefault
        })

        this.setState({
            notificationModal: item
        })
    }

    setDefaultValues = (tab) => {
        let notificationsDoctorObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        let notificationsPatientObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set9": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set10": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set11": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set12": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set13": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set14": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set15": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        if (tab == 1) {
            this.setState({
                notificationsDoctor: notificationsDoctorObjDefault,
            })
        } else if (tab == 2) {
            this.setState({
                notificationsPatient: notificationsPatientObjDefault
            })
        }

    }
    stopAnimation = () => {
        this.player.current.play();
    };

    changeEConnectStatus = () => {
        if (!this.state.eConnectModal || !this.state.eConnectModal.id || !this.state.eConnectModal.action || !['enable', 'disable'].includes(this.state.eConnectModal.action)) return;
        this.setState({ loading: true }, async () => {
            const newState = { loading: false, eConnectModal: null };
            const response = await fetch(API_ENDPOINT + `/users/e-connect/subaccounts/${this.state.eConnectModal.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    eConnectTempDisabled: this.state.eConnectModal.action === 'enable' ? false : true
                })
            });

            const result = await response?.json?.();

            if (!response?.ok || !result || result.error) {
                newState.error = result.error ?? 'Something went wrong! Please try again later.';
                return this.setState(newState);
            }

            const eConnectTempDisabled = result.eConnectTempDisabled ?? false;
            const form = this.state.form ?? {};
            form.eConnectTempDisabled = eConnectTempDisabled;

            newState.form = form;
            newState.error = null;
            this.setState(newState, () => {
                this.get();
            });
        })
    }

    getPermissions = () => {
        this.setState({ permissions: null }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/permission-types');
            if (!response || !response.ok) return;
            const result = await response?.json?.();
            if (!result || !result.permissions) return;
            this.setState({ permissions: result.permissions });
        })
    }

    triggerSemble = async (item) => {
        if (!this.props.selectedGroup || !item || !item._id) return;

        this.setState({ _saving: true });

        let sembleUsers = this.state.sembleUsers;
        if (!sembleUsers || !Array.isArray(sembleUsers) || !sembleUsers.length) {
            const response = await fetch(`${API_ENDPOINT}/users/semble-users?groupId=${this.props.selectedGroup}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                    'Content-Type': 'application/json'
                },
            });

            const result = await response?.json?.();

            if (!result || result.error || !result.users || !Array.isArray(result.users) || !result.users.length) return this.setState({
                error: 'No Semble users found! If your clinic has clinic users in Semble panel, please notify the administrators so that your connection to Semble can be configured.',
                _saving: false
            });

            sembleUsers = result.users;
        };

        let name = item?.userData?.name || item?.name || '';
        name = name.trim().toLowerCase();
        let email = item?.contacts?.[this.props.selectedGroup]?.email || '';
        email = email.trim().toLowerCase();

        const newSembleUsers = [...sembleUsers].sort((a, b) => {
            try {
                const aUserEmail = a?.email?.trim?.()?.toLowerCase?.();
                const aUserName = a?.name?.trim?.()?.toLowerCase?.();

                const bUserEmail = b?.email?.trim?.()?.toLowerCase?.();
                const bUserName = b?.name?.trim?.()?.toLowerCase?.();

                let aPoints = 0;
                let bPoints = 0;

                if (aUserEmail === email) aPoints += 3;
                if (aUserName === name) aPoints += 2;
                const aSplitted = aUserName.split(' ');
                for (let i = 0; i < aSplitted.length; i++) {
                    if (name.includes(aSplitted[i])) aPoints += 1;
                }

                if (bUserEmail === email) bPoints += 3;
                if (bUserName === name) bPoints += 2;
                const bSplitted = bUserName.split(' ');
                for (let i = 0; i < bSplitted.length; i++) {
                    if (name.includes(bSplitted[i])) bPoints += 1;
                }

                return bPoints - aPoints;
            } catch (err) {
                return 0;
            }
        });

        this.setState({ sembleUsers: newSembleUsers, _saving: false }, () => {
            this.setState({ sembleModal: item });
        });
    }

    connectSemble = (semble) => {
        if (semble && semble.id && this.state.sembleModal?._id && this.props.selectedGroup) {
            this.setState({ loading: true, _saving: true }, async () => {
                const response = await fetch(`${API_ENDPOINT}/users/semble/${this.state.sembleModal._id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        semble,
                        action: 'connect',
                        groupId: this.props.selectedGroup
                    })
                });

                const result = await response?.json?.();

                this.setState({
                    loading: false,
                    _saving: false,
                    sembleModal: null,
                    error: !result || result.error ? (
                        result?.error || 'Something went wrong! Please refresh your browser and try again!'
                    ) : (
                        null
                    )
                }, () => {
                    this.get();
                })
            });
        } else {
            this.setState({
                sembleModal: null,
                error: 'Something went wrong! Please refresh your browser and try again!'
            })
        }
    }

    disconnectSemble = () => {
        if (this.state.sembleModal?._id && this.props.selectedGroup) {
            this.setState({ loading: true }, async () => {
                const response = await fetch(`${API_ENDPOINT}/users/semble/${this.state.sembleModal._id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'disconnect',
                        groupId: this.props.selectedGroup
                    })
                });

                const result = await response?.json?.();

                this.setState({
                    loading: false,
                    sembleModal: null,
                    error: !result || result.error ? (
                        result?.error || 'Something went wrong! Please refresh your browser and try again!'
                    ) : (
                        null
                    )
                }, () => {
                    this.get();
                })
            });
        } else {
            this.setState({
                sembleModal: null,
                error: 'Something went wrong! Please refresh your browser and try again!'
            })
        }
    }

    render() {
        if (this.state.error) console.log('error: ', this.state.error);
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let mailEnabled = false;
        let smsEnabled = false;
        let pushEnabled = false;
        let notifEnabled = false;
        let listingNotificationsEnabled = false;
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx]) {
            mailEnabled = this.props.uData.clinicGroups[selectedGroupIdx].mailNotifications;
            smsEnabled = this.props.uData.clinicGroups[selectedGroupIdx].smsNotifications;
            pushEnabled = this.props.uData.clinicGroups[selectedGroupIdx].pushNotifications;
            notifEnabled = this.props.uData.clinicGroups[selectedGroupIdx].systemNotifications;
            listingNotificationsEnabled = this.props.uData.clinicGroups[selectedGroupIdx].listing
        }

        const semble = this.props.hasSemble

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <div className="panel">
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndexGroups == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndexGroups: 1 }, () => {
                                        this.setState({
                                            selectedProfession: ''
                                        }, () => {
                                            this.updateParams('page', 0)
                                            this.get()
                                        })
                                    })}>{"Accounts".translate(this.props.lang)}</Button>

                                    <Button className={this.state.tabIndexGroups == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndexGroups: 2 }, () => {
                                        this.setState({
                                            selectedProfession: this.state.professions[0]._id
                                        }, () => {
                                            this.updateParams('entries', 10)
                                            this.updateParams('page', 0)
                                            this.get()
                                        })
                                    })}>{"Groups".translate(this.props.lang)}</Button>

                                </div>

                            </Col>
                            {
                                this.state.tabIndexGroups === 1 ?
                                    <Col lg="12">


                                        <div className="panel-header">
                                            <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                            <button className="btn add-button" onClick={() => this.setState({
                                                form: {},
                                                lockFields: false
                                            }, () => {
                                                let form = this.state.form;
                                                if (!form.groupPermissions) {
                                                    form.groupPermissions = {};
                                                }

                                                if (!form.groupPermissions[this.props.selectedGroup]) {
                                                    form.groupPermissions[this.props.selectedGroup] = {};
                                                }

                                                this.setState({ form })
                                            })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        </div>
                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'name', label: 'Title, first and last name'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'username', label: 'Username'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'email', label: 'Email address'.translate(this.props.lang), allowSort: true },

                                            ]}
                                            items={this.state.items.map(item => {
                                                // console.log('item: ', item);
                                                return {
                                                    ...item,
                                                    name: this.props.formatName(item.userData.title, item.userData.name),
                                                    email: item.contacts && item.contacts[this.props.selectedGroup] && item.contacts[this.props.selectedGroup].email

                                                }
                                            })}
                                            actions={
                                                [
                                                    {
                                                        renderComponent: item => <div className="tabel-action-tooltip">
                                                            <svg class="feather feather-link" fill="none" height="24" stroke={this.props.selectedGroup && item?.semble?.[this.props.selectedGroup]?.id ? 'blue' : '#dc3545'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" /><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" /></svg>

                                                            <div className="chat-icons-tooltip">{'Semble'.translate(this.props.lang)}</div>
                                                        </div>,
                                                        condition: () => this.props.hasSemble ? true : false,
                                                        onClick: (item) => this.triggerSemble(item)
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"> <Isvg className='notification-edit-svg' src={notificationSettings} /> <div className="chat-icons-tooltip">{'Notification settings'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.notificationSettingsFun(item),
                                                        condition: (item) => {
                                                            if (this.props.uData.groupShowNotificationsSettings) {
                                                                return true
                                                            } else {
                                                                return false
                                                            }
                                                        }
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"> <Isvg src={edit} /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ form: item })
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"> <Isvg src={garabage} /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.checkActiveReferrals(item)
                                                    },
                                                ]
                                            }
                                            rawItems={this.state.items}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                        <DeleteModal
                                            lang={this.props.lang}
                                            isOpen={this.state.deleteModal}
                                            toggle={() => this.setState({ deleteModal: null })}
                                            handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                                        >
                                            {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                        </DeleteModal>

                                        {
                                            this.state.errorLimit && this.state.errorLimit.error ?
                                                <InfoModal
                                                    isOpen={this.state.errorLimit.error}
                                                    onClose={(item) => {
                                                        this.setState({
                                                            errorLimit: null
                                                        })
                                                    }}
                                                    close={true}
                                                    header={'Increased monthly cost'.translate(this.props.lang)}
                                                    info={'The added user is associated with a monthly fee. For each user in addition to 10 users, a monthly cost will be added.'.translate(this.props.lang)}
                                                    buttons={[
                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                            let data;
                                                            if (this.state.errorLimit.data) {
                                                                data = this.state.errorLimit.data;
                                                            }
                                                            this.setState({ errorLimit: null }, () => this.insertOrUpdate(data, true))
                                                        }}>{'Ok'.translate(this.props.lang)}</Button>,
                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ errorLimit: null, form: null, doctorPermissions: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                                                    ]}
                                                />
                                                :
                                                null

                                        }
                                        {this.state.errorAcitveReferrals ?
                                            <Modal isOpen={this.state.errorAcitveReferrals} centered>
                                                <ModalBody className="delete-modal">
                                                    <div className="message">
                                                        {this.state.errorAcitveReferrals.translate(this.props.lang)}
                                                        <FormGroup style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                                            <div>
                                                                <Label>{'Transfer to'.translate(this.props.lang)}</Label>
                                                                <Input type='select' value={this.state.transferToUser}
                                                                    style={{ width: 300 }}
                                                                    onChange={(e) => {

                                                                        this.setState({ transferToUser: e.target.value })
                                                                    }}>
                                                                    <option value={null}>{'None'.translate(this.props.lang)}</option>

                                                                    {
                                                                        this.state.doctors && this.state.doctors.sort((a, b) => {
                                                                            const nameA = a.name.toLowerCase();
                                                                            const nameB = b.name.toLowerCase();
                                                                            if (nameA < nameB) {
                                                                                return -1
                                                                            }
                                                                            if (nameA > nameB) {
                                                                                return 1
                                                                            }
                                                                        }).map((item, idx) => {
                                                                            if (item._id != this.state.userChecked._id)
                                                                                return (
                                                                                    <option value={item._id}>{this.props.formatName(item.userData.title, item.userData.name)}</option>
                                                                                )
                                                                        })
                                                                    }
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button style={{ minWidth: 100 }} disabled={!this.state.transferToUser || this.state.transferToUser == 'None' ? true : false} onClick={() => this.checkTransfer()} >{'Ok'.translate(this.props.lang)}</Button>{' '}

                                                    <Button style={{ minWidth: 100 }} type="submit" color="primary" onClick={() => this.setState({ errorAcitveReferrals: null, transferToUser: null, userChecked: null })} >{'Cancel'.translate(this.props.lang)}</Button>{' '}
                                                </ModalFooter>
                                            </Modal>

                                            :
                                            null
                                        }




                                    </Col>
                                    :
                                    null
                            }
                            {
                                this.state.tabIndexGroups === 2 ?
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '30%', borderRight: '1px solid black', marginRight: 40 }}>
                                            <div className='daybook-events-wrap'>
                                                <div className='daybook-events-title'>{'Groups'.translate(this.props.lang)}</div>
                                                <div className="daybook-events">
                                                    {
                                                        this.state.professions && this.state.professions.map((item, idx) => {
                                                            return (
                                                                <div>
                                                                    <div className="daybook-events-item">
                                                                        <div className="daybook-events-item-name-scale">
                                                                            <Input disabled={!item.group} type="text" value={this.props.lang === 'en' ? item.professionEn : item.professionSv} onChange={(e) => {
                                                                                let professions = this.state.professions;
                                                                                if (professions && professions[idx] && professions[idx].group && (professions[idx].professionEn || professions[idx].professionEn === '') && (professions[idx].professionSv || professions[idx].professionSv === '')) {
                                                                                    if (this.props.lang === 'en') {
                                                                                        professions[idx].professionEn = e.target.value;
                                                                                        this.setState({ professions }, () => {
                                                                                            this.insertOrUpdateProfesion(professions[idx])
                                                                                        })
                                                                                    } else {
                                                                                        professions[idx].professionSv = e.target.value;
                                                                                        this.setState({ professions }, () => {
                                                                                            this.insertOrUpdateProfesion(professions[idx])
                                                                                        })
                                                                                    }

                                                                                }
                                                                            }} />
                                                                        </div>
                                                                        {
                                                                            item.group ?
                                                                                <div className="daybook-events-item-delete-button" onClick={() => {
                                                                                    let professions = this.state.professions;
                                                                                    if (professions && professions[idx]) {

                                                                                        this.deleteProfession(professions[idx]._id)
                                                                                    }
                                                                                }}>
                                                                                    <Isvg src={garabage} />
                                                                                    <div className='daybook-events-item-delete-button-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                                </div>
                                                                                :
                                                                                null
                                                                        }


                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    <div className="add-daybook-events-item" onClick={() => {
                                                        this.setState({
                                                            professionForm: {}
                                                        })
                                                    }}>
                                                        <Isvg src={addIcon} />
                                                        <h6>{'Add more'.translate(this.props.lang)}</h6>
                                                    </div>
                                                }
                                                {this.state.professionForm ?
                                                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                                        getStringDateTs={this.props.getStringDateTs}
                                                        lang={this.props.lang}
                                                        onSubmit={(data) => this.insertOrUpdateProfesion(data)}
                                                        initialValues={this.state.professionForm}
                                                        isOpen={this.state.professionForm}
                                                        size={'lg'}
                                                        toggle={() => this.setState({ professionForm: null })}
                                                        title={this.state.professionForm._id ? 'Edit group'.translate(this.props.lang) : 'Add new group'.translate(this.props.lang)}
                                                        _saving={this.state._saving}
                                                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                                                        buttonText={'Save'.translate(this.props.lang)}

                                                        fields={[
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 6,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'professionEn',
                                                                                label: 'Groups EN'.translate(this.props.lang),
                                                                                validate: [required('Group EN is required!'.translate(this.props.lang))]
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 6,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'professionSv',
                                                                                label: 'Groups SV'.translate(this.props.lang),
                                                                                validate: [required('Group SV is required!'.translate(this.props.lang))]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            }
                                                        ]}
                                                    ></FormBuilder>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div style={{ width: '70%' }}>
                                            <div style={{ paddingLeft: 15, fontWeight: 600 }}>{'Group members'.translate(this.props.lang)}</div>


                                            <ListBuilder
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.total}
                                                hideEntries={true}

                                                selectData={this.state.professions && this.state.professions.map(item => {
                                                    return { name: this.props.lang === 'en' ? item.professionEn : item.professionSv, value: item._id }
                                                })}
                                                onSelectChange={(value) => {
                                                    this.setState({
                                                        selectedProfession: value
                                                    }, () => {
                                                        this.get()
                                                    })
                                                }}
                                                selectedValue={this.state.selectedProfession}

                                                showNumeration={false}
                                                fields={[
                                                    { type: 'text', name: 'name', label: 'Title, first and last name'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'username', label: 'Username'.translate(this.props.lang), allowSort: true },

                                                ]}
                                                items={this.state.items.map(item => {
                                                    return {
                                                        ...item,
                                                        name: this.props.formatName(item.userData.title, item.userData.name),

                                                    }
                                                })}
                                                actions={
                                                    [
                                                        {
                                                            component: <div className="tabel-action-tooltip"> <Isvg src={garabage} /> <div className="chat-icons-tooltip">{'Remove from group'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => {
                                                                this.setState({ deleteModalProfession: item })
                                                            },
                                                            condition: (item) => {
                                                                if (item.profession && item.profession[this.props.selectedGroup] && typeof item.profession[this.props.selectedGroup] !== 'string' && item.profession[this.props.selectedGroup].length > 1) {
                                                                    return true
                                                                } else {
                                                                    return false
                                                                }
                                                            }
                                                        },
                                                    ]
                                                }
                                                rawItems={this.state.items}
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            ></ListBuilder>


                                            <DeleteModal
                                                lang={this.props.lang}
                                                isOpen={this.state.deleteModalProfession}
                                                toggle={() => this.setState({ deleteModalProfession: null })}
                                                handler={() => {
                                                    let profession = {}
                                                    if (this.state.deleteModalProfession.profession) {
                                                        profession = this.state.deleteModalProfession.profession
                                                        let index = profession[this.props.selectedGroup].findIndex(item => item === this.state.selectedProfession)
                                                        if (profession[this.props.selectedGroup][index]) {
                                                            profession[this.props.selectedGroup].splice(index, 1)
                                                        }
                                                    }
                                                    this.upgradeUsersProfession(profession, this.state.deleteModalProfession._id)
                                                }}
                                            >
                                                {'Delete'.translate(this.props.lang)} <strong>{this.state.deleteModalProfession && this.state.deleteModalProfession.name}</strong> {'from this group'.translate(this.props.lang)}?
                                            </DeleteModal>



                                        </div>
                                    </div>

                                    :
                                    null
                            }

                            {this.state.error && this.state.error !== "The added user is associated with a monthly fee. For each user in addition to 10 users, a monthly cost will be added." ?

                                <ErrorModal lang={this.props.lang}
                                    isOpen={this.state.error}
                                    toggle={() => this.setState({ error: null })}

                                >
                                    {this.state.error.translate(this.props.lang)}
                                </ErrorModal>

                                :
                                this.state.error === "The added user is associated with a monthly fee. For each user in addition to 10 users, a monthly cost will be added." ?
                                    <InfoModal
                                        isOpen={this.state.error}
                                        onClose={(item) => {
                                            this.setState({
                                                error: null
                                            })
                                        }}
                                        close={true}
                                        header={'Increased monthly cost'.translate(this.props.lang)}
                                        info={'The added user is associated with a monthly fee. For each user in addition to 10 users, a monthly cost will be added.'.translate(this.props.lang)}
                                        buttons={[
                                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ error: null })}>{'Ok'.translate(this.props.lang)}</Button>,
                                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ error: null })}>{'Cancel'.translate(this.props.lang)}</Button>


                                        ]}
                                    />

                                    :
                                    null
                            }
                        </div>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => {
                            this.insertOrUpdate(data)
                        }
                        }
                        initialValues={this.state.form}
                        size="lg"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null, doctorPermissions: false })}
                        title={this.state.form._id ? 'Modify user'.translate(this.props.lang) : 'Add new user'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                        disabledCreatedInfo={true}
                        afterComponent={
                            <div>
                                <div style={{ width: '100%', overflowX: 'auto' }}>

                                    {this.state.clinicGroupSettings?.eConnect && this.state.permissions?.doctor && this.state.form?.groupPermissions?.[this.props.selectedGroup]?.[this.props?.selectedClinic]?.includes?.(this.state.permissions?.doctor) ? <div className="e-connect-profile-page-container">
                                        <FormGroup className="account-details">
                                            {"e-Connect".translate(this.props.lang)}:
                                            &nbsp;
                                            <strong>
                                                {this.state.form?.eConnectTempDisabled ? 'Disabled'.translate(this.props.lang) : 'Enabled'.translate(this.props.lang)}
                                            </strong>
                                            <Button style={{ paddingRight: 32, paddingLeft: 32 }} color={this.state.form?.eConnectTempDisabled ? 'info' : 'danger'} onClick={() => {
                                                let action = this.state.form?.eConnectTempDisabled ? 'enable' : 'disable';
                                                this.setState({ eConnectModal: { action, id: this.state.form?._id } });
                                            }}>
                                                {this.state.form?.eConnectTempDisabled ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                                            </Button>
                                        </FormGroup>
                                    </div> : null}

                                    <div className="table-container-scroll">
                                        <div className="table-container-scroll-reverse subaccounts-table-modal">

                                            <Table responsive>
                                                <thead>
                                                    <th>{'Clinic'.translate(this.props.lang)}</th>
                                                    {
                                                        this.props.uData && this.props.uData.clinicModules.map((item, idx) => {
                                                            return (
                                                                <th key={idx}>{item.name && item.name.translate(this.props.lang)}</th>
                                                            )
                                                        })
                                                    }
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.props.uData && this.props.uData.clinicGroups[selectedGroupIdx].clinics.map((item, idx) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item.name} <br /><span className="clinic-subgroup-name">{item.subgroupName}</span></td>
                                                                    {
                                                                        this.props.uData && this.props.uData.clinicModules.map((module, idx) => {


                                                                            return (
                                                                                <td key={idx}>
                                                                                    <Checkbox checked={this.state.form.groupPermissions && this.state.form.groupPermissions[this.props.selectedGroup] && this.state.form.groupPermissions[this.props.selectedGroup][item._id] && this.state.form.groupPermissions[this.props.selectedGroup][item._id].indexOf(module._id) != -1} onChange={(e) => {
                                                                                        if (!(module.name == 'Klinik admin' && this.state.form._id && this.state.form.groupPermissions && this.state.form.groupPermissions[this.props.selectedGroup] && this.state.form.groupPermissions[this.props.selectedGroup][item._id] && this.state.form.groupPermissions[this.props.selectedGroup][item._id].indexOf(module._id) != -1 &&
                                                                                            this.state.clinicAdmins && this.state.clinicAdmins.length && this.state.clinicAdmins.filter(clinic => clinic.clinicId == item._id && clinic.clinicAdmins && clinic.clinicAdmins.length && clinic.clinicAdmins.length == 1).length && this.state.clinicAdmins.filter(clinic => clinic.clinicId == item._id && clinic.clinicAdmins && clinic.clinicAdmins.length && clinic.clinicAdmins.length == 1 && clinic.clinicAdmins[0].toString() == this.state.form._id.toString()).length)) {
                                                                                            if (!e.target.checked) {
                                                                                                let form = this.state.form;
                                                                                                if (!form.groupPermissions) {
                                                                                                    form.groupPermissions = {};
                                                                                                }

                                                                                                if (!form.groupPermissions[this.props.selectedGroup]) {
                                                                                                    form.groupPermissions[this.props.selectedGroup] = {};
                                                                                                }

                                                                                                if (!form.groupPermissions[this.props.selectedGroup][item._id]) {
                                                                                                    form.groupPermissions[this.props.selectedGroup][item._id] = [];
                                                                                                }

                                                                                                form.groupPermissions[this.props.selectedGroup][item._id].splice(this.state.form.groupPermissions[this.props.selectedGroup][item._id].indexOf(module._id), 1);
                                                                                                this.setState({ form }, () => {
                                                                                                    console.log(this.props.questionaryList)
                                                                                                    if (this.props.questionaryList && this.props.questionaryList.length) {
                                                                                                        let newQuestionaryList = this.state.questionaryList.filter((referralItem) => {


                                                                                                            let doctorModule = this.props.uData.clinicModules[2];
                                                                                                            let form = this.state.form;

                                                                                                            if (this.props.questionaryList.indexOf(referralItem._id) !== -1 && doctorModule && form && form.groupPermissions && form.groupPermissions[this.props.selectedGroup] && form.groupPermissions[this.props.selectedGroup][referralItem.clinic] && form.groupPermissions[this.props.selectedGroup][referralItem.clinic].indexOf(doctorModule._id) !== -1) {
                                                                                                                return true;
                                                                                                            } else {
                                                                                                                return false;
                                                                                                            }

                                                                                                        })

                                                                                                        console.log(newQuestionaryList)

                                                                                                        this.props.changeQuestionaryList(newQuestionaryList.map(refId => refId._id));
                                                                                                    }

                                                                                                })
                                                                                            } else {
                                                                                                let form = this.state.form;
                                                                                                if (!form.groupPermissions) {
                                                                                                    form.groupPermissions = {};
                                                                                                }

                                                                                                if (!form.groupPermissions[this.props.selectedGroup]) {
                                                                                                    form.groupPermissions[this.props.selectedGroup] = {};
                                                                                                }

                                                                                                if (!form.groupPermissions[this.props.selectedGroup][item._id]) {
                                                                                                    form.groupPermissions[this.props.selectedGroup][item._id] = [];
                                                                                                }

                                                                                                form.groupPermissions[this.props.selectedGroup][item._id].push(module._id);


                                                                                                this.setState({ form }, () => {

                                                                                                })

                                                                                            }
                                                                                        }


                                                                                    }}
                                                                                        disabled={module.name == 'Klinik admin' && this.state.form._id && this.state.form.groupPermissions && this.state.form.groupPermissions[this.props.selectedGroup] && this.state.form.groupPermissions[this.props.selectedGroup][item._id] && this.state.form.groupPermissions[this.props.selectedGroup][item._id].indexOf(module._id) != -1 &&
                                                                                            this.state.clinicAdmins && this.state.clinicAdmins.length &&
                                                                                            this.state.clinicAdmins.filter(clinic => clinic.clinicId == item._id && clinic.clinicAdmins && clinic.clinicAdmins.length && clinic.clinicAdmins.length == 1).length &&
                                                                                            this.state.clinicAdmins.filter(clinic => clinic.clinicId == item._id && clinic.clinicAdmins && clinic.clinicAdmins.length && clinic.clinicAdmins.length == 1 && clinic.clinicAdmins[0].toString() == this.state.form._id.toString()).length ? true : false}
                                                                                    />
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div></div></div>

                        }
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: this.state.clinicGroupSettings?.netIdAuthentication ? 6 : 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                hideFindChildren: true,
                                                searchByNameAndEmailFormBuilder: this.state.lockFields ? false : true,
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                disabled: this.state.lockFields ? true : false,
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]

                                            },
                                        ]
                                    },
                                    this.state.clinicGroupSettings?.netIdAuthentication ? {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'netId',
                                                name: 'netId',
                                                tooltip: "Do not fill in this field when registering a new user. It is registered afterwards.".translate(this.props.lang),
                                                label: 'HSA-id'.translate(this.props.lang),
                                                disabled: this.state.lockFields ? true : false
                                            },
                                        ]
                                    } : {
                                        type: 'empty'
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 2,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.title',
                                                label: 'Title'.translate(this.props.lang),
                                                disabled: this.props.alias || this.state.lockFields ? true : false
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.name',
                                                label: 'First and last name'.translate(this.props.lang),
                                                disabled: this.props.alias || this.state.lockFields ? true : false,
                                                validate: [required('Name is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirth.date',
                                                    disabled: this.state.disabledDateOfBirth,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : null,
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select-checkbox',
                                                name: `profession.${this.props.selectedGroup}`,
                                                label: 'Groups'.translate(this.props.lang),
                                                values: [...this.state.professions].map(item => { return { name: this.props.lang == 'en' ? item.professionEn : item.professionSv, value: item._id } }),
                                                validate: [required('Groups are required!'.translate(this.props.lang)), minLength(1, 'Groups are required!'.translate(this.props.lang))],
                                                lang: this.props.lang,
                                                placeHolder: 'Select group(s)'.translate(this.props.lang)
                                                // disabled: this.state.lockFields ? true : false
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'email',
                                                name: `contacts.${this.props.selectedGroup}.email`,
                                                label: 'Email address'.translate(this.props.lang),
                                                validate: [required('Email address is required!'.translate(this.props.lang))],
                                                // disabled: this.state.changeEmail ? false : this.state.lockFields ? true : false
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'phone',
                                                name: `contacts.${this.props.selectedGroup}.phone`,
                                                label: 'Phone'.translate(this.props.lang),
                                                validate: [required('Phone is required!'.translate(this.props.lang))],
                                                // disabled: this.state.lockFields ? true : false
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'username',
                                                label: 'Username'.translate(this.props.lang),
                                                disabled: true,
                                                validate: [required('Username is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } }),
                                                    disabled: this.state.lockFields ? true : false
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.form._id ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'password',
                                                    disabled: this.props.alias,
                                                    autoComplete: 'new-password',
                                                    labelInfo: "Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:".translate(this.props.lang),
                                                    name: 'password',
                                                    label: 'Password'.translate(this.props.lang),
                                                    disabled: this.state.lockFields ? true : false,
                                                    validate: !this.state.clinicGroupSettings?.netIdAuthentication && !this.state.clinicGroupSettings?.bankIdAuthentication ? [required("Password is required!".translate(this.props.lang))] : undefined
                                                },
                                            ]
                                        } : null,
                                    !this.state.form._id ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'password',
                                                    disabled: this.props.alias,
                                                    autoComplete: 'new-password',
                                                    name: 'repeatPassword',
                                                    label: 'Repeat password'.translate(this.props.lang),
                                                    disabled: this.state.lockFields ? true : false,
                                                    validate: !this.state.clinicGroupSettings?.netIdAuthentication && !this.state.clinicGroupSettings?.bankIdAuthentication ? [required("Password is required!".translate(this.props.lang))] : undefined
                                                },
                                            ]
                                        } : null,

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'h6',
                                                text: "Note: if a password is not created, another login-method must be used the first time. User can then create a password under 'My profile'.".translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'table',
                                        name: 'subAccounts',
                                        fields: [
                                            { name: 'name', type: 'text' },


                                        ],
                                        actions: [
                                            {
                                                component: <i className="fa fa-edit base-color" />,
                                                onClick: (item) => this.setState({ form: null, doctorPermissions: false }, () => this.setState({ form: item }))
                                            },
                                            {
                                                component: <i className="fa fa-unlink danger-color" />,
                                                onClick: (item) => this.setState({ unlinkModal: item })
                                            },

                                            {
                                                component: <i className="fa fa-trash danger-color" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },

                                        ],
                                        label: 'Sub Accounts'.translate(this.props.lang),
                                    },








                                ]
                            }
                        ]}
                    // fieldsLast={[
                    //     this.state.questionaryList.filter((item) => {
                    //         let doctorModule = this.props.uData.clinicModules[2];
                    //         let form = this.state.form;

                    //         if (doctorModule && form && form.groupPermissions && form.groupPermissions[this.props.selectedGroup] && form.groupPermissions[this.props.selectedGroup][item.clinic] && form.groupPermissions[this.props.selectedGroup][item.clinic].indexOf(doctorModule._id) !== -1) {
                    //             return true;
                    //         } else {
                    //             return false;
                    //         }

                    //     }).length ?
                    //         {
                    //             type: 'col',
                    //             width: {
                    //                 lg: 12,
                    //                 sm: 12,
                    //                 xs: 12
                    //             },
                    //             children: [
                    //                 {
                    //                     type: 'list-checkbox',
                    //                     name: 'questionaryList',
                    //                     multiple: true,
                    //                     label: 'Forms'.translate(this.props.lang),
                    //                     values: [...this.state.questionaryList.filter((item) => {
                    //                         let doctorModule = this.props.uData.clinicModules[2];
                    //                         let form = this.state.form;

                    //                         if (doctorModule && form && form.groupPermissions && form.groupPermissions[this.props.selectedGroup] && form.groupPermissions[this.props.selectedGroup][item.clinic] && form.groupPermissions[this.props.selectedGroup][item.clinic].indexOf(doctorModule._id) !== -1 && item.wayOfScheduling == 'patient') {
                    //                             return true;
                    //                         } else {
                    //                             return false;
                    //                         }

                    //                     }).map(item => { return { name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)') /*Object.translate(item, 'name', this.props.lang)*/, value: item._id } })],


                    //                 },
                    //             ]
                    //         } : null,
                    // ]}
                    ></FormBuilder>
                    :
                    null
                }

                {this.state.sembleModal ? <Modal
                    isOpen={this.state.sembleModal}
                    toggle={() => this.setState({ sembleModal: null })}
                    centered
                >
                    <ModalHeader>
                        {'Connect with Semble'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.selectedGroup && this.state.sembleModal?.semble?.[this.props.selectedGroup]?.id ? <div className='semble-users-list-connected-user'>
                            <div>
                                <div>
                                    {getInitialsFromName(this.state.sembleModal?.semble?.[this.props.selectedGroup].name)}
                                </div>
                                <div>
                                    <p>{this.state.sembleModal?.semble?.[this.props.selectedGroup].name}</p>
                                    <p>{this.state.sembleModal?.semble?.[this.props.selectedGroup].email}</p>
                                </div>
                            </div>
                            <div>
                                <Button
                                    color='danger'
                                    onClick={() => {
                                        this.disconnectSemble();
                                    }}
                                >
                                    {'Disconnect'.translate(this.props.lang)}
                                </Button>
                            </div>
                        </div> : <ul className='semble-users-list-ul'>
                            {this.state.sembleUsers?.map?.(user => user?.id ? <li key={user?.id}>
                                <div>
                                    <div>
                                        {getInitialsFromName(user.name)}
                                    </div>
                                    <div>
                                        <p>{user.name}</p>
                                        <p>{user.email}</p>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        color='info'
                                        onClick={() => {
                                            const semble = {
                                                id: user.id,
                                                email: user.email,
                                                name: user.name
                                            }

                                            this.connectSemble(semble);
                                        }}
                                    >
                                        {'Connect'.translate(this.props.lang)}
                                    </Button>
                                </div>
                            </li> : null)}
                        </ul>}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            color='primary'
                            onClick={() => this.setState({ sembleModal: null })}
                        >
                            {'Close'.translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.eConnectModal && this.state.eConnectModal?.id ? <InfoModal
                    isOpen={this.state.eConnectModal}
                    onClose={() => {
                        this.setState({
                            eConnectModal: null
                        })
                    }}
                    info={this.state.eConnectModal?.action === 'enable' ? 'Enable eConnect again? Clinic admin will be able to see this user in the list of caregivers when assigning new eConnect cases, if eConnect is enabled for the clinic.'.translate(this.props.lang) : 'Temporarily disable eConnect? Clinic admin will NOT be able to see this user in the list of caregivers when assigning new eConnect cases, if eConnect is enabled for the clinic.'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color={this.state.eConnectModal?.action === 'enable' ? 'info' : 'danger'}
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.changeEConnectStatus}
                            className="e-connect-profile-button"
                        >
                            {this.state.eConnectModal?.action === 'enable' ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    eConnectModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.notificationModal ?
                    <Modal isOpen={this.state.notificationModal} centered size='xl'>
                        <ModalHeader toggle={() => this.setState({ notificationModal: !this.state.notificationModal, notificationsPatient: null, notificationsDoctor: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ notificationModal: !this.state.notificationModal, notificationsPatient: null, notificationsDoctor: null })}>&times;</button>}>{'User notifications'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            {/* <div className="panel"> */}

                            <Row>

                                <Col lg="12">
                                    <div className="flex-space-between tablet-none notifications-tabs-wrap" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                        <div className="notifications-tabs tabs-buttons-wrap" style={{ marginBottom: 0, borderBottom: 0 }}>
                                            <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Doctor notifications".translate(this.props.lang)}</Button>
                                            <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Patient notifications".translate(this.props.lang)}</Button>
                                        </div>

                                        <div>
                                            <Button style={{ whiteSpace: 'nowrap' }} className="tab-button acitve-tab-button reset-default-btn" onClick={() => this.setDefaultValues(this.state.tabIndex)}>{"Reset default".translate(this.props.lang)}</Button>
                                        </div>
                                    </div>
                                </Col>


                                <Col lg="12" className={this.state.tabIndex == 1 ? 'visibility mt-20' : 'none-visibility'}>

                                    <FormGroup style={{ width: '100%', overflowX: 'auto' }}>
                                        <div className="table-container-scroll">
                                            <div className="table-container-scroll-reverse">
                                                <table className="clinic-notification-table">
                                                    <tr>
                                                        <th>{"Doctor notifications".translate(this.props.lang)}</th>
                                                        {
                                                            mailEnabled ?
                                                                <th>{"EMAIL".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <th>{"SMS".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <th>{"PUSH".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        {/* <th>{"PUSH".translate(this.props.lang)}</th> */}
                                                        {
                                                            notifEnabled ?
                                                                <th>
                                                                    {"SYSTEM".translate(this.props.lang)}
                                                                    {/* <span className='info-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='info-tooltip'>{'This setting cannot be changed.'.translate(this.props.lang)}</div>
                                                            </span> */}
                                                                </th>
                                                                :
                                                                null
                                                        }

                                                        <th>{"DEFAULT".translate(this.props.lang)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"New event".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td >
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set1) {
                                                                                    notificationsDoctor.set1.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }


                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.sms : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set1) {
                                                                                    notificationsDoctor.set1.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set1) {
                                                                                    notificationsDoctor.set1.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.webpush : null}
                                                            onChange={(e) => {
                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set1) {
                                                                        notificationsDoctor.set1.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }

                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set1) {
                                                                                    notificationsDoctor.set1.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set1 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set1) {
                                                                        notificationsDoctor.set1.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set1 = this.state.notificationsDoctorDefault.set1;
                                                                        }
                                                                    }

                                                                    this.setState({ notificationsDoctor })
                                                                }}

                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Notification of booked appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {

                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set2) {
                                                                                    notificationsDoctor.set2.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {

                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set2) {
                                                                                    notificationsDoctor.set2.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }


                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set2) {
                                                                                    notificationsDoctor.set2.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set2) {
                                                                                    notificationsDoctor.set2.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set2) {
                                                                        notificationsDoctor.set2.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;

                                                                    if (notificationsDoctor.set2 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set2) {
                                                                        notificationsDoctor.set2.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set2 = this.state.notificationsDoctorDefault.set2;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Patient rescheduled/cancelled a visit".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set3) {
                                                                                    notificationsDoctor.set3.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set3) {
                                                                                    notificationsDoctor.set3.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set3) {
                                                                                    notificationsDoctor.set3.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set3) {
                                                                                    notificationsDoctor.set3.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set3) {
                                                                        notificationsDoctor.set3.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;

                                                                    if (notificationsDoctor.set3 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set3) {
                                                                        notificationsDoctor.set3.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set3 = this.state.notificationsDoctorDefault.set3;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"New appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set4) {
                                                                                    notificationsDoctor.set4.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set4) {
                                                                                    notificationsDoctor.set4.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set4) {
                                                                                    notificationsDoctor.set4.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set4) {
                                                                                    notificationsDoctor.set4.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set4) {
                                                                        notificationsDoctor.set4.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;

                                                                    if (notificationsDoctor.set4 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set4) {
                                                                        notificationsDoctor.set4.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set4 = this.state.notificationsDoctorDefault.set4;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"New message".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set5) {
                                                                                    notificationsDoctor.set5.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set5) {
                                                                                    notificationsDoctor.set5.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set5) {
                                                                                    notificationsDoctor.set5.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set5) {
                                                                                    notificationsDoctor.set5.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set5) {
                                                                        notificationsDoctor.set5.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;


                                                                    if (notificationsDoctor.set5 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set5) {
                                                                        notificationsDoctor.set5.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set5 = this.state.notificationsDoctorDefault.set5;
                                                                        }
                                                                    }

                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"New time for appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set6) {
                                                                                    notificationsDoctor.set6.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set6) {
                                                                                    notificationsDoctor.set6.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set6) {
                                                                                    notificationsDoctor.set6.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set6) {
                                                                                    notificationsDoctor.set6.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set6) {
                                                                        notificationsDoctor.set6.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set6 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set6) {
                                                                        notificationsDoctor.set6.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set6 = this.state.notificationsDoctorDefault.set6;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p>{"Reminder that a meeting starts in 1 hour".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set7) {
                                                                                    notificationsDoctor.set7.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set7) {
                                                                                    notificationsDoctor.set7.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                    /></div>
                                                                </td>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set7) {
                                                                                    notificationsDoctor.set7.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set7) {
                                                                                    notificationsDoctor.set7.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set7 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set7) {
                                                                        notificationsDoctor.set7.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set7 = this.state.notificationsDoctorDefault.set7;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Payment information".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set8) {
                                                                                    notificationsDoctor.set8.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set8) {
                                                                                    notificationsDoctor.set8.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                    /></div>
                                                                </td>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set8) {
                                                                                    notificationsDoctor.set8.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            notifEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.notif : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                let notificationsDoctor = this.state.notificationsDoctor;
                                                                                if (notificationsDoctor.set8) {
                                                                                    notificationsDoctor.set8.notif = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsDoctor })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set8 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set8) {
                                                                        notificationsDoctor.set8.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsDoctor.set8 = this.state.notificationsDoctorDefault.set8;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </FormGroup>




                                </Col>


                                <Col lg="12" className={this.state.tabIndex == 2 ? 'visibility mt-20' : 'none-visibility'}>

                                    <FormGroup style={{ width: '100%', overflowX: 'auto' }}>
                                        <div className="table-container-scroll">
                                            <div className="table-container-scroll-reverse">
                                                <table className="clinic-notification-table">
                                                    <tr>
                                                        <th>{"Patient notifications".translate(this.props.lang)}</th>
                                                        {
                                                            mailEnabled ?
                                                                <th>{"EMAIL".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <th>{"SMS".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <th>{"PUSH".translate(this.props.lang)}</th>
                                                                :
                                                                null
                                                        }
                                                        <th>
                                                            {"SYSTEM / APP".translate(this.props.lang)}
                                                            <span className='info-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='info-tooltip'>{'This setting cannot be changed.'.translate(this.props.lang)}</div>
                                                            </span>
                                                        </th>

                                                        <th>{"DEFAULT".translate(this.props.lang)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Reminder that a appointment starts in 24 hours".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.email : null}
                                                                        onChange={(e) => {
                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set1) {
                                                                                    notificationsPatient.set1.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}

                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set1) {
                                                                                    notificationsPatient.set1.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set1) {
                                                                                    notificationsPatient.set1.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set1 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set1) {
                                                                        notificationsPatient.set1.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set1 = this.state.notificationsPatientDefault.set1;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })


                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Reminder that a appointment starts in 1 hour".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set10) {
                                                                                    notificationsPatient.set10.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set10) {
                                                                                    notificationsPatient.set10.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td >
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set10) {
                                                                                    notificationsPatient.set10.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set10 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set10) {
                                                                        notificationsPatient.set10.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set10 = this.state.notificationsPatientDefault.set10;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Reminder that a appointment starts in 10 minutes".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set11) {
                                                                                    notificationsPatient.set11.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set11) {
                                                                                    notificationsPatient.set11.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td >
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set11) {
                                                                                    notificationsPatient.set11.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set11 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set11) {
                                                                        notificationsPatient.set11.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set11 = this.state.notificationsPatientDefault.set11;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Notification of received request".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set2) {
                                                                                    notificationsPatient.set2.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set2) {
                                                                                    notificationsPatient.set2.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set2) {
                                                                                    notificationsPatient.set2.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set2 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set2) {
                                                                        notificationsPatient.set2.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set2 = this.state.notificationsPatientDefault.set2;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Approved request".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set3) {
                                                                                    notificationsPatient.set3.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set3) {
                                                                                    notificationsPatient.set3.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set3) {
                                                                                    notificationsPatient.set3.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set3 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set3) {
                                                                        notificationsPatient.set3.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set3 = this.state.notificationsPatientDefault.set3;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}

                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"A meeting has been booked".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set4) {
                                                                                    notificationsPatient.set4.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set4) {
                                                                                    notificationsPatient.set4.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set4) {
                                                                                    notificationsPatient.set4.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set4 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set4) {
                                                                        notificationsPatient.set4.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set4 = this.state.notificationsPatientDefault.set4;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Denied patient request".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set5) {
                                                                                    notificationsPatient.set5.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set5) {
                                                                                    notificationsPatient.set5.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set5) {
                                                                                    notificationsPatient.set5.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set5 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set5) {
                                                                        notificationsPatient.set5.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set5 = this.state.notificationsPatientDefault.set5;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Rebooked appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set6) {
                                                                                    notificationsPatient.set6.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set6) {
                                                                                    notificationsPatient.set6.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set6) {
                                                                                    notificationsPatient.set6.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set6 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set6) {
                                                                        notificationsPatient.set6.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set6 = this.state.notificationsPatientDefault.set6;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"New message".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set7) {
                                                                                    notificationsPatient.set7.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set7) {
                                                                                    notificationsPatient.set7.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set7) {
                                                                                    notificationsPatient.set7.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set7 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set7) {
                                                                        notificationsPatient.set7.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set7 = this.state.notificationsPatientDefault.set7;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Change time for appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set8) {
                                                                                    notificationsPatient.set8.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set8) {
                                                                                    notificationsPatient.set8.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set8) {
                                                                                    notificationsPatient.set8.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set8 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set8) {
                                                                        notificationsPatient.set8.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set8 = this.state.notificationsPatientDefault.set8;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })

                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Deleted scheduled appointment".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set9) {
                                                                                    notificationsPatient.set9.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set9) {
                                                                                    notificationsPatient.set9.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td >
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set9) {
                                                                                    notificationsPatient.set9.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set9 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set9) {
                                                                        notificationsPatient.set9.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set9 = this.state.notificationsPatientDefault.set9;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        listingNotificationsEnabled ?
                                                            <tr>
                                                                <td>
                                                                    <p>{"Listing request approved".translate(this.props.lang)}</p>
                                                                </td>
                                                                {
                                                                    mailEnabled ?
                                                                        <td>
                                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.email : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set12) {
                                                                                            notificationsPatient.set12.email = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }
                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    smsEnabled ?
                                                                        <td>
                                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.sms : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set12) {
                                                                                            notificationsPatient.set12.sms = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }
                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    pushEnabled ?
                                                                        <td >
                                                                            <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.webpush : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set12) {
                                                                                            notificationsPatient.set12.webpush = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }

                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                <td>
                                                                    <div><Checkbox disabled checked={true}
                                                                        onChange={(e) => {


                                                                        }}
                                                                    /></div>
                                                                </td>

                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.default : null}
                                                                        onChange={(e) => {
                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                            if (notificationsPatient.set12 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set12) {
                                                                                notificationsPatient.set12.default = e.target.checked;
                                                                                if (e.target.checked) {
                                                                                    notificationsPatient.set12 = this.state.notificationsPatientDefault.set12;
                                                                                }
                                                                            }
                                                                            this.setState({ notificationsPatient })
                                                                        }}
                                                                    /></div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            null
                                                    }

                                                    <tr>
                                                        <td>
                                                            <p>{"Video call starting".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set13) {
                                                                                    notificationsPatient.set13.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set13) {
                                                                                    notificationsPatient.set13.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td >
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set13) {
                                                                                    notificationsPatient.set13.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set13 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set13) {
                                                                        notificationsPatient.set13.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set13 = this.state.notificationsPatientDefault.set13;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>{"Payment reminders".translate(this.props.lang)}</p>
                                                        </td>
                                                        {
                                                            mailEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.email : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set14) {
                                                                                    notificationsPatient.set14.email = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            smsEnabled ?
                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.sms : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set14) {
                                                                                    notificationsPatient.set14.sms = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }
                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            pushEnabled ?
                                                                <td >
                                                                    <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.webpush : null}
                                                                        onChange={(e) => {

                                                                            if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set14) {
                                                                                    notificationsPatient.set14.webpush = e.target.checked;
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }

                                                                        }}
                                                                        disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                    /></div>
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                        <td>
                                                            <div><Checkbox disabled checked={true}
                                                                onChange={(e) => {


                                                                }}
                                                            /></div>
                                                        </td>

                                                        <td>
                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.default : null}
                                                                onChange={(e) => {
                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                    if (notificationsPatient.set14 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set14) {
                                                                        notificationsPatient.set14.default = e.target.checked;
                                                                        if (e.target.checked) {
                                                                            notificationsPatient.set14 = this.state.notificationsPatientDefault.set14;
                                                                        }
                                                                    }
                                                                    this.setState({ notificationsPatient })
                                                                }}
                                                            /></div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        semble ?
                                                            <tr>
                                                                <td>
                                                                    <p>{"Semble notifications".translate(this.props.lang)}</p>
                                                                </td>
                                                                {
                                                                    mailEnabled ?
                                                                        <td>
                                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.email : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set15) {
                                                                                            notificationsPatient.set15.email = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }
                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    smsEnabled ?
                                                                        <td>
                                                                            <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.sms : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set15) {
                                                                                            notificationsPatient.set15.sms = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }
                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    pushEnabled ?
                                                                        <td >
                                                                            <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.webpush : null}
                                                                                onChange={(e) => {

                                                                                    if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                                        if (notificationsPatient.set15) {
                                                                                            notificationsPatient.set15.webpush = e.target.checked;
                                                                                        }
                                                                                        this.setState({ notificationsPatient })
                                                                                    }

                                                                                }}
                                                                                disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                            /></div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                <td>
                                                                    <div><Checkbox disabled checked={true}
                                                                        onChange={(e) => {


                                                                        }}
                                                                    /></div>
                                                                </td>

                                                                <td>
                                                                    <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.default : null}
                                                                        onChange={(e) => {
                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                            if (notificationsPatient.set15 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set15) {
                                                                                notificationsPatient.set15.default = e.target.checked;
                                                                                if (e.target.checked) {
                                                                                    notificationsPatient.set15 = this.state.notificationsPatientDefault.set15;
                                                                                }
                                                                            }
                                                                            this.setState({ notificationsPatient })
                                                                        }}
                                                                    /></div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            null
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </FormGroup>


                                </Col>
                                <Col lg='12' className="notifications-btns-wrap">
                                    <Button color="primary" onClick={() => this.updateNotifications(this.state.notificationModal)}>
                                        {/* {"Save".translate(this.props.lang)} */}

                                        {
                                            this.state.showPlayer ? (
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load") this.stopAnimation();
                                                    }}
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                    style={{ height: "30px", width: "30px" }}
                                                ></Player>

                                            ) : this.state.showPlayer2 ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player2} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                    style={{ height: "30px", width: "30px" }}
                                                ></Player>
                                                    {'Saved'.translate(this.props.lang)}
                                                </div>
                                            ) : (
                                                "Save".translate(this.props.lang)
                                            )
                                        }

                                    </Button>
                                    <Button color="primary" className="reset-default-mobile" onClick={() => this.setDefaultValues(this.state.tabIndex)}>{"Reset default".translate(this.props.lang)}</Button>
                                </Col>



                            </Row>


                            {/* </div> */}

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>
                    : null}

                {/* {this.state.error ? <ErrorModal
                    lang={this.props.lang}
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                >
                    {this.state.error.translate(this.props.lang)}
                </ErrorModal> : null} */}

                {this.state._saving ? <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100vh',
                        zIndex: 9999,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',

                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="loader-wrap">
                        <Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{
                                height:
                                    typeof window != "undefined" &&
                                        window.innerWidth < 768
                                        ? "128px"
                                        : "256px",
                                width:
                                    typeof window != "undefined" &&
                                        window.innerWidth < 768
                                        ? "128px"
                                        : "256px",
                            }}
                        ></Player>
                    </div>
                </div> : null}

            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        alias: selector(state, 'alias'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),
        questionaryList: selector(state, 'questionaryList'),
        profession: selector(state, 'profession'),
        dateOfBirth: selector(state, 'dateOfBirth.date')

    }


}, {
    changeUserData: value => change("modalForm", "userData", value),
    changeEmail: value => change("modalForm", "email", value),
    changeSocialSecurityNumber: value => change("modalForm", "socialSecurityNumber", value),
    changeUsername: value => change("modalForm", "username", value),
    changeLanguage: value => change("modalForm", "language", value),
    changePhone: value => change("modalForm", "phone", value),
    changeProfession: value => change("modalForm", "profession", value),
    changeQuestionaryList: value => change("modalForm", "questionaryList", value),
    changeDateOfBirth: value => change("modalForm", "dateOfBirth.date", value)

})(Page(SubAccounts));