import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input,

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Page from '../../containers/page';
import { getSearchParams } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import BankIdModal from '../../components/bankIdModal';
import InfoModal from '../../components/infoModal';


class RedirectFormPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            loading: true
        };

    }

    get = () => {

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        let alias = this.props[0].match.params.alias;
        let mainAlias = this.props[0].match.params.mainAlias;

        if (mainAlias && alias) {
            fetch(API_ENDPOINT + '/landing/page/clinic-group', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {

                this.setState({
                    seoData: result
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            });

        } else {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {

                this.setState({
                    seoData: result,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })

            });
        }


        this.setLastGroupAlias();

    }
    setLastGroupAlias = () => {
        if (this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length) {
            for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
                if (localStorage.groupAlias && localStorage.getItem('groupAlias') == this.props.uData.patientGroups[i].alias && this.props.uData.patientGroups[i]._noReferrals) {
                    localStorage.setItem('lastGroup', this.props.uData.patientGroups[i].alias);
                    break;
                }
            }

        }
    }
    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get()

        let mainAlias = this.props[0].match.params.mainAlias;
        let alias = this.props[0].match.params.alias;
        let id = this.props[0].match.params.id?.length === 24 ? this.props[0].match.params.id : null;
        if (alias && id) {
            this.checkForm(alias, id)
        } else if (alias) {
            let link;
            if (mainAlias) {
                link = `/${mainAlias}/${alias}`;
            } else {
                link = `/${alias}`;
            }
            this.props[0].history.push(link)
        } else {
            this.props[0].history.push('/login')
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.uData?._id !== this.props?.uData?._id && this.props?.uData?._id && this.state.redirectData?.link) {
            this.redirectToForm()
        }
    }

    checkForm = (alias, formId) => {
        if (alias && formId) {
            fetch(API_ENDPOINT + `/personal-questionary/check/${alias}/${formId}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    if (result.availableTerms) {
                        this.setState({
                            checkWorkingHoursError: result.error.translate(this.props.lang),
                            checkWorkingHoursAvailableTerms: result.availableTerms
                        })
                    } else {
                        this.setState({ error: result.error })
                    }
                } else if (result.type) {
                    let continueProcess = true;
                    // if (result.type === 'dropIn') {
                    //     if (result?.estimatedTime > 0) {
                    //         continueProcess = false;
                    //         this.setState({
                    //             estimatedWaitingTime: result.estimatedTime
                    //         })
                    //     }
                    // }
                    if (continueProcess)
                        this.setData(result.type)
                }
            });
        }

    }
    setData = (type) => {
        if (type) {
            let mainAlias = this.props[0].match.params.mainAlias;
            let alias = this.props[0].match.params.alias;
            let formId = this.props[0].match.params.id?.length === 24 ? this.props[0].match.params.id : null;
            let link;
            if (alias) {
                if (mainAlias) {
                    link = `/${mainAlias}/${alias}`;
                } else {
                    link = `/${alias}`;
                }
            }
            let screen;
            if (type === 'bookingByClinic') screen = '/personal-referral';
            else if (type === 'dropIn') screen = '/drop-in';
            else if (type === 'webBooking') screen = '/choose-time';

            if (screen) link += screen;

            if (link && formId) {
                this.setState({ redirectData: { link, formId } }, () => {

                    if (this.props.uData?._id || type === 'webBooking') {
                        this.redirectToForm()
                    } else {
                        this.startLogin()
                    }
                })
            }
        }
    }
    redirectToForm = () => {
        if (this.state.loginBankIdModal) {
            this.setState({ loginBankIdModal: null })
        }
        const link = this.state.redirectData?.link;
        const formId = this.state.redirectData?.formId;
        if (link && formId) {
            this.props[0].history.push({ pathname: link, state: { formId: formId } })
        }
    }
    startLogin = () => {
        this.setState({ loginBankIdModal: true })
    }


    componentWillUnmount() {

    }




    render() {
        const mainAlias = this.props[0].match.params.mainAlias;
        const alias = this.props[0].match.params.alias;
        const id = this.props[0].match.params.id?.length === 24 ? this.props[0].match.params.id : null;
        let link;
        if (alias) {
            if (mainAlias) {
                link = `/${mainAlias}/${alias}`;
            } else {
                link = `/${alias}`;
            }
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {
                    this.state.loginBankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.loginBankIdModal}
                            stateForOpen='loginBankIdModal'
                            // faceIdEnv={this.state.faceIdEnv}
                            checkLastLogin={true}
                            toggle={() => {
                                if (this.props.uData) {
                                    this.props.registerClinicGroupLogs({
                                        type: 'login',
                                        loginType: 'bankid'
                                    })
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    localStorage.setItem('hidePatientModal', true);
                                } else {
                                    this.props[0].history.push(link)
                                }
                                this.setState({ loginBankIdModal: null })

                            }}
                            redirect_url={this.state.loginBankIdModal}></BankIdModal>

                        :

                        null
                }

                {
                    this.state.error ?
                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null }, () => {
                                this.props[0].history.push(link)
                            })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>
                        :
                        null
                }
                {this.state.estimatedWaitingTime ?
                    <InfoModal
                        isOpen={this.state.estimatedWaitingTime}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Estimated waiting time is'.translate(this.props.lang) + ' ' + (this.state.estimatedWaitingTime ? parseInt(this.state.estimatedWaitingTime / 60) == 0 ? this.state.estimatedWaitingTime + ' ' + 'minutes'.translate(this.props.lang) : + parseInt(this.state.estimatedWaitingTime / 60) + 'h'.translate(this.props.lang) + ' ' + 'and'.translate(this.props.lang) + ' ' + this.state.estimatedWaitingTime % 60 + ' ' + 'minutes'.translate(this.props.lang) : '/')}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ estimatedWaitingTime: null }, () => {
                                this.setData('dropIn')
                            })}>{'Continue'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.setState({ estimatedWaitingTime: null }, () => {
                                    this.props[0].history.push(link)
                                })

                            }}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {
                    this.state.checkWorkingHoursError ?
                        <InfoModal
                            isOpen={this.state.checkWorkingHoursError}
                            onClose={(item) => {
                                this.setState({
                                    checkWorkingHoursError: item
                                })
                            }}
                            close={true}
                            header={'Info'.translate(this.props.lang)}
                            info={
                                <>
                                    <span style={{ fontWeight: 600 }}>{this.state.checkWorkingHoursError.translate(this.props.lang)}</span>
                                    <div className='table-error-dropin'>
                                        {
                                            this.state.checkWorkingHoursAvailableTerms && this.state.checkWorkingHoursAvailableTerms.map((item, idx) => {
                                                const startTime = this.props.getStringDate(item.startTimeUTC, 'HH:mm');
                                                // console.log(idx, startTime, item.startTimeUTC);
                                                const endTime = this.props.getStringDate(item.endTimeUTC, 'HH:mm');
                                                const timeUTC = `${startTime} - ${endTime}`
                                                return (


                                                    idx == 0 ?
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Monday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                        :
                                                        idx == 1 ?
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Tuesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                            :
                                                            idx == 2 ?
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Wednesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                :
                                                                idx == 3 ?
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Thursday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                    :
                                                                    idx == 4 ?
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Friday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                        :
                                                                        idx == 5 ?
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Saturday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                            :
                                                                            idx == 6 ?
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Sunday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                                :
                                                                                null


                                                )
                                            })
                                        }
                                    </div>
                                </>

                            }
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkWorkingHoursError: false }, () => {
                                    this.props[0].history.push(link)
                                })}>{'Ok'.translate(this.props.lang)}</Button>,

                            ]}
                        />
                        :
                        null
                }

            </div >
        );
    }
}



export default Page(RedirectFormPage);